import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = ({
  count, page, rowsPerPage, onChangePage,
}) => {
  const classes = useStyles();

  const onFirstButtonClick = (event) => {
    onChangePage(event, 1);
  };

  const onBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const onNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const onLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={onFirstButtonClick}
        disabled={page === 1}
        aria-label="primera página"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={onBackButtonClick}
        disabled={page === 1}
        aria-label="página anterior"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={onNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="página siguiente"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={onLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="última pagina"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
