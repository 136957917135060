import { takeLatest, put } from 'redux-saga/effects';
import { materialTypeActions } from 'store/modules/materialType';
import { uiActions } from 'store/modules/ui';
import { createSnack, getUserFriendlyError } from 'common/utils';
import materialType from 'graphql/materialType';

/**
 * Get filter options saga
 *
 * @param {*} action
 */
function* getFilterOptions() {
  try {
    const response = yield materialType.getMaterialTypes();
    const { data: { materialTypes: types } } = response;

    const options = types.map((option) => ({
      value: Number(option.id), label: option.name.toUpperCase(),
    }));

    options.unshift({ value: '', label: 'NINGUNO' });

    yield put(materialTypeActions.getFilterOptionsSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialTypeActions.getFilterOptionsError(message));
  }
}

/**
 * Get material types saga
 *
 * @param {*} action
 */
function* getMaterialTypes() {
  try {
    const response = yield materialType.getMaterialTypes();
    const { data: { materialTypes: types } } = response;

    yield put(materialTypeActions.getMaterialTypesSuccess(types));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialTypeActions.getMaterialTypesError(message));
  }
}

/**
 * Create material type saga
 *
 * @param {*} action
 */
function* createMaterialType(action) {
  try {
    yield materialType.createMaterialType(action.payload);

    const snack = createSnack({
      key: 'material-type-created-notification-success',
      variant: 'success',
      message: 'Tipo de material creado con éxito',
    });

    yield put(materialTypeActions.createMaterialTypeSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialTypeActions.createMaterialTypeError(message));
  }
}

/**
 * Delete material types saga
 *
 * @param {*} action
 */
function* deleteMaterialTypes(action) {
  try {
    const {
      data: { deleteMaterialTypes: deletedMaterialTypes },
    } = yield materialType.deleteMaterialTypes(action.payload);

    const snack = createSnack({
      key: 'delete-material-types-notification-success',
      variant: 'success',
      message: `${deletedMaterialTypes.length > 1 ? 'Tipos de materiales eliminados' : 'Tipo de material eliminado'} con éxito`,
    });

    yield put(materialTypeActions.deleteMaterialTypesSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialTypeActions.deleteMaterialTypesError(message));
  }
}

/**
 * Update material type saga
 *
 * @param {*} action
 */
function* updateMaterialType(action) {
  try {
    const {
      data: { updateMaterialType: updatedMaterialType },
    } = yield materialType.updateMaterialType(action.payload);

    const snack = createSnack({
      key: 'update-material-type-notification-success',
      variant: 'success',
      message: 'El tipo de material fue actualizado con éxito',
    });

    yield put(materialTypeActions.updateMaterialTypeSuccess(updatedMaterialType));
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialTypeActions.updateMaterialTypeError(message));
  }
}

/**
 * Watch material type
 *
 * @export
 */
export default function* watchMaterialType() {
  yield takeLatest(materialTypeActions.getFilterOptions, getFilterOptions);
  yield takeLatest(materialTypeActions.getMaterialTypes, getMaterialTypes);
  yield takeLatest(materialTypeActions.createMaterialType, createMaterialType);
  yield takeLatest(materialTypeActions.deleteMaterialTypes, deleteMaterialTypes);
  yield takeLatest(materialTypeActions.updateMaterialType, updateMaterialType);
}
