import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(routeProps) => <Layout {...routeProps} component={component} />}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.func.isRequired,
};

export default PublicRoute;
