import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Get filter options method
 * @param  {Object} payload
 * @return {Promise}
 */
const getFilterOptions = (payload) => {
  const exclude = payload.exclude.length > 0
    ? `{ column: ID, operator: NOT_IN, value: [ ${payload.exclude} ] }`
    : '';

  return (
    client.query({
      query: gql`
        query materialRequests($first: Int!, $page: Int!) {
          materialRequests(
            first: $first
            page: $page
            where: {
              AND: [
                { column: ID, operator: LIKE, value: "${payload.value}%" }
                ${exclude}
              ]
            }
            orderBy: [{
              column: ID
              order: ASC
            }]
          ) {
            data {
              id
            }
          }
        }
      `,
      variables: {
        first: payload.pagination.first,
        page: payload.pagination.page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Get requests method
 * @param  {Object} payload
 * @return {Promise}
 */
const getRequests = (payload) => {
  const { filters, pagination: { first, page } } = payload;

  const id = filters.id.length > 0
    ? `{ column: ID, operator: IN, value: [ ${filters.id} ] }` : '';
  const authorId = filters.author_id.length > 0
    ? `{ column: AUTHOR_ID, operator: IN, value: [ ${filters.author_id} ] }` : '';
  const constructionId = filters.construction_id.length > 0
    ? `{ column: CONSTRUCTION_ID, operator: IN, value: [ ${filters.construction_id} ] }` : '';
  const status = filters.status ? `{ column: STATUS, value: "${filters.status}" }` : '';
  const createdAt = filters.created_at ? `createdAt: "${filters.created_at}"` : '';
  const deliveryDate = filters.delivery_date ? `deliveryDate: "${filters.delivery_date}"` : '';

  const where = `
    where: {
      AND: [
        ${id}
        ${authorId}
        ${constructionId}
        ${status}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query materialRequests($first: Int!, $page: Int!) {
          materialRequests(
            first: $first
            page: $page
            ${where}
            ${createdAt}
            ${deliveryDate}
            orderBy: [{
              column: ID
              order: DESC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              author_id {
                full_name
              }
              construction_id {
                name
              }
              delivery_date
              status
              created_at
              comments
              admin_comments
              has_pdf
              material_transactions {
                material_id {
                  id
                  name
                  unit
                }
                quantity
              }
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Get requests to import method
 * @param  {Object} payload
 * @return {Promise}
 */
const getRequestsToImport = (payload) => {
  const { filters, pagination: { first, page } } = payload;

  const id = filters.id && filters.id.length > 0
    ? `{ column: ID, operator: IN, value: [ ${filters.id} ] }` : '';

  const where = `
    where: {
      AND: [
        ${id}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query materialRequests($first: Int!, $page: Int!) {
          materialRequests(
            first: $first
            page: $page
            ${where}
            orderBy: [{
              column: ID
              order: DESC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              material_transactions {
                material_id {
                  id
                  name
                  unit
                  value
                  discount
                  iva
                }
                quantity
              }
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create request method
 * @param  {Object} payload
 * @return {Promise}
 */
const createRequest = (payload) => client.mutate({
  mutation: gql`
    mutation createMaterialRequest($input: CreateMaterialRequestInput!) {
      createMaterialRequest(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Create pdf method
 * @param  {String} id
 * @return {Promise}
 */
const createPdf = (id) => client.mutate({
  mutation: gql`
    mutation {
      createMaterialRequestPdf(id: ${id})
    }
  `,
});

/**
 * Send created notification method
 * @param  {String} id
 * @return {Promise}
 */
const sendCreatedNotification = (id) => client.mutate({
  mutation: gql`
    mutation {
      sendMaterialRequestCreatedNotification(id: ${id})
    }
  `,
});

/**
 * Get download pdf url method
 * @param  {Object} payload
 * @return {Promise}
 */
const getDownloadPdfUrl = (payload) => client.query({
  query: gql`
    query {
      materialRequestDownloadPdfUrl(id: ${payload.id}, status: ${payload.status}) {
        url
      }
    }
  `,
});

/**
 * Move pdf method
 * @param  {Object} payload
 * @return {Promise}
 */
const movePdf = (payload) => client.mutate({
  mutation: gql`
    mutation moveMaterialRequestPdf($input: MoveMaterialRequestPdfInput!) {
      moveMaterialRequestPdf(input: $input) {
        moved
        errors {
          id
          message
        }
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Update request method
 * @param  {Object} payload
 * @return {Promise}
 */
const updateRequest = (payload) => client.mutate({
  mutation: gql`
    mutation updateMaterialRequest($input: UpdateMaterialRequestInput!) {
      updateMaterialRequest(id: ${payload.id}, input: $input) {
        id
        comments
        admin_comments
      }
    }
  `,
  variables: { input: payload.input },
});

/**
 * Update requests method
 * @param  {Object} payload
 * @return {Promise}
 */
const updateRequests = (payload) => client.mutate({
  mutation: gql`
    mutation updateMaterialRequests($input: UpdateMaterialRequestsInput!) {
      updateMaterialRequests(input: $input) {
        ids
        updated
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Send updated notification method
 * @param  {String} id
 * @return {Promise}
 */
const sendUpdatedNotification = (ids) => client.mutate({
  mutation: gql`
    mutation {
      sendMaterialRequestUpdatedNotification(id: ${ids})
    }
  `,
});

/**
 * Delete requests method
 * @param  {String} id
 * @return {Promise}
 */
const deleteRequests = (ids) => client.mutate({
  mutation: gql`
    mutation deleteMaterialRequests($id: [ID!]!) {
      deleteMaterialRequests(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

export default {
  getFilterOptions,
  getRequests,
  getRequestsToImport,
  createRequest,
  createPdf,
  sendCreatedNotification,
  getDownloadPdfUrl,
  movePdf,
  updateRequest,
  updateRequests,
  sendUpdatedNotification,
  deleteRequests,
};
