import { takeLatest, put, call } from 'redux-saga/effects';
import { authActions } from 'store/modules/auth';
import { uiActions } from 'store/modules/ui';
import auth from 'graphql/auth';
import { saveLocalStorage, removeLocalStorage, getUserFriendlyError } from 'common/utils';
import { persistor } from 'store';

/**
 * Login saga
 *
 * @param {*} action
 */
function* loginUser(action) {
  try {
    const { username, password } = action.payload;
    const response = yield auth.login(username, password);
    const { data: { login } } = response;

    yield call(saveLocalStorage, 'app-token', login.access_token);
    yield put(authActions.loginSuccess(login));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authActions.loginError(message));
  }
}

/**
 * Logout saga
 *
 * @param {*} action
 */
function* logoutUser() {
  try {
    const response = yield auth.logout();
    const { data: { logout } } = response;

    yield put(authActions.logoutSuccess(logout));
    yield call(removeLocalStorage, 'app-token');
    yield call(persistor.purge);
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authActions.logoutError(message));
  }
}

/**
 * Refresh token saga
 *
 * @param {*} action
 */
function* refreshAuthToken(action) {
  try {
    const response = yield auth.refreshToken(action.payload);
    const { data: { refreshToken } } = response;

    yield call(saveLocalStorage, 'app-token', refreshToken.access_token);
    yield put(authActions.refreshTokenSuccess(refreshToken));
    yield put(uiActions.toggleRefreshTokenDialog(false));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authActions.refreshTokenError(message));
  }
}

/**
 * Watch auth
 *
 * @export
 */
export default function* watchAuth() {
  yield takeLatest(authActions.login, loginUser);
  yield takeLatest(authActions.logout, logoutUser);
  yield takeLatest(authActions.refreshToken, refreshAuthToken);
}
