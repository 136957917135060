import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const CheckboxFieldAdapter = ({
  input, meta, label, ...rest
}) => (
  <FormControlLabel
    control={(
      <Checkbox
        {...input}
        {...rest}
        color="primary"
        onChange={(event) => input.onChange(event.target.checked)}
      />
    )}
    label={label}
  />
);

CheckboxFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckboxFieldAdapter;
