import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: (props) => (props.spacingTop ? theme.spacing(3) : 0),
    marginBottom: (props) => (props.spacingBottom ? theme.spacing(3) : 0),
  },
}));

const ErrorBanner = (props) => {
  const classes = useStyles(props);
  const { show = false, message = '' } = props;
  const [collapse, setCollapse] = useState(show);

  const onCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <Collapse in={collapse} timeout={1000}>
      <Alert onClose={onCollapse} severity="error" className={classes.alert}>{message}</Alert>
    </Collapse>
  );
};

ErrorBanner.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorBanner;
