import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Get filter options method
 * @param  {Object} payload
 * @return {Promise}
 */
const getFilterOptions = (payload) => {
  const exclude = payload.exclude.length > 0
    ? `{ column: ID, operator: NOT_IN, value: [ ${payload.exclude} ] }`
    : '';

  return (
    client.query({
      query: gql`
        query purchaseOrders($first: Int!, $page: Int!) {
          purchaseOrders(
            first: $first
            page: $page
            where: {
              AND: [
                { column: ID, operator: LIKE, value: "${payload.value}%" }
                ${exclude}
              ]
            }
            orderBy: [{
              column: ID
              order: ASC
            }]
          ) {
            data {
              id
            }
          }
        }
      `,
      variables: {
        first: payload.pagination.first,
        page: payload.pagination.page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Get orders method
 * @param  {Object} payload
 * @return {Promise}
 */
const getOrders = (payload) => {
  const { filters, pagination: { first, page } } = payload;

  const id = filters.id.length > 0 ? `{ column: ID, operator: IN, value: [ ${filters.id} ] }` : '';
  const authorId = filters.author_id.length > 0 ? `{ column: AUTHOR_ID, operator: IN, value: [ ${filters.author_id} ] }` : '';
  const constructionId = filters.construction_id.length > 0 ? `{ column: CONSTRUCTION_ID, operator: IN, value: [ ${filters.construction_id} ] }` : '';
  const supplierId = filters.supplier_id.length > 0 ? `{ column: SUPPLIER_ID, operator: IN, value: [ ${filters.supplier_id} ] }` : '';
  const materialRequestsId = filters.material_requests_id.length > 0 ? `material_requests_id : ${filters.material_requests_id}` : '';
  const createdAt = filters.created_at ? `createdAt: "${filters.created_at}"` : '';
  const deliveryDate = filters.delivery_date ? `deliveryDate: "${filters.delivery_date}"` : '';

  const where = `
    where: {
      AND: [
        ${id}
        ${authorId}
        ${constructionId}
        ${supplierId}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query purchaseOrders($first: Int!, $page: Int!) {
          purchaseOrders(
            first: $first
            page: $page
            ${where}
            ${createdAt}
            ${deliveryDate}
            ${materialRequestsId}
            orderBy: [{
              column: ID
              order: DESC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              author_id {
                full_name
              }
              construction_id {
                name
              }
              supplier_id {
                name
              }
              delivery_address
              created_at
              delivery_date
              comments
              notes
              has_pdf
              has_spreadsheet
              material_transactions {
                material_id {
                  id
                  name
                  unit
                }
                quantity
                value
                total_value
              }
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create order method
 * @param  {Object} payload
 * @return {Promise}
 */
const createOrder = (payload) => client.mutate({
  mutation: gql`
    mutation createPurchaseOrder($input: CreatePurchaseOrderInput!) {
      createPurchaseOrder(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Delete orders method
 * @param  {String} id
 * @return {Promise}
 */
const deleteOrders = (ids) => client.mutate({
  mutation: gql`
    mutation deletePurchaseOrders($id: [ID!]!) {
      deletePurchaseOrders(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Create pdf method
 * @param  {String} id
 * @return {Promise}
 */
const createPdf = (id) => client.mutate({
  mutation: gql`
    mutation {
      createPurchaseOrderPdf(id: ${id})
    }
  `,
});

/**
 * Create spreadsheet method
 * @param  {String} id
 * @return {Promise}
 */
const createSpreadsheet = (id) => client.mutate({
  mutation: gql`
    mutation {
      createPurchaseOrderSpreadsheet(id: ${id})
    }
  `,
});

/**
 * Send created notification method
 * @param  {String} id
 * @param  {Boolean} sendToSupplier
 * @return {Promise}
 */
const sendCreatedNotification = (id, sendToSupplier) => client.mutate({
  mutation: gql`
    mutation {
      sendPurchaseOrderCreatedNotification(id: ${id}, sendToSupplier: ${sendToSupplier})
    }
  `,
});

/**
 * Get download file url method
 * @param  {Object} payload
 * @return {Promise}
 */
const getDownloadFileUrl = (payload) => client.query({
  query: gql`
    query {
      purchaseOrderDownloadFileUrl(id: ${payload.id}, format: ${payload.format}) {
        url
      }
    }
  `,
});

export default {
  getFilterOptions,
  getOrders,
  createOrder,
  deleteOrders,
  createPdf,
  createSpreadsheet,
  sendCreatedNotification,
  getDownloadFileUrl,
};
