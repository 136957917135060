import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableBodyRow } from 'material-table';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ExplicitIcon from '@material-ui/icons/Explicit';
import { makeStyles } from '@material-ui/core/styles';
import tableIcons from 'common/tableIcons';
import TablePaginationActions from 'components/shared/TablePaginationActions';
import TableLoadingIndicator from 'components/shared/TableLoadingIndicator';
import { reportActions } from 'store/modules/report';
import { fileExportFormat } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  paginationToolbar: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  paginationSpacer: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 auto',
    },
  },
}));

const ReportTable = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    reports,
    pagination,
    paginatorInfo,
    loading,
  } = useSelector((state) => ({
    reports: state.report.reports,
    pagination: state.report.pagination,
    paginatorInfo: state.report.paginatorInfo,
    loading: state.report.loading.list,
  }));

  const data = reports.map((report) => ({
    ...report,
    created_at: format(parseISO(report.created_at), 'dd/MM/yyyy'),
  }));

  const getDisplayedRows = () => {
    const firstItem = pagination.page === 1
      ? 1
      : (pagination.page - 1) * pagination.first;

    const lastItem = pagination.page === paginatorInfo.lastPage
      ? paginatorInfo.total
      : pagination.page * pagination.first;

    return `${firstItem}-${lastItem} de ${paginatorInfo.total}`;
  };

  const onDownloadPdf = (event, rowData) => {
    dispatch(reportActions.downloadFile({
      id: rowData.report_id,
      type,
      format: fileExportFormat.pdf,
    }));
  };

  const onDownloadSpreadsheet = (event, rowData) => {
    dispatch(reportActions.downloadFile({
      id: rowData.report_id,
      type,
      format: fileExportFormat.spreadsheet,
    }));
  };

  const onPageChange = (event, newPage) => {
    dispatch(reportActions.updatePagination({ page: newPage }));
  };

  return (
    <MaterialTable
      columns={[
        { title: 'Consec.', field: 'report_id' },
        { title: 'Fecha de Creación', field: 'created_at' },
      ]}
      data={data}
      icons={tableIcons}
      isLoading={loading}
      actions={[
        {
          name: 'downloadPdf',
          icon: () => <PictureAsPdfIcon />,
          position: 'row',
          tooltip: 'Descargar PDF',
          onClick: onDownloadPdf,
        },
        {
          name: 'downloadSpreadsheet',
          icon: () => <ExplicitIcon />,
          position: 'row',
          tooltip: 'Descargar Hoja de Cálculo',
          onClick: onDownloadSpreadsheet,
        },
      ]}
      options={{
        actionsColumnIndex: -1,
        toolbar: false,
        search: false,
        showTitle: false,
        emptyRowsWhenPaging: false,
        draggable: false,
        sorting: false,
        pageSize: pagination.first,
        initialPage: pagination.page,
      }}
      localization={{
        header: {
          actions: '',
        },
        body: {
          emptyRowsWhenPaging: 'No hay reportes que mostrar.',
          emptyDataSourceMessage: 'No hay reportes que mostrar.',
        },
      }}
      components={{
        OverlayLoading: () => (<TableLoadingIndicator />),
        Pagination: () => (
          <TablePagination
            classes={{
              toolbar: classes.paginationToolbar,
              spacer: classes.paginationSpacer,
            }}
            count={paginatorInfo.total}
            page={pagination.page}
            rowsPerPage={pagination.first}
            rowsPerPageOptions={[pagination.first]}
            labelDisplayedRows={getDisplayedRows}
            onChangePage={onPageChange}
            ActionsComponent={TablePaginationActions}
          />
        ),
        Row: (props) => {
          const propsCopy = { ...props };
          propsCopy.actions.find((a) => a.name === 'downloadPdf').hidden = !propsCopy.data.has_pdf;
          propsCopy.actions.find((a) => a.name === 'downloadSpreadsheet').hidden = !propsCopy.data.has_spreadsheet;
          return <MTableBodyRow {...propsCopy} />;
        },
      }}
    />
  );
};

ReportTable.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ReportTable;
