import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { userActions } from 'store/modules/user';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import UserFilters from './Filters';
import UsersTable from './Table';

const User = () => {
  const dispatch = useDispatch();

  const { filters, pagination, refresh } = useSelector((state) => ({
    filters: state.user.filters,
    pagination: state.user.pagination,
    refresh: state.user.refresh,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.user.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.user.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(userActions.getUsers({ filters, pagination }));
  }, [filters, pagination, refresh, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Usuarios" buttonProps={buttonProps} />
      <UserFilters isOpen />
      <UsersTable />
    </React.Fragment>
  );
};

export default User;
