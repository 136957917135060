import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Get filter options
const getFilterOptions = createAction('materialType/getFilterOptions');
const getFilterOptionsSuccess = createAction('materialType/getFilterOptionsSuccess');
const getFilterOptionsError = createAction('materialType/getFilterOptionsError');

// Get material types options
const getMaterialTypes = createAction('materialType/getMaterialTypes');
const getMaterialTypesSuccess = createAction('materialType/getMaterialTypesSuccess');
const getMaterialTypesError = createAction('materialType/getMaterialTypesError');

// Create material type
const createMaterialType = createAction('materialType/createMaterialType');
const createMaterialTypeSuccess = createAction('materialType/createMaterialTypeSuccess');
const createMaterialTypeError = createAction('materialType/createMaterialTypeError');

// Delete materials type
const deleteMaterialTypes = createAction('materialType/deleteMaterialTypes');
const deleteMaterialTypesSuccess = createAction('materialType/deleteMaterialTypesSuccess');
const deleteMaterialTypesError = createAction('materialType/deleteMaterialTypesError');

// Update material type
const updateMaterialType = createAction('materialType/updateMaterialType');
const updateMaterialTypeSuccess = createAction('materialType/updateMaterialTypeSuccess');
const updateMaterialTypeError = createAction('materialType/updateMaterialTypeError');

export const materialTypeActions = {
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsError,
  getMaterialTypes,
  getMaterialTypesSuccess,
  getMaterialTypesError,
  createMaterialType,
  createMaterialTypeSuccess,
  createMaterialTypeError,
  deleteMaterialTypes,
  deleteMaterialTypesSuccess,
  deleteMaterialTypesError,
  updateMaterialType,
  updateMaterialTypeSuccess,
  updateMaterialTypeError,
};

// Initial state
const initialState = {
  materialTypes: [],
  options: [],
  loading: {
    list: false,
    filter: false,
  },
  error: null,
  refresh: false,
};

// Reducer
export default createReducer(initialState, {
  // Get filter options reducer
  [getFilterOptions]: (state) => {
    state.loading.filter = true;
    state.error = null;
  },
  [getFilterOptionsSuccess]: (state, action) => {
    state.options = action.payload;
    state.loading.filter = false;
  },
  [getFilterOptionsError]: (state, action) => {
    state.loading.filter = false;
    state.error = action.payload;
  },
  // Get material types reducer
  [getMaterialTypes]: (state) => {
    state.loading.list = true;
    state.error = null;
    state.refresh = false;
  },
  [getMaterialTypesSuccess]: (state, action) => {
    state.materialTypes = action.payload;
    state.loading.list = false;
  },
  [getMaterialTypesError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create material type reducer
  [createMaterialType]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createMaterialTypeSuccess]: (state) => {
    state.loading.list = false;
  },
  [createMaterialTypeError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Delete material types reducer
  [deleteMaterialTypes]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [deleteMaterialTypesSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [deleteMaterialTypesError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Update material type reducer
  [updateMaterialType]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [updateMaterialTypeSuccess]: (state, action) => {
    const { payload } = action;

    const index = state.materialTypes.findIndex(
      (currentMaterialType) => currentMaterialType.id === payload.id,
    );

    if (index >= 0) {
      state.materialTypes[index] = payload;
    }

    state.loading.list = false;
  },
  [updateMaterialTypeError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
