import { take, takeLatest, put } from 'redux-saga/effects';
import { materialRefundActions } from 'store/modules/materialRefund';
import { movementActions } from 'store/modules/movement';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { getMaterialRefundFilters } from 'common/filters';
import { quantityOperations } from 'common/constants';
import { createSnack, getUserFriendlyError } from 'common/utils';
import materialRefund from 'graphql/materialRefund';

/**
 * Get material refunds saga
 *
 * @param {*} action
 */
function* getMaterialRefunds(action) {
  try {
    const { payload } = action;
    const filters = getMaterialRefundFilters(payload.filters);
    const response = yield materialRefund.list({
      filters, pagination: payload.pagination,
    });
    const { data: { materialRefunds: { data, paginatorInfo } } } = response;

    yield put(materialRefundActions.getMaterialRefundsSuccess(data));
    yield put(materialRefundActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialRefundActions.getMaterialRefundsError(message));
  }
}

/**
 * Create material refund saga
 *
 * @param {*} action
 */
function* createMaterialRefund(action) {
  try {
    const { payload: { refund, movements, materialsToUpdate } } = action;
    const { data: { createMaterialRefund: { id } } } = yield materialRefund.create(refund);

    const movementsData = movements.map((movement) => ({
      ...movement,
      material_refund_id: Number(id),
    }));

    const snack = createSnack({
      key: 'refund-created-notification-success',
      variant: 'success',
      message: `Devolución de materiales ${id} creada con éxito`,
    });

    yield put(movementActions.insertMovements(movementsData));
    yield take(movementActions.insertMovementsSuccess);
    yield put(materialActions.updateMaterialByMovement({
      toUpdate: materialsToUpdate, operation: quantityOperations.increase,
    }));
    yield take(materialActions.updateMaterialByMovementSuccess);
    yield put(materialRefundActions.createPdf(id));
    yield take(materialRefundActions.createPdfSuccess);
    yield put(materialRefundActions.sendCreatedNotification(id));
    yield take(materialRefundActions.sendCreatedNotificationSuccess);
    yield put(materialRefundActions.createMaterialRefundSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialRefundActions.createMaterialRefundError(message));
  }
}

/**
 * Create material refund PDF saga
 *
 * @param {*} action
 */
function* createPdf(action) {
  try {
    const { payload: id } = action;
    yield materialRefund.createPdf(id);
    yield put(materialRefundActions.createPdfSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialRefundActions.createPdfError(message));
  }
}

/**
 * Send material refund created notification saga
 *
 * @param {*} action
 */
function* sendCreatedNotification(action) {
  try {
    const { payload: id } = action;
    const {
      data: {
        sendMaterialRefundCreatedNotification: { sent, error },
      },
    } = yield materialRefund.sendCreatedNotification(id);

    if (!sent) {
      yield put(materialRefundActions.sendCreatedNotificationError(error));
    } else {
      yield put(materialRefundActions.sendCreatedNotificationSuccess());
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialRefundActions.sendCreatedNotificationError(message));
  }
}

/**
 * Download pdf saga
 *
 * @param {*} action
 */
function* downloadPdf(action) {
  const { payload: id } = action;
  const response = yield materialRefund.getDownloadPdfUrl(id);
  const { data: { materialRefundDownloadPdfUrl: { url: downloadUrl } } } = response;

  window.open(downloadUrl, '_blank');
}

/**
 * Watch material refund
 *
 * @export
 */
export default function* watchMaterialRefund() {
  yield takeLatest(materialRefundActions.getMaterialRefunds, getMaterialRefunds);
  yield takeLatest(materialRefundActions.createMaterialRefund, createMaterialRefund);
  yield takeLatest(materialRefundActions.createPdf, createPdf);
  yield takeLatest(materialRefundActions.sendCreatedNotification, sendCreatedNotification);
  yield takeLatest(materialRefundActions.downloadPdf, downloadPdf);
}
