import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import { constructionStatus } from 'common/constants';

// Action creators

// Filter options
const getFilterOptions = createAction('construction/getFilterOptions');
const getFilterOptionsSuccess = createAction('construction/getFilterOptionsSuccess');
const getFilterOptionsError = createAction('construction/getFilterOptionsError');

// Update pagination
const updatePagination = createAction('construction/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('construction/updatePaginatorInfo');

// Update filters
const updateFilters = createAction('construction/updateFilters');

// Get constructions
const getConstructions = createAction('construction/getConstructions');
const getConstructionsSuccess = createAction('construction/getConstructionsSuccess');
const getConstructionsError = createAction('construction/getConstructionsError');

// Create construction
const createConstruction = createAction('construction/createConstruction');
const createConstructionSuccess = createAction('construction/createConstructionSuccess');
const createConstructionError = createAction('construction/createConstructionError');

// Delete constructions
const deleteConstructions = createAction('construction/deleteConstructions');
const deleteConstructionsSuccess = createAction('construction/deleteConstructionsSuccess');
const deleteConstructionsError = createAction('construction/deleteConstructionsError');

// Update construction
const updateConstruction = createAction('construction/updateConstruction');
const updateConstructionSuccess = createAction('construction/updateConstructionSuccess');
const updateConstructionError = createAction('construction/updateConstructionError');

export const constructionActions = {
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsError,
  updatePagination,
  updatePaginatorInfo,
  updateFilters,
  getConstructions,
  getConstructionsSuccess,
  getConstructionsError,
  createConstruction,
  createConstructionSuccess,
  createConstructionError,
  deleteConstructions,
  deleteConstructionsSuccess,
  deleteConstructionsError,
  updateConstruction,
  updateConstructionSuccess,
  updateConstructionError,
};

// Initial state
const initialState = {
  constructions: [],
  options: [],
  filters: {
    id: [],
    work_order: null,
    status: constructionStatus.active,
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    list: false,
    filter: false,
  },
  error: null,
  refresh: false,
};

// Reducer
export default createReducer(initialState, {
  // Get filter options reducer
  [getFilterOptions]: (state) => {
    state.loading.filter = true;
    state.error = null;
  },
  [getFilterOptionsSuccess]: (state, action) => {
    state.options = action.payload;
    state.loading.filter = false;
  },
  [getFilterOptionsError]: (state, action) => {
    state.loading.filter = false;
    state.error = action.payload;
  },
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Get constructions reducer
  [getConstructions]: (state) => {
    state.loading.list = true;
    state.error = null;
    state.refresh = false;
  },
  [getConstructionsSuccess]: (state, action) => {
    state.constructions = action.payload;
    state.loading.list = false;
  },
  [getConstructionsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create construction reducer
  [createConstruction]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createConstructionSuccess]: (state) => {
    state.loading.list = false;
  },
  [createConstructionError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Delete constructions reducer
  [deleteConstructions]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [deleteConstructionsSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [deleteConstructionsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Update construction reducer
  [updateConstruction]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [updateConstructionSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [updateConstructionError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
