import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { materialTypeActions } from 'store/modules/materialType';
import routes from 'common/routes';
import MaterialFilters from './Filters';
import MaterialsTable from './Table';

const Material = () => {
  const dispatch = useDispatch();

  const { filters, pagination, refresh } = useSelector((state) => ({
    filters: state.material.filters,
    pagination: state.material.pagination,
    refresh: state.material.refresh,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.material.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.material.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(materialActions.getMaterials({ filters, pagination }));
  }, [filters, pagination, refresh, dispatch]);

  useEffect(() => {
    dispatch(materialTypeActions.getFilterOptions());
  }, [dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Materiales" buttonProps={buttonProps} />
      <MaterialFilters isOpen />
      <MaterialsTable />
    </React.Fragment>
  );
};

export default Material;
