import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { materialTypeActions } from 'store/modules/materialType';
import tableIcons from 'common/tableIcons';
import TableLoadingIndicator from 'components/shared/TableLoadingIndicator';
import routes from 'common/routes';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(8),
    },
  },
}));

const MaterialTypesTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    materialTypes,
    loading,
  } = useSelector((state) => ({
    materialTypes: state.materialType.materialTypes,
    loading: state.materialType.loading.list,
  }));

  const data = materialTypes.map((materialType) => ({
    ...materialType,
    name: materialType.name.toUpperCase(),
  }));

  const goToEdit = (event, rowData) => {
    history.push(routes.materialType.update.path.replace(':id', rowData.id));
  };

  const onDelete = (event, rowData) => {
    const ids = rowData.map((row) => row.id);
    dispatch(materialTypeActions.deleteMaterialTypes(ids));
  };

  return (
    <Paper square className={classes.container}>
      <MaterialTable
        columns={[
          { title: 'Consec.', field: 'id' },
          { title: 'Nombre', field: 'name', width: 500 },
          { title: 'Materiales', field: 'materials_count' },
        ]}
        data={data}
        icons={tableIcons}
        isLoading={loading}
        actions={[
          {
            icon: () => <DeleteIcon />,
            position: 'toolbarOnSelect',
            tooltip: 'Eliminar',
            onClick: onDelete,
          },
          {
            icon: () => <EditIcon />,
            position: 'row',
            tooltip: 'Editar',
            onClick: goToEdit,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          draggable: false,
          sorting: false,
          selection: true,
          paging: false,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyRowsWhenPaging: 'No hay tipos de materiales que mostrar',
            emptyDataSourceMessage: 'No hay tipos de materiales que mostrar',
          },
          toolbar: {
            nRowsSelected: '{0} fila(s) seleccionada(s)',
          },
        }}
        components={{
          OverlayLoading: () => (<TableLoadingIndicator />),
        }}
      />
    </Paper>
  );
};

export default MaterialTypesTable;
