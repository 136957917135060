import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators
const toggleDrawer = createAction('ui/toggleDrawer');
const toggleTheme = createAction('ui/toggleTheme');
const toggleMiniDrawer = createAction('ui/toggleMiniDrawer');

// Dialogs
const toggleRefreshTokenDialog = createAction('ui/toggleRefreshTokenDialog');
const toggleImportMaterialsDialog = createAction('ui/toggleImportMaterialsDialog');

// Page title
const updatePageTitle = createAction('ui/updatePageTitle');

// Notistack
const enqueueSnackbar = createAction('ENQUEUE_SNACKBAR');
const closeSnackbar = createAction('CLOSE_SNACKBAR');
const removeSnackbar = createAction('REMOVE_SNACKBAR');

export const uiActions = {
  toggleDrawer,
  toggleTheme,
  toggleMiniDrawer,
  toggleRefreshTokenDialog,
  toggleImportMaterialsDialog,
  updatePageTitle,
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
};

// Initial state
const initialState = {
  drawer: false,
  darkMode: true,
  miniDrawer: false,
  notifications: [],
  refreshTokenDialog: false,
  importMaterialsDialog: false,
  pageTitle: 'Ingresar',
};

// Reducer
export default createReducer(initialState, {
  // Toggle drawer reducer
  [toggleDrawer]: (state) => {
    state.drawer = !state.drawer;
  },
  // Toggle theme reducer
  [toggleTheme]: (state, action) => {
    state.darkMode = action.payload;
  },
  // Toggle mini drawer reducer
  [toggleMiniDrawer]: (state) => {
    state.miniDrawer = !state.miniDrawer;
  },
  // Toggle refresh token dialog reducer
  [toggleRefreshTokenDialog]: (state, action) => {
    state.refreshTokenDialog = action.payload;
  },
  // Toggle import materials dialog reducer
  [toggleImportMaterialsDialog]: (state, action) => {
    state.importMaterialsDialog = action.payload;
  },
  // Update page title reducer
  [updatePageTitle]: (state, action) => {
    state.pageTitle = action.payload;
  },
  // Snackbar reducer
  [enqueueSnackbar]: (state, action) => {
    state.notifications = [
      ...state.notifications, {
        ...action.payload.notification,
        key: action.payload.key,
      },
    ];
  },
  [closeSnackbar]: (state, action) => {
    state.notifications = state.notifications.map((notification) => (
      (action.payload.dismissAll || notification.key === action.payload.key)
        ? { ...notification, dismissed: true }
        : { ...notification }
    ));
  },
  [removeSnackbar]: (state, action) => {
    state.notifications = state.notifications.filter(
      (notification) => notification.key !== action.payload,
    );
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
