import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { materialRefundActions } from 'store/modules/materialRefund';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import MaterialRefundFilters from './Filters';
import MaterialRefundTable from './Table';

const MaterialRefund = () => {
  const dispatch = useDispatch();

  const { filters, pagination } = useSelector((state) => ({
    filters: state.materialRefund.filters,
    pagination: state.materialRefund.pagination,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.materialRefund.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.materialRefund.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(materialRefundActions.getMaterialRefunds({ filters, pagination }));
  }, [filters, pagination, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Devoluciones de Materiales" buttonProps={buttonProps} />
      <MaterialRefundFilters isOpen />
      <MaterialRefundTable />
    </React.Fragment>
  );
};

export default MaterialRefund;
