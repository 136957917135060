import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Get filter options method
 * @param  {Object} payload
 * @return {Promise}
 */
const getFilterOptions = (payload) => {
  const exclude = payload.exclude.length > 0
    ? `{ column: ID, operator: NOT_IN, value: [ ${payload.exclude} ] }`
    : '';

  return (
    client.query({
      query: gql`
        query constructions($first: Int!, $page: Int!) {
          constructions(
            first: $first
            page: $page
            where: {
              AND: [
                { column: NAME, operator: LIKE, value: "%${payload.value}%" }
                ${exclude}
              ]
            }
            orderBy: [{
              column: NAME
              order: ASC
            }]
          ) {
            data {
              id
              name
            }
          }
        }
      `,
      variables: {
        first: payload.pagination.first,
        page: payload.pagination.page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Get constructions method
 * @param  {Object} payload
 * @return {Promise}
 */
const getConstructions = (payload) => {
  const { filters, pagination: { first, page } } = payload;

  const id = filters.id.length > 0 ? `{ column: ID, operator: IN, value: [ ${filters.id} ] }` : '';
  const status = filters.status ? `{ column: STATUS, value: "${filters.status}" }` : '';
  const name = filters.name ? `{ column: NAME, operator: LIKE, value: "${filters.name}%"}` : '';
  const work_order = filters.work_order ? `{ column: WORK_ORDER, operator: LIKE, value: "${filters.work_order}%"}` : '';

  const where = `
    where: {
      AND: [
        ${id}
        ${status}
        ${name}
        ${work_order}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query constructions($first: Int!, $page: Int!) {
          constructions(
            first: $first
            page: $page
            ${where}
            orderBy: [{
              column: ID
              order: DESC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              name
              work_order
              status
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create construction method
 * @param  {Object} payload
 * @return {Promise}
 */
const createConstruction = (payload) => client.mutate({
  mutation: gql`
    mutation createConstruction($input: CreateConstructionInput!) {
      createConstruction(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Delete constructions method
 * @param  {String} ids
 * @return {Promise}
 */
const deleteConstructions = (ids) => client.mutate({
  mutation: gql`
    mutation deleteConstructions($id: [ID!]!) {
      deleteConstructions(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Update construction method
 * @param  {Object} payload
 * @return {Promise}
 */
const updateConstruction = (payload) => client.mutate({
  mutation: gql`
    mutation updateConstruction($id: ID!, $input: UpdateConstructionInput!) {
      updateConstruction(id: $id, input: $input) {
        id
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

export default {
  getFilterOptions,
  getConstructions,
  createConstruction,
  deleteConstructions,
  updateConstruction,
};
