import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Login
const login = createAction('auth/login');
const loginSuccess = createAction('auth/loginSuccess');
const loginError = createAction('auth/loginError');

// Logout
const logout = createAction('auth/logout');
const logoutSuccess = createAction('auth/logoutSuccess');
const logoutError = createAction('auth/logoutError');

// Refresh token
const refreshToken = createAction('auth/refreshToken');
const refreshTokenSuccess = createAction('auth/refreshTokenSuccess');
const refreshTokenError = createAction('auth/refreshTokenError');

// Update current user
const updateCurrentUser = createAction('auth/updateCurrentUser');

export const authActions = {
  login,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  logoutError,
  refreshToken,
  refreshTokenSuccess,
  refreshTokenError,
  updateCurrentUser,
};

// Initial state
const initialState = {
  loading: false,
  isLoggedIn: false,
  isLoggingOut: false,
  user: null,
  error: null,
  access_token: null,
  expires_in: null,
  refresh_token: null,
};

// Reducer
export default createReducer(initialState, {
  // Login reducer
  [login]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [loginSuccess]: (state, action) => {
    const {
      access_token, expires_in, refresh_token, user,
    } = action.payload;

    state.loading = false;
    state.isLoggedIn = true;
    state.access_token = access_token;
    state.expires_in = expires_in;
    state.refresh_token = refresh_token;
    state.user = user;
  },
  [loginError]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  // Logout reducer
  [logout]: (state) => {
    state.isLoggingOut = true;
  },
  [logoutError]: (state) => {
    state.isLoggingOut = false;
  },
  [refreshToken]: (state) => {
    state.loading = true;
    state.error = null;
  },
  // Refresh token reducer
  [refreshTokenSuccess]: (state, action) => {
    const { access_token, expires_in, refresh_token } = action.payload;

    state.access_token = access_token;
    state.expires_in = expires_in;
    state.refresh_token = refresh_token;
    state.loading = false;
  },
  [refreshTokenError]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  // Update current user reducer
  [updateCurrentUser]: (state, action) => {
    const { payload } = action;
    const { user } = state;

    if (user.id === payload.id) {
      state.user = payload;
    }
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
