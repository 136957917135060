import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Update pagination
const updatePagination = createAction('materialEntry/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('materialEntry/updatePaginatorInfo');

// Update filters
const updateFilters = createAction('materialEntry/updateFilters');

// Get material entries
const getMaterialEntries = createAction('materialEntry/getMaterialEntries');
const getMaterialEntriesSuccess = createAction('materialEntry/getMaterialEntriesSuccess');
const getMaterialEntriesError = createAction('materialEntry/getMaterialEntriesError');

// Create material entry
const createMaterialEntry = createAction('materialEntry/createMaterialEntry');
const createMaterialEntrySuccess = createAction('materialEntry/createMaterialEntrySuccess');
const createMaterialEntryError = createAction('materialEntry/createMaterialEntryError');

// Create pdf
const createPdf = createAction('materialEntry/createPdf');
const createPdfSuccess = createAction('materialEntry/createPdfSuccess');
const createPdfError = createAction('materialEntry/createPdfError');

// Send created notification
const sendCreatedNotification = createAction('materialEntry/sendCreatedNotification');
const sendCreatedNotificationSuccess = createAction('materialEntry/sendCreatedNotificationSuccess');
const sendCreatedNotificationError = createAction('materialEntry/sendCreatedNotificationError');

// Download pdf
const downloadPdf = createAction('materialEntry/downloadPdf');

export const materialEntryActions = {
  updatePagination,
  updatePaginatorInfo,
  updateFilters,
  getMaterialEntries,
  getMaterialEntriesSuccess,
  getMaterialEntriesError,
  createMaterialEntry,
  createMaterialEntrySuccess,
  createMaterialEntryError,
  createPdf,
  createPdfSuccess,
  createPdfError,
  sendCreatedNotification,
  sendCreatedNotificationSuccess,
  sendCreatedNotificationError,
  downloadPdf,
};

// Initial state
const initialState = {
  materialEntries: [],
  options: [],
  filters: {
    author_id: [],
    purchase_order_id: [],
    created_at: null,
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    list: false,
    filter: false,
  },
  error: null,
};

// Reducer
export default createReducer(initialState, {
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Get material entries reducer
  [getMaterialEntries]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [getMaterialEntriesSuccess]: (state, action) => {
    state.materialEntries = action.payload;
    state.loading.list = false;
  },
  [getMaterialEntriesError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create material entry reducer
  [createMaterialEntry]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createMaterialEntrySuccess]: (state) => {
    state.loading.list = false;
  },
  [createMaterialEntryError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create pdf reducer
  [createPdf]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createPdfError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Send created notification reducer
  [sendCreatedNotification]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [sendCreatedNotificationError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
