import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Create material movements
const insertMovements = createAction('movement/insertMovements');
const insertMovementsSuccess = createAction('movement/insertMovementsSuccess');
const insertMovementsError = createAction('movement/insertMovementsError');

export const movementActions = {
  insertMovements,
  insertMovementsSuccess,
  insertMovementsError,
};

// Initial state
const initialState = {
  success: false,
  error: null,
};

// Reducer
export default createReducer(initialState, {
  // Insert material movements reducer
  [insertMovements]: (state) => {
    state.success = false;
    state.error = null;
  },
  [insertMovementsSuccess]: (state) => {
    state.success = true;
  },
  [insertMovementsError]: (state, action) => {
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
