import React from 'react';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

const SwitchFieldAdapter = ({ input, ...rest }) => (
  <Switch
    {...input}
    {...rest}
    color="primary"
    inputProps={{ 'aria-label': 'primary checkbox' }}
    onChange={(event) => input.onChange(event.target.checked)}
  />
);

SwitchFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
};

export default SwitchFieldAdapter;
