import { takeLatest, put } from 'redux-saga/effects';
import { constructionActions } from 'store/modules/construction';
import { uiActions } from 'store/modules/ui';
import { getDefaultFilters } from 'common/filters';
import { createSnack, getUserFriendlyError } from 'common/utils';
import construction from 'graphql/construction';

/**
 * Get filter options saga
 *
 * @param {*} action
 */
function* getFilterOptions(action) {
  try {
    const params = 'exclude' in action.payload ? action.payload : { ...action.payload, exclude: [] };
    const response = yield construction.getFilterOptions(params);
    const { data: { constructions: { data } } } = response;
    const options = data.map((option) => ({ value: option.id, label: option.name }));

    yield put(constructionActions.getFilterOptionsSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(constructionActions.getFilterOptionsError(message));
  }
}

/**
 * Get constructions saga
 *
 * @param {*} action
 */
function* getConstructions(action) {
  try {
    const { payload } = action;
    const filters = getDefaultFilters(payload.filters);
    const response = yield construction.getConstructions({
      filters, pagination: payload.pagination,
    });
    const { data: { constructions: { data, paginatorInfo } } } = response;

    yield put(constructionActions.getConstructionsSuccess(data));
    yield put(constructionActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(constructionActions.getConstructionsError(message));
  }
}

/**
 * Create construction saga
 *
 * @param {*} action
 */
function* createConstruction(action) {
  try {
    yield construction.createConstruction(action.payload);

    const snack = createSnack({
      key: 'construction-created-notification-success',
      variant: 'success',
      message: 'Obra creada con éxito',
    });

    yield put(constructionActions.createConstructionSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(constructionActions.createConstructionError(message));
  }
}

/**
 * Delete constructions saga
 *
 * @param {*} action
 */
function* deleteConstructions(action) {
  try {
    const {
      data: { deleteConstructions: deletedConstructions },
    } = yield construction.deleteConstructions(action.payload);

    const snack = createSnack({
      key: 'delete-constructions-notification-success',
      variant: 'success',
      message: `${deletedConstructions.length > 1 ? 'Obras eliminadas' : 'Obra eliminada'} con éxito`,
    });

    yield put(constructionActions.deleteConstructionsSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(constructionActions.deleteConstructionsError(message));
  }
}

/**
 * Update construction saga
 *
 * @param {*} action
 */
function* updateConstruction(action) {
  try {
    const {
      data: { updateConstruction: updatedConstruction },
    } = yield construction.updateConstruction(action.payload);

    const snack = createSnack({
      key: 'update-construction-notification-success',
      variant: 'success',
      message: 'La obra fue actualizada con éxito',
    });

    yield put(constructionActions.updateConstructionSuccess(updatedConstruction));
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(constructionActions.updateConstructionError(message));
  }
}

/**
 * Watch construction
 *
 * @export
 */
export default function* watchConstruction() {
  yield takeLatest(constructionActions.getFilterOptions, getFilterOptions);
  yield takeLatest(constructionActions.getConstructions, getConstructions);
  yield takeLatest(constructionActions.createConstruction, createConstruction);
  yield takeLatest(constructionActions.deleteConstructions, deleteConstructions);
  yield takeLatest(constructionActions.updateConstruction, updateConstruction);
}
