import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import formatISO9075 from 'date-fns/formatISO9075';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import clsx from 'clsx';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { materialExitActions } from 'store/modules/materialExit';
import { constructionActions } from 'store/modules/construction';
import { userActions } from 'store/modules/user';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { debounceWait, operationType } from 'common/constants';
import routes from 'common/routes';
import { filtersPagination } from 'common/filters';
import { getTotalValue } from 'common/utils';
import { hasValue, positiveNumber } from 'common/validations';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(3),
    '&.bottom-spacing': {
      marginBottom: theme.spacing(3),
    },
  },
  buttonGrid: {
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '100%',
      },
    },
  },
  deleteWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '0 !important',
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  alertRoot: {
    borderRadius: 0,
  },
}));

const initialValues = {
  created_at: Date.now(),
  construction_id: '',
  requested_by_id: null,
  approved_by_id: null,
  materials: [],
  comments: '',
};

const CreateMaterialExit = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    constructionOptions,
    constructionLoading,
    userOptions,
    userLoading,
    canApprove,
    materialOptions,
    materialLoading,
    loading,
    userId,
  } = useSelector((state) => ({
    constructionOptions: state.construction.options,
    constructionLoading: state.construction.loading.filter,
    userOptions: state.user.options,
    userLoading: state.user.loading.filter,
    canApprove: state.user.canApprove,
    materialOptions: state.material.options,
    materialLoading: state.material.loading.filter,
    loading: state.materialExit.loading.list,
    userId: state.auth.user.id,
  }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.materialExit.create.title));
  }, [dispatch]);

  useEffect(() => {
    if (canApprove.length === 0) {
      dispatch(userActions.getUsersWhoCanApprove({
        filters: { can_approve: true },
        pagination: filtersPagination,
      }));
    }
  }, [canApprove.length, dispatch]);

  const canApproveOptions = canApprove.map((userCanApprove) => ({
    value: userCanApprove.id, label: userCanApprove.full_name,
  }));

  const composeValidators = (...validators) => (value, allValues, meta) => validators.reduce(
    (error, validator) => error || validator(value, allValues, meta), undefined,
  );

  const required = (value) => (value ? undefined : 'Este campo es requerido');

  const isPositiveNumber = (value) => (positiveNumber(value) ? undefined : 'Este campo solo admite números positivos');

  const isValidOutgoingQuantity = (value, allValues, meta) => {
    const { name } = meta;
    const materialIndex = Number(/\[(.*?)\]/.exec(name)[1]);
    const { quantity } = allValues.materials[materialIndex].item;

    return value <= quantity ? undefined : 'La cantidad saliente no puede ser mayor a la cantidad disponible';
  };

  const validate = (values) => {
    const errors = {};

    if (!hasValue(values.construction_id)) {
      errors.construction_id = 'Este campo no puede estar vacío';
    }

    if (!hasValue(values.requested_by_id)) {
      errors.requested_by_id = 'Este campo no puede estar vacío';
    }

    if (!hasValue(values.approved_by_id)) {
      errors.approved_by_id = 'Este campo no puede estar vacío';
    }

    if (!hasValue(values.materials)) {
      errors.materials = 'Este campo no puede estar vació';
    }

    return errors;
  };

  const getSubmitData = (values) => {
    const { materials } = values;
    const movements = [];
    const materialsToUpdate = [];

    for (let i = 0; i < materials.length; i += 1) {
      const {
        item: {
          outgoingQuantity, price, iva, value,
        },
      } = materials[i];

      const castId = Number(value);
      const castOutgoingQuantity = Number(outgoingQuantity);
      const castPrice = Number(price);
      const totalValue = getTotalValue(price, outgoingQuantity, iva);

      movements.push({
        material_id: castId,
        type: operationType.exit,
        quantity: castOutgoingQuantity,
        value: castPrice,
        total_value: totalValue,
        created_at: formatISO9075(values.created_at),
        updated_at: formatISO9075(values.created_at),
      });

      materialsToUpdate.push({
        id: castId,
        quantity: castOutgoingQuantity,
      });
    }

    return { movements, materialsToUpdate };
  };

  const onConstructionIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(constructionActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onMaterialIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(materialActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onRequestedByIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(userActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const isOptionDisabled = (option) => option.quantity === 0 || option.price === 0;

  const resetAll = (args, state) => {
    const form = args[0];
    const { formState: { values: { materials } } } = state;

    for (let i = 0; i < materials.length; i += 1) {
      form.mutators.pop('materials');
    }

    form.restart();
  };

  const onSubmit = (values) => {
    const submitData = getSubmitData(values);

    const data = {
      exit: {
        author_id: Number(userId),
        construction_id: Number(values.construction_id.value),
        requested_by_id: Number(values.requested_by_id.value),
        approved_by_id: Number(values.approved_by_id),
        created_at: formatISO9075(values.created_at),
        comments: values.comments,
      },
      movements: submitData.movements,
      materialsToUpdate: submitData.materialsToUpdate,
    };

    dispatch(materialExitActions.createMaterialExit(data));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Crear Salida de Materiales" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          mutators={{
            resetAll,
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            invalid,
            pristine,
            form,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="construction_id-create"
                    name="construction_id"
                    component={AutocompleteFieldAdapter}
                    options={constructionOptions}
                    isMultiple={false}
                    filterSelectedOptions={false}
                    loading={constructionLoading}
                    onInputChange={_debounce(onConstructionIdChange, debounceWait)}
                    textProps={{
                      label: 'Obra',
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="requested_by_id-create"
                    name="requested_by_id"
                    component={AutocompleteFieldAdapter}
                    options={userOptions}
                    isMultiple={false}
                    filterSelectedOptions
                    loading={userLoading}
                    onInputChange={_debounce(onRequestedByIdChange, debounceWait)}
                    textProps={{
                      label: 'Solicitada Por',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="approved_by_id-create"
                    name="approved_by_id"
                    component={SelectFieldAdapter}
                    options={canApproveOptions}
                    label="Aprobada Por"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="created_at-create"
                    name="created_at"
                    component={DatePickerFieldAdapter}
                    label="Fecha de creación"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={clsx(classes.grid, classes.buttonGrid, 'bottom-spacing')}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => push('materials', undefined)}
                  >
                    Agregar Material
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={clsx(classes.grid, 'bottom-spacing')}>
                <Grid item xs={12}>
                  <FieldArray name="materials">
                    {({ fields }) => fields.map((name, index) => (
                      <Grid container spacing={3} key={name}>
                        <Grid item xs={12} sm={12} md={5}>
                          <Field
                            id={`material_id-create-${index}`}
                            name={`${name}.item`}
                            component={AutocompleteFieldAdapter}
                            options={materialOptions}
                            isMultiple={false}
                            filterSelectedOptions={false}
                            loading={materialLoading}
                            onInputChange={_debounce(onMaterialIdChange, debounceWait)}
                            getOptionDisabled={isOptionDisabled}
                            required
                            validate={required}
                            textProps={{
                              label: 'Material',
                            }}
                            subscription={{ active: true, value: true }}
                          />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Field
                            name={`${name}.item.unit`}
                            component={TextFieldAdapter}
                            label="Unidad"
                            disabled
                            fullWidth
                            subscription={{ value: true }}
                          />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Field
                            name={`${name}.item.quantity`}
                            component={TextFieldAdapter}
                            label="CANT. Disponible"
                            type="number"
                            disabled
                            fullWidth
                            subscription={{
                              value: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Field
                            name={`${name}.item.outgoingQuantity`}
                            component={TextFieldAdapter}
                            label="CANT. Saliente"
                            type="number"
                            required
                            validate={composeValidators(isPositiveNumber, isValidOutgoingQuantity)}
                            fullWidth
                            subscription={{
                              value: true,
                              invalid: true,
                              touched: true,
                              error: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} sm={1} md={1} className={classes.deleteWrapper}>
                          <IconButton
                            color="secondary"
                            onClick={() => fields.remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </FieldArray>
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  <Field
                    id="comments-create"
                    name="comments"
                    component={TextFieldAdapter}
                    label="Comentarios"
                    multiline
                    maxRows="4"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.mutators.resetAll(form);
                    }}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={8} sm={6} className={classes.submit}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.materialExit.index.path}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || pristine || loading}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
      <Alert severity="info" variant="filled" classes={{ root: classes.alertRoot }}>
        Tenga en cuenta que una vez creada, la salida de
        materiales no puede ser modificada, ni eliminada
      </Alert>
    </React.Fragment>
  );
};

export default CreateMaterialExit;
