import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import { requestActions } from 'store/modules/request';
import { userActions } from 'store/modules/user';
import { constructionActions } from 'store/modules/construction';
import { requestStatusOptions, debounceWait } from 'common/constants';
import { filtersPagination } from 'common/filters';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const RequestFilters = ({ isOpen = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    currentUser,
    initialValues,
    loading,
    requestOptions,
    requestLoading,
    userOptions,
    userLoading,
    constructionOptions,
    constructionLoading,
  } = useSelector((state) => ({
    currentUser: state.auth.user,
    initialValues: state.request.filters,
    loading: state.request.loading.list,
    requestOptions: state.request.options,
    requestLoading: state.request.loading.filter,
    userOptions: state.user.options,
    userLoading: state.user.loading.filter,
    constructionOptions: state.construction.options,
    constructionLoading: state.construction.loading.filter,
  }));

  const onRequestIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      const toExclude = initialValues.id.length > 0
        ? initialValues.id.map((item) => item.value)
        : [];

      dispatch(requestActions.getFilterOptions({
        value, exclude: toExclude, pagination: filtersPagination,
      }));
    }
  };

  const onAuthorIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      const toExclude = initialValues.author_id.length > 0
        ? initialValues.author_id.map((item) => item.value)
        : [];

      dispatch(userActions.getFilterOptions({
        value, exclude: toExclude, pagination: filtersPagination,
      }));
    }
  };

  const onConstructionIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      const toExclude = initialValues.construction_id.length > 0
        ? initialValues.construction_id.map((item) => item.value)
        : [];

      dispatch(constructionActions.getFilterOptions({
        value, exclude: toExclude, pagination: filtersPagination,
      }));
    }
  };

  const onSubmit = (values) => {
    dispatch(requestActions.updateFilters(values));
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Accordion square defaultExpanded={isOpen}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Filtros</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="id-filter"
                    name="id"
                    component={AutocompleteFieldAdapter}
                    options={requestOptions}
                    isMultiple
                    filterSelectedOptions
                    loading={requestLoading}
                    onInputChange={_debounce(onRequestIdChange, debounceWait)}
                    textProps={{
                      label: 'Consecutivo',
                    }}
                  />
                </Grid>
                {currentUser.is_admin && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      id="author_id-filter"
                      name="author_id"
                      component={AutocompleteFieldAdapter}
                      options={userOptions}
                      isMultiple
                      filterSelectedOptions
                      loading={userLoading}
                      onInputChange={_debounce(onAuthorIdChange, debounceWait)}
                      textProps={{
                        label: 'Autor',
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="construction_id-filter"
                    name="construction_id"
                    component={AutocompleteFieldAdapter}
                    options={constructionOptions}
                    isMultiple
                    filterSelectedOptions
                    loading={constructionLoading}
                    onInputChange={_debounce(onConstructionIdChange, debounceWait)}
                    textProps={{
                      label: 'Obra',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="delivery_date-filter"
                    name="delivery_date"
                    component={DatePickerFieldAdapter}
                    label="Fecha de Entrega"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="status-filter"
                    name="status"
                    component={SelectFieldAdapter}
                    options={requestStatusOptions}
                    label="Estado"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="created_at-filter"
                    name="created_at"
                    component={DatePickerFieldAdapter}
                    label="Fecha de Creación"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button
                type="submit"
                size="small"
                color="primary"
                disabled={pristine || loading}
              >
                Filtrar
              </Button>
            </AccordionActions>
          </Accordion>
        </form>
      )}
    />
  );
};

RequestFilters.defaultProps = {
  isOpen: false,
};

RequestFilters.propTypes = {
  isOpen: PropTypes.bool,
};

export default RequestFilters;
