import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import PasswordFieldAdapter from 'components/shared/PasswordFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import SwitchFieldAdapter from 'components/shared/SwitchFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { userActions } from 'store/modules/user';
import { uiActions } from 'store/modules/ui';
import { userRole, userRoleOptions } from 'common/constants';
import routes from 'common/routes';
import { validateUser } from 'common/formValidations';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  noBorder: {
    borderBottom: 0,
  },
}));

const initialValues = {
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  role: userRole.user,
  settings: {
    notifications: {
      order: {
        created: false,
      },
      request: {
        created: false,
        updated: false,
      },
    },
  },
};

const CreateUser = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { loading } = useSelector((state) => ({ loading: state.user.loading.list }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.user.create.title));
  }, [dispatch]);

  const [showPassword, setShowPassword] = useState(false);

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resetAll = (args) => {
    const form = args[0];

    form.restart();
  };

  const onSubmit = (values) => {
    const transformedValues = {
      ...values,
      username: values.username.toLowerCase(),
      settings: JSON.stringify(values.settings),
    };

    dispatch(userActions.createUser(transformedValues));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Crear Usuario" backButton />
      <Form
        initialValues={initialValues}
        validate={validateUser}
        onSubmit={onSubmit}
        mutators={{
          resetAll,
        }}
        render={({
          handleSubmit,
          invalid,
          pristine,
          form,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Paper square className={classes.paper}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="username-create"
                    name="username"
                    component={TextFieldAdapter}
                    label="Nombre de usuario"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="email-create"
                    name="email"
                    component={TextFieldAdapter}
                    label="Email"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="first_name-create"
                    name="first_name"
                    component={TextFieldAdapter}
                    label="Nombre"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="last_name-create"
                    name="last_name"
                    component={TextFieldAdapter}
                    label="Apellido"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="password-create"
                    name="password"
                    component={PasswordFieldAdapter}
                    label="Contraseña"
                    showPassword={showPassword}
                    onClickShowPassword={onClickShowPassword}
                    onMouseDownPassword={onMouseDownPassword}
                    autoComplete="new-password"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="role-create"
                    name="role"
                    component={SelectFieldAdapter}
                    options={userRoleOptions}
                    label="Rol"
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper square className={classes.paper}>
              <DialogLoadingIndicator open={loading} />
              <Typography variant="h6" gutterBottom>
                Notificaciones
              </Typography>
              <Table aria-label="notificacions-table">
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell align="center">Creada</TableCell>
                    <TableCell align="center">Actualizada</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Requisición de Materiales</TableCell>
                    <TableCell align="center">
                      <Field
                        id="request-created-notification-create"
                        name="settings.notifications.request.created"
                        checked={values.settings.notifications.request.created}
                        component={SwitchFieldAdapter}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Field
                        id="request-updated-notification-create"
                        name="settings.notifications.request.updated"
                        checked={values.settings.notifications.request.updated}
                        component={SwitchFieldAdapter}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.noBorder}>Orden de Compra</TableCell>
                    <TableCell align="center" className={classes.noBorder}>
                      <Field
                        id="order-created-notification-create"
                        name="settings.notifications.order.created"
                        checked={values.settings.notifications.order.created}
                        component={SwitchFieldAdapter}
                      />
                    </TableCell>
                    <TableCell className={classes.noBorder} />
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <Grid container spacing={3} className={classes.buttons}>
              <Grid item xs={4} sm={6}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    form.mutators.resetAll(form);
                  }}
                >
                  Reiniciar
                </Button>
              </Grid>
              <Grid item xs={8} sm={6} className={classes.submit}>
                <Button
                  className={classes.cancel}
                  type="button"
                  color="secondary"
                  component={RouterLink}
                  to={routes.user.index.path}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={invalid || pristine || loading}
                >
                  Crear
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </React.Fragment>
  );
};

export default CreateUser;
