import React, { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    display: 'table',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[800],
    opacity: 0.9,
  },
  tableCell: {
    display: 'table-cell',
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
}));

const TableLoadingIndicator = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <div className={classes.tableCell}>
        <CircularProgress size={34} thickness={5} color="secondary" />
      </div>
    </div>
  );
};

export default memo(TableLoadingIndicator);
