import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Update pagination
const updatePagination = createAction('report/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('report/updatePaginatorInfo');

// Get orders
const getReports = createAction('report/getReports');
const getReportsSuccess = createAction('report/getReportsSuccess');
const getReportsError = createAction('report/getReportsError');

// Create report
const createReport = createAction('report/createReport');
const createReportSuccess = createAction('report/createReportSuccess');
const createReportError = createAction('report/createReportError');

// Create pdf
const createPdf = createAction('report/createPdf');
const createPdfSuccess = createAction('report/createPdfSuccess');
const createPdfError = createAction('report/createPdfError');

// Create spreadsheet
const createSpreadsheet = createAction('report/createSpreadsheet');
const createSpreadsheetSuccess = createAction('report/createSpreadsheetSuccess');
const createSpreadsheetError = createAction('report/createSpreadsheetError');

// Download pdf
const downloadFile = createAction('report/downloadFile');

export const reportActions = {
  updatePagination,
  updatePaginatorInfo,
  getReports,
  getReportsSuccess,
  getReportsError,
  createReport,
  createReportSuccess,
  createReportError,
  createPdf,
  createPdfSuccess,
  createPdfError,
  createSpreadsheet,
  createSpreadsheetSuccess,
  createSpreadsheetError,
  downloadFile,
};

// Initial state
const initialState = {
  reports: [],
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 10,
    page: 1,
  },
  loading: {
    list: false,
    action: false,
  },
  error: null,
  refresh: false,
};

// Reducer
export default createReducer(initialState, {
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Get reports reducer
  [getReports]: (state) => {
    state.loading.list = true;
    state.error = null;
    state.refresh = false;
  },
  [getReportsSuccess]: (state, action) => {
    state.reports = action.payload;
    state.loading.list = false;
  },
  [getReportsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create report reducer
  [createReport]: (state) => {
    state.loading.action = true;
    state.error = null;
  },
  [createReportSuccess]: (state) => {
    state.loading.action = false;
    state.refresh = true;
  },
  [createReportError]: (state, action) => {
    state.loading.action = false;
    state.error = action.payload;
  },
  // Create pdf reducer
  [createPdf]: (state) => {
    state.loading.action = true;
    state.error = null;
  },
  [createPdfError]: (state, action) => {
    state.loading.action = false;
    state.error = action.payload;
  },
  // Create spreadsheet reducer
  [createSpreadsheetError]: (state) => {
    state.loading.action = true;
    state.error = null;
  },
  [createSpreadsheetError]: (state, action) => {
    state.loading.action = false;
    state.error = action.payload;
  },
  [createSpreadsheetSuccess]: (state) => {
    state.loading.action = false;
    state.error = null;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
