import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableBodyRow } from 'material-table';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteIcon from '@material-ui/icons/Delete';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { requestActions } from 'store/modules/request';
import tableIcons from 'common/tableIcons';
import { requestStatus, requestStringToIntStatus } from 'common/constants';
import TablePaginationActions from 'components/shared/TablePaginationActions';
import TableLoadingIndicator from 'components/shared/TableLoadingIndicator';
import TableDetail from './TableDetail';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(8),
    },
    '& .MuiTableFooter-root .MuiTablePagination-root': {
      display: 'block',
      boxSizing: 'border-box',
      clear: 'both',
    },
  },
  paginationToolbar: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  paginationSpacer: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 auto',
    },
  },
}));

const RequestsTable = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    user,
    requests,
    filters,
    pagination,
    paginatorInfo,
    loading,
  } = useSelector((state) => ({
    user: state.auth.user,
    requests: state.request.requests,
    filters: state.request.filters,
    pagination: state.request.pagination,
    paginatorInfo: state.request.paginatorInfo,
    loading: state.request.loading.list,
  }));

  const data = requests.map((request) => ({
    ...request,
    author_id: request.author_id.full_name,
    construction_id: request.construction_id.name,
    delivery_date: format(parseISO(request.delivery_date), 'dd/MM/yyyy'),
    created_at: format(parseISO(request.created_at), 'dd/MM/yyyy'),
  }));

  const getDisplayedRows = () => {
    const firstItem = pagination.page === 1
      ? 1
      : (pagination.page - 1) * pagination.first;

    const lastItem = pagination.page === paginatorInfo.lastPage
      ? paginatorInfo.total
      : pagination.page * pagination.first;

    return `${firstItem}-${lastItem} de ${paginatorInfo.total}`;
  };

  const onDownloadPdf = (event, rowData) => {
    dispatch(requestActions.downloadPdf({
      id: rowData.id,
      status: requestStringToIntStatus[rowData.status],
    }));
  };

  const onHold = (event, rowData) => {
    const ids = rowData.map((row) => row.id);
    const { status } = rowData[0];

    dispatch(requestActions.movePdf({
      ids,
      origin: { id: requestStringToIntStatus[status], text: status },
      destination: { id: requestStringToIntStatus.EVALUANDO, text: 'EVALUANDO' },
    }));
  };

  const onApprove = (event, rowData) => {
    const ids = rowData.map((row) => row.id);
    const { status } = rowData[0];

    dispatch(requestActions.movePdf({
      ids,
      origin: { id: requestStringToIntStatus[status], text: status },
      destination: { id: requestStringToIntStatus.APROBADA, text: 'APROBADA' },
    }));
  };

  const onReject = (event, rowData) => {
    const ids = rowData.map((row) => row.id);
    const { status } = rowData[0];

    dispatch(requestActions.movePdf({
      ids,
      origin: { id: requestStringToIntStatus[status], text: status },
      destination: { id: requestStringToIntStatus.RECHAZADA, text: 'RECHAZADA' },
    }));
  };

  const onDelete = (event, rowData) => {
    const ids = rowData.map((row) => row.id);
    dispatch(requestActions.deleteRequests(ids));
  };

  const onPageChange = (event, newPage) => {
    dispatch(requestActions.updatePagination({ page: newPage }));
  };

  return (
    <Paper square className={classes.container}>
      <MaterialTable
        columns={[
          { title: 'Consec.', field: 'id' },
          { title: 'Autor', field: 'author_id' },
          { title: 'Obra', field: 'construction_id' },
          { title: 'Estado', field: 'status' },
          { title: 'Fecha de Entrega', field: 'delivery_date' },
          { title: 'Fecha de Creación', field: 'created_at' },
        ]}
        data={data}
        icons={tableIcons}
        isLoading={loading}
        detailPanel={[
          (rowData) => ({
            render: () => <TableDetail data={rowData} user={user} />,
          }),
        ]}
        actions={[
          {
            name: 'download',
            icon: () => <PictureAsPdfIcon />,
            position: 'row',
            tooltip: 'Descargar PDF',
            onClick: onDownloadPdf,
          },
          {
            icon: () => <HourglassEmptyIcon />,
            position: 'toolbarOnSelect',
            tooltip: 'En Evaluación',
            onClick: onHold,
            hidden: filters.status === requestStatus.waiting,
          },
          {
            icon: () => <ThumbUpIcon />,
            position: 'toolbarOnSelect',
            tooltip: 'Aprobar',
            onClick: onApprove,
            hidden: filters.status === requestStatus.approved,
          },
          {
            icon: () => <ThumbDownIcon />,
            position: 'toolbarOnSelect',
            tooltip: 'Rechazar',
            onClick: onReject,
            hidden: filters.status === requestStatus.rejected,
          },
          {
            icon: () => <DeleteIcon />,
            position: 'toolbarOnSelect',
            tooltip: 'Eliminar',
            onClick: onDelete,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          emptyRowsWhenPaging: false,
          draggable: false,
          sorting: false,
          selection: true,
          pageSize: pagination.first,
          initialPage: pagination.page,
          detailPanelType: 'single',
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyRowsWhenPaging: 'No hay requisiciones que mostrar. Intenta cambiando los filtros',
            emptyDataSourceMessage: 'No hay requisiciones que mostrar. Intenta cambiando los filtros',
          },
          toolbar: {
            nRowsSelected: '{0} fila(s) seleccionada(s)',
          },
        }}
        components={{
          OverlayLoading: () => (<TableLoadingIndicator />),
          Pagination: () => (
            <TablePagination
              classes={{
                toolbar: classes.paginationToolbar,
                spacer: classes.paginationSpacer,
              }}
              count={paginatorInfo.total}
              page={pagination.page}
              rowsPerPage={pagination.first}
              rowsPerPageOptions={[pagination.first]}
              labelDisplayedRows={getDisplayedRows}
              onChangePage={onPageChange}
              ActionsComponent={TablePaginationActions}
            />
          ),
          Row: (props) => {
            const propsCopy = { ...props };
            propsCopy.actions.find((a) => a.name === 'download').hidden = !propsCopy.data.has_pdf;
            return <MTableBodyRow {...propsCopy} />;
          },
        }}
      />
    </Paper>
  );
};

export default RequestsTable;
