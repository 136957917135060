import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { supplierActions } from 'store/modules/supplier';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import SupplierFilters from './Filters';
import SuppliersTable from './Table';

const Supplier = () => {
  const dispatch = useDispatch();

  const { filters, pagination, refresh } = useSelector((state) => ({
    filters: state.supplier.filters,
    pagination: state.supplier.pagination,
    refresh: state.supplier.refresh,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.supplier.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.supplier.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(supplierActions.getSuppliers({ filters, pagination }));
  }, [filters, pagination, refresh, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Proveedores" buttonProps={buttonProps} />
      <SupplierFilters isOpen />
      <SuppliersTable />
    </React.Fragment>
  );
};

export default Supplier;
