import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { materialExitActions } from 'store/modules/materialExit';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import MaterialExitFilters from './Filters';
import MaterialExitTable from './Table';

const MaterialExit = () => {
  const dispatch = useDispatch();

  const { filters, pagination } = useSelector((state) => ({
    filters: state.materialExit.filters,
    pagination: state.materialExit.pagination,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.materialExit.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.materialExit.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(materialExitActions.getMaterialExits({ filters, pagination }));
  }, [filters, pagination, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Salidas de Materiales" buttonProps={buttonProps} />
      <MaterialExitFilters isOpen />
      <MaterialExitTable />
    </React.Fragment>
  );
};

export default MaterialExit;
