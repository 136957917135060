import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Update pagination
const updatePagination = createAction('materialRefund/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('materialRefund/updatePaginatorInfo');

// Update filters
const updateFilters = createAction('materialRefund/updateFilters');

// Get material refunds
const getMaterialRefunds = createAction('materialRefund/getMaterialRefunds');
const getMaterialRefundsSuccess = createAction('materialRefund/getMaterialRefundsSuccess');
const getMaterialRefundsError = createAction('materialRefund/getMaterialRefundsError');

// Create material refund
const createMaterialRefund = createAction('materialRefund/createMaterialRefund');
const createMaterialRefundSuccess = createAction('materialRefund/createMaterialRefundSuccess');
const createMaterialRefundError = createAction('materialRefund/createMaterialRefundError');

// Create pdf
const createPdf = createAction('materialRefund/createPdf');
const createPdfSuccess = createAction('materialRefund/createPdfSuccess');
const createPdfError = createAction('materialRefund/createPdfError');

// Send created notification
const sendCreatedNotification = createAction('materialRefund/sendCreatedNotification');
const sendCreatedNotificationSuccess = createAction('materialRefund/sendCreatedNotificationSuccess');
const sendCreatedNotificationError = createAction('materialRefund/sendCreatedNotificationError');

// Download pdf
const downloadPdf = createAction('materialRefund/downloadPdf');

export const materialRefundActions = {
  updatePagination,
  updatePaginatorInfo,
  updateFilters,
  getMaterialRefunds,
  getMaterialRefundsSuccess,
  getMaterialRefundsError,
  createMaterialRefund,
  createMaterialRefundSuccess,
  createMaterialRefundError,
  createPdf,
  createPdfSuccess,
  createPdfError,
  sendCreatedNotification,
  sendCreatedNotificationSuccess,
  sendCreatedNotificationError,
  downloadPdf,
};

// Initial state
const initialState = {
  materialRefunds: [],
  options: [],
  filters: {
    author_id: [],
    construction_id: [],
    created_at: null,
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    list: false,
    filter: false,
  },
  error: null,
};

// Reducer
export default createReducer(initialState, {
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Get material refunds reducer
  [getMaterialRefunds]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [getMaterialRefundsSuccess]: (state, action) => {
    state.materialRefunds = action.payload;
    state.loading.list = false;
  },
  [getMaterialRefundsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create material refund reducer
  [createMaterialRefund]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createMaterialRefundSuccess]: (state) => {
    state.loading.list = false;
  },
  [createMaterialRefundError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create pdf reducer
  [createPdf]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createPdfError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Send created notification reducer
  [sendCreatedNotification]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [sendCreatedNotificationError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
