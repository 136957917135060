import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mainSecondaryColor } from 'common/constants';

const useStyles = makeStyles({
  constainer: (props) => ({
    width: props.size,
    height: props.size,
    display: 'flex',
    flexDirection: 'column',
    transform: 'rotate(-45deg)',
  }),
  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '31.33%',
    '&:nth-child(even)': {
      marginTop: '3%',
      marginBottom: '3%',
    },
  },
  square: {
    backgroundColor: mainSecondaryColor,
    width: '31.33%',
    height: '100%',
    '&:nth-child(2)': {
      marginRight: '3%',
      marginLeft: '3%',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
});

const Logo = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.constainer}>
      <div className={classes.row}>
        <div className={classes.square} />
      </div>
      <div className={classes.row}>
        <div className={classes.square} />
        <div className={classes.square} />
      </div>
      <div className={classes.row}>
        <div className={classes.square} />
        <div className={classes.square} />
        <div className={classes.square} />
      </div>
    </div>
  );
};

export default memo(Logo);
