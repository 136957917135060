import { take, takeLatest, put } from 'redux-saga/effects';
import { requestActions } from 'store/modules/request';
import { transactionActions } from 'store/modules/transaction';
import { uiActions } from 'store/modules/ui';
import request from 'graphql/request';
import { getRequestFilters } from 'common/filters';
import { createSnack, getUserFriendlyError } from 'common/utils';

/**
 * Get filter options saga
 *
 * @param {*} action
 */
function* getFilterOptions(action) {
  try {
    const params = 'exclude' in action.payload ? action.payload : { ...action.payload, exclude: [] };
    const response = yield request.getFilterOptions(params);
    const { data: { materialRequests: { data } } } = response;
    const options = data.map((option) => ({ value: option.id, label: option.id }));

    yield put(requestActions.getFilterOptionsSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.getFilterOptionsError(message));
  }
}

/**
 * Get requests saga
 *
 * @param {*} action
 */
function* getRequests(action) {
  try {
    const { payload } = action;
    const filters = getRequestFilters(payload.filters);
    const response = yield request.getRequests({ filters, pagination: payload.pagination });
    const { data: { materialRequests: { data, paginatorInfo } } } = response;

    yield put(requestActions.getRequestsSuccess(data));
    yield put(requestActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.getRequestsError(message));
  }
}

/**
 * Create request saga
 *
 * @param {*} action
 */
function* createRequest(action) {
  try {
    const { payload: { request: requestData, transactions } } = action;
    const { data: { createMaterialRequest: { id } } } = yield request.createRequest(requestData);

    const transactionsData = transactions.map((transaction) => ({
      ...transaction,
      material_request_id: Number(id),
    }));

    const snack = createSnack({
      key: 'send-request-created-notification-success',
      variant: 'success',
      message: `Requisición ${id} creada con éxito`,
    });

    yield put(transactionActions.insertTransactions(transactionsData));
    yield take([
      requestActions.createRequestSuccess,
      transactionActions.insertTransactionsSuccess,
    ]);
    yield put(requestActions.createPdf(id));
    yield take(requestActions.createPdfSuccess);
    yield put(requestActions.sendCreatedNotification(id));
    yield take(requestActions.sendCreatedNotificationSuccess);
    yield put(requestActions.createRequestSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.createRequestError(message));
  }
}

/**
 * Delete requests saga
 *
 * @param {*} action
 */
function* deleteRequests(action) {
  try {
    const {
      data: { deleteMaterialRequests: deletedRequests },
    } = yield request.deleteRequests(action.payload);

    const snack = createSnack({
      key: 'delete-requests-notification-success',
      variant: 'success',
      message: `${deletedRequests.length > 1 ? 'Requisiciones eliminadas' : 'Requisición eliminada'} con éxito`,
    });

    yield put(requestActions.deleteRequestsSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.deleteRequestsError(message));
  }
}

/**
 * Create request PDF saga
 *
 * @param {*} action
 */
function* createPdf(action) {
  try {
    const { payload: id } = action;
    yield request.createPdf(id);
    yield put(requestActions.createPdfSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.createPdfError(message));
  }
}

/**
 * Send created notification saga
 *
 * @param {*} action
 */
function* sendCreatedNotification(action) {
  try {
    const { payload: id } = action;
    yield request.sendCreatedNotification(id);
    yield put(requestActions.sendCreatedNotificationSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.sendCreatedNotificationError(message));
  }
}

/**
 * Download pdf saga
 *
 * @param {*} action
 */
function* downloadPdf(action) {
  const response = yield request.getDownloadPdfUrl(action.payload);
  const { data: { materialRequestDownloadPdfUrl: { url: downloadUrl } } } = response;

  window.open(downloadUrl, '_blank');
}

/**
 * Move pdf saga
 *
 * @param {*} action
 */
function* movePdf(action) {
  try {
    const { payload: { ids, origin, destination } } = action;
    yield request.movePdf({ ids, origin: origin.id, destination: destination.id });

    const snack = createSnack({
      key: 'send-request-updated-notification-success',
      variant: 'success',
      message: `${ids.length > 1 ? 'Requisiciones actualizadas' : 'Requisición actualizada'} con éxito`,
    });

    yield put(requestActions.updateRequests({
      ids,
      toUpdate: [{ status: destination.text }],
    }));
    yield take([
      requestActions.updateRequestsSuccess,
      requestActions.movePdfSuccess,
    ]);
    yield put(requestActions.sendUpdatedNotification(ids));
    yield take(requestActions.sendUpdatedNotificationSuccess);
    yield put(requestActions.movePdfSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.movePdfError(message));
  }
}

/**
 * Update request comments saga
 *
 * @param {*} action
 */
function* updateRequestComments(action) {
  try {
    const { payload } = action;

    const snack = createSnack({
      key: 'update-request-comments-notification-success',
      variant: 'success',
      message: `Requisicion ${payload.id} actualizada con éxito`,
    });

    const { data: { updateMaterialRequest: response } } = yield request.updateRequest(payload);
    yield put(requestActions.updateRequestCommentsSuccess(response));
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.updateRequestCommentsError(message));
  }
}

/**
 * Update requests saga
 *
 * @param {*} action
 */
function* updateRequests(action) {
  try {
    yield request.updateRequests(action.payload);
    yield put(requestActions.updateRequestsSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.updateRequestsError(message));
  }
}

/**
 * Send updated notification saga
 *
 * @param {*} action
 */
function* sendUpdatedNotification(action) {
  try {
    const { payload: ids } = action;
    yield request.sendUpdatedNotification(ids);
    yield put(requestActions.sendUpdatedNotificationSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(requestActions.sendUpdatedNotificationError(message));
  }
}

/**
 * Watch request
 *
 * @export
 */
export default function* watchRequest() {
  yield takeLatest(requestActions.getFilterOptions, getFilterOptions);
  yield takeLatest(requestActions.getRequests, getRequests);
  yield takeLatest(requestActions.createRequest, createRequest);
  yield takeLatest(requestActions.deleteRequests, deleteRequests);
  yield takeLatest(requestActions.createPdf, createPdf);
  yield takeLatest(requestActions.sendCreatedNotification, sendCreatedNotification);
  yield takeLatest(requestActions.downloadPdf, downloadPdf);
  yield takeLatest(requestActions.movePdf, movePdf);
  yield takeLatest(requestActions.updateRequestComments, updateRequestComments);
  yield takeLatest(requestActions.updateRequests, updateRequests);
  yield takeLatest(requestActions.sendUpdatedNotification, sendUpdatedNotification);
}
