import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.text.primary,
    marginBottom: (props) => (props.backButton ? 0 : theme.spacing(1)),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  button: {
    textAlign: 'right',
  },
}));

const SectionTitle = (props) => {
  const { text = '', buttonProps = {}, backButton = false } = props;
  const history = useHistory();
  const classes = useStyles(props);

  const gridWidth = () => {
    const button = 'text' in buttonProps;
    return button ? 9 : 12;
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between">
        <Grid item xs={gridWidth()} className={classes.titleContainer}>
          {backButton && (
            <IconButton
              aria-label="go-back"
              className={classes.icon}
              onClick={goBack}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography className={classes.title} variant="h1">{text}</Typography>
        </Grid>
        {'text' in buttonProps && (
          <Grid item xs={3} className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={buttonProps.to}
              size="small"
            >
              {buttonProps.text}
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider light />
    </React.Fragment>
  );
};

SectionTitle.defaultProps = {
  buttonProps: {},
  backButton: false,
};

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  buttonProps: PropTypes.objectOf(PropTypes.string),
  backButton: PropTypes.bool,
};

export default SectionTitle;
