import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import { userActions } from 'store/modules/user';
import { userRoleOptions, debounceWait } from 'common/constants';
import { filtersPagination } from 'common/filters';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const UserFilters = ({ isOpen = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    initialValues,
    loading,
    userOptions,
    userLoading,
  } = useSelector((state) => ({
    initialValues: state.user.filters,
    loading: state.user.loading.list,
    userOptions: state.user.options,
    userLoading: state.user.loading.filter,
  }));

  const onUserIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      const toExclude = initialValues.id.length > 0
        ? initialValues.id.map((item) => item.value)
        : [];

      dispatch(userActions.getFilterOptions({
        value, exclude: toExclude, pagination: filtersPagination,
      }));
    }
  };

  const onSubmit = (values) => {
    dispatch(userActions.updateFilters(values));
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Accordion square defaultExpanded={isOpen}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Filtros</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="id-filter"
                    name="id"
                    component={AutocompleteFieldAdapter}
                    options={userOptions}
                    isMultiple
                    filterSelectedOptions
                    loading={userLoading}
                    onInputChange={_debounce(onUserIdChange, debounceWait)}
                    textProps={{
                      label: 'Consecutivo',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="username-filter"
                    name="username"
                    component={TextFieldAdapter}
                    label="Nombre de usuario"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="email-filter"
                    name="email"
                    component={TextFieldAdapter}
                    label="Email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="first_name-filter"
                    name="first_name"
                    component={TextFieldAdapter}
                    label="Nombre"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="last_name-filter"
                    name="last_name"
                    component={TextFieldAdapter}
                    label="Apellido"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="role-filter"
                    name="role"
                    component={SelectFieldAdapter}
                    options={userRoleOptions}
                    label="Rol"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button
                type="submit"
                size="small"
                color="primary"
                disabled={pristine || loading}
              >
                Filtrar
              </Button>
            </AccordionActions>
          </Accordion>
        </form>
      )}
    />
  );
};

UserFilters.defaultProps = {
  isOpen: false,
};

UserFilters.propTypes = {
  isOpen: PropTypes.bool,
};

export default UserFilters;
