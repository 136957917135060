import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2),
  },
}));

const ImportAlert = ({ errors }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const getErrorMessages = (messages) => messages.map((message) => (
    <ListItem key={`${new Date().getTime() + Math.random()}`}>
      <ListItemText
        primary={message}
      />
    </ListItem>
  ));

  const getAlertContent = () => errors.map((error) => (
    <div>
      <Typography variant="body2">
        Fila
        {' '}
        {error.row}
      </Typography>
      <List dense>{getErrorMessages(error.errors_messages)}</List>
    </div>
  ));

  return (
    <Collapse in={open} className={classes.wrapper}>
      <Alert
        severity="error"
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      >
        {getAlertContent()}
      </Alert>
    </Collapse>
  );
};

ImportAlert.propTypes = {
  errors: PropTypes.array.isRequired,
};

export default ImportAlert;
