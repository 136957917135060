import React from 'react';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import PropTypes from 'prop-types';

const CustomMask = (props) => {
  const { inputRef, mask, ...other } = props;

  const maskToApply = mask.length > 0 ? mask : createNumberMask({
    prefix: '',
  });

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskToApply}
    />
  );
};

const MaskedFieldAdapter = (props) => {
  const {
    input,
    meta,
    mask,
    InputProps,
    ...rest
  } = props;

  const removeSpecialChars = (string) => string.replace(/[, ]+/g, '');

  return (
    <TextField
      {...input}
      {...rest}
      InputProps={{
        ...InputProps,
        mask,
        inputComponent: CustomMask,
      }}
      onChange={(event) => {
        const transformedValue = removeSpecialChars(event.target.value);
        return input.onChange(transformedValue);
      }}
      error={meta.touched ? meta.invalid : false}
      helperText={(meta.touched && meta.invalid) ? meta.error : ''}
    />
  );
};

CustomMask.defaultProps = {
  mask: [],
};

CustomMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
};

MaskedFieldAdapter.defaultProps = {
  mask: [],
};

MaskedFieldAdapter.propTypes = {
  InputProps: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  mask: PropTypes.array,
};

export default MaskedFieldAdapter;
