import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Get filter options
const getFilterOptions = createAction('material/getFilterOptions');
const getFilterOptionsSuccess = createAction('material/getFilterOptionsSuccess');
const getFilterOptionsError = createAction('material/getFilterOptionsError');

// Update pagination
const updatePagination = createAction('material/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('material/updatePaginatorInfo');

// Update filters
const updateFilters = createAction('material/updateFilters');

// Get materials
const getMaterials = createAction('material/getMaterials');
const getMaterialsSuccess = createAction('material/getMaterialsSuccess');
const getMaterialsError = createAction('material/getMaterialsError');

// Create material
const createMaterial = createAction('material/createMaterial');
const createMaterialSuccess = createAction('material/createMaterialSuccess');
const createMaterialError = createAction('material/createMaterialError');

// Import materials
const importMaterials = createAction('material/importMaterials');
const importMaterialsSuccess = createAction('material/importMaterialsSuccess');
const importMaterialsError = createAction('material/importMaterialsError');

// Delete materials
const deleteMaterials = createAction('material/deleteMaterials');
const deleteMaterialsSuccess = createAction('material/deleteMaterialsSuccess');
const deleteMaterialsError = createAction('material/deleteMaterialsError');

// Update material
const updateMaterial = createAction('material/updateMaterial');
const updateMaterialSuccess = createAction('material/updateMaterialSuccess');
const updateMaterialError = createAction('material/updateMaterialError');

// Update material by movement
const updateMaterialByMovement = createAction('material/updateMaterialByMovement');
const updateMaterialByMovementSuccess = createAction('material/updateMaterialByMovementSuccess');
const updateMaterialByMovementError = createAction('material/updateMaterialByMovementError');

export const materialActions = {
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsError,
  updatePagination,
  updatePaginatorInfo,
  updateFilters,
  getMaterials,
  getMaterialsSuccess,
  getMaterialsError,
  createMaterial,
  createMaterialSuccess,
  createMaterialError,
  importMaterials,
  importMaterialsSuccess,
  importMaterialsError,
  deleteMaterials,
  deleteMaterialsSuccess,
  deleteMaterialsError,
  updateMaterial,
  updateMaterialSuccess,
  updateMaterialError,
  updateMaterialByMovement,
  updateMaterialByMovementSuccess,
  updateMaterialByMovementError,
};

// Initial state
const initialState = {
  materials: [],
  options: [],
  filters: {
    id: [],
    material_type_id: [],
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    list: false,
    filter: false,
  },
  error: null,
  refresh: false,
};

// Reducer
export default createReducer(initialState, {
  // Get filter options reducer
  [getFilterOptions]: (state) => {
    state.loading.filter = true;
    state.error = null;
  },
  [getFilterOptionsSuccess]: (state, action) => {
    state.options = action.payload;
    state.loading.filter = false;
  },
  [getFilterOptionsError]: (state, action) => {
    state.loading.filter = false;
    state.error = action.payload;
  },
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Get materials reducer
  [getMaterials]: (state) => {
    state.loading.list = true;
    state.error = null;
    state.refresh = false;
  },
  [getMaterialsSuccess]: (state, action) => {
    state.materials = action.payload;
    state.loading.list = false;
  },
  [getMaterialsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create material reducer
  [createMaterial]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createMaterialSuccess]: (state) => {
    state.loading.list = false;
  },
  [createMaterialError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Import materials reducer
  [importMaterials]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [importMaterialsSuccess]: (state) => {
    state.loading.list = false;
  },
  [importMaterialsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload.errors;
  },
  // Delete materials reducer
  [deleteMaterials]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [deleteMaterialsSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [deleteMaterialsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Update material reducer
  [updateMaterial]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [updateMaterialSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [updateMaterialError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Update material by movement reducer
  [updateMaterialByMovement]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [updateMaterialByMovementSuccess]: (state) => {
    state.loading.list = false;
  },
  [updateMaterialByMovementError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
