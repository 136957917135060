import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { uiActions } from 'store/modules/ui';
import Logo from 'components/shared/Logo';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  text: {
    color: theme.palette.text.primary,
    width: '90%',
    marginTop: theme.spacing(10),
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(4),
  },
}));

const NotFound = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(uiActions.updatePageTitle('Página no encontrada'));
  }, [dispatch]);

  return (
    <main className={classes.main}>
      <Logo size={80} />
      <Typography
        className={classes.text}
        variant="body1"
      >
        Lo sentimos, no pudimos encontrar la página que estabas buscando.
      </Typography>
      <Button
        className={classes.button}
        type="button"
        color="secondary"
        variant="contained"
        component={RouterLink}
        to="/"
      >
        Ir al inicio
      </Button>
    </main>
  );
};

export default NotFound;
