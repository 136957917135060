import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { uiActions } from 'store/modules/ui';
import { authActions } from 'store/modules/auth';

const useStyles = makeStyles({
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const RefreshTokenDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { refreshTokenDialog: isOpen, refreshToken, loading } = useSelector((state) => ({
    refreshTokenDialog: state.ui.refreshTokenDialog,
    refreshToken: state.auth.refresh_token,
    loading: state.auth.loading,
  }));

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(uiActions.toggleRefreshTokenDialog(false));
    }
  };

  const onRefresh = () => {
    dispatch(authActions.refreshToken(refreshToken));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="refresh-token-dialog"
      aria-describedby="refresh-token-description"
      disableEscapeKeyDown
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle id="refresh-token-dialog">
        Su sesi&oacute;n ha expirado
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="refresh-token-description">
          Para reactivar su sesi&oacute;n haga clic en reactivar.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={onRefresh}
            color="primary"
            disabled={loading}
          >
            Reactivar
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              thickness={5}
              className={classes.buttonProgress}
              color="secondary"
            />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RefreshTokenDialog;
