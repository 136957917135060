const getErrors = (data) => {
  let errors = [];

  if (Object.keys(data).length > 0) {
    for (const prop in data) {
      if (Object.prototype.hasOwnProperty.call(data, prop)) {
        errors = [...errors, data[prop]];
      }
    }

    return errors;
  }

  return ['Internal Error'];
};

const errorHandler = ({
  response, operation, graphQLErrors, networkError,
}) => {
  if (response && 'errors' in response && response.errors.length > 0) {
    for (const [index, err] of response.errors.entries()) {
      // Default error handle
      if ('extensions' in err && 'validation' in err.extensions) {
        const errors = getErrors(err.extensions.validation);
        response.errors[index].message = errors;
      }

      // Unauthenticated error handle
      if (err.message === 'Unauthenticated.') {
        response.errors[index].message = 'Su sesión ha expirado';
      }

      // Login error handle
      if (operation && operation.operationName === 'login') {
        response.errors[index].message = 'El email ingresado no existe en el sistema o la contraseña y el email no concuerdan. Por favor, inténtelo de nuevo';
      }
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      console.log(`[GraphQL error]: ${err.message}`); // eslint-disable-line
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`); // eslint-disable-line
  }

  return null;
};

export default errorHandler;
