import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Get filter options
const getFilterOptions = createAction('order/getFilterOptions');
const getFilterOptionsSuccess = createAction('order/getFilterOptionsSuccess');
const getFilterOptionsError = createAction('order/getFilterOptionsError');

// Update filters
const updateFilters = createAction('order/updateFilters');

// Update pagination
const updatePagination = createAction('order/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('order/updatePaginatorInfo');

// Get orders
const getOrders = createAction('order/getOrders');
const getOrdersSuccess = createAction('order/getOrdersSuccess');
const getOrdersError = createAction('order/getOrdersError');

// Create order
const createOrder = createAction('order/createOrder');
const createOrderSuccess = createAction('order/createOrderSuccess');
const createOrderError = createAction('order/createOrderError');

// Delete orders
const deleteOrders = createAction('order/deleteOrders');
const deleteOrdersSuccess = createAction('order/deleteOrdersSuccess');
const deleteOrdersError = createAction('order/deleteOrdersError');

// Create pdf
const createPdf = createAction('order/createPdf');
const createPdfSuccess = createAction('order/createPdfSuccess');
const createPdfError = createAction('order/createPdfError');

// Create spreadsheet
const createSpreadsheet = createAction('order/createSpreadsheet');
const createSpreadsheetSuccess = createAction('order/createSpreadsheetSuccess');
const createSpreadsheetError = createAction('order/createSpreadsheetError');

// Send created notification
const sendCreatedNotification = createAction('order/sendCreatedNotification');
const sendCreatedNotificationSuccess = createAction('order/sendCreatedNotificationSuccess');
const sendCreatedNotificationError = createAction('order/sendCreatedNotificationError');

// Download pdf
const downloadFile = createAction('order/downloadFile');

export const orderActions = {
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsError,
  updateFilters,
  updatePagination,
  updatePaginatorInfo,
  getOrders,
  getOrdersSuccess,
  getOrdersError,
  createOrder,
  createOrderSuccess,
  createOrderError,
  deleteOrders,
  deleteOrdersSuccess,
  deleteOrdersError,
  createPdf,
  createPdfSuccess,
  createPdfError,
  createSpreadsheet,
  createSpreadsheetSuccess,
  createSpreadsheetError,
  sendCreatedNotification,
  sendCreatedNotificationSuccess,
  sendCreatedNotificationError,
  downloadFile,
};

// Initial state
const initialState = {
  orders: [],
  options: [],
  filters: {
    id: [],
    author_id: [],
    construction_id: [],
    supplier_id: [],
    material_requests_id: [],
    delivery_date: null,
    created_at: null,
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    list: false,
    filter: false,
  },
  error: null,
  refresh: false,
};

// Reducer
export default createReducer(initialState, {
  // Get filter options reducer
  [getFilterOptions]: (state) => {
    state.loading.filter = true;
    state.error = null;
  },
  [getFilterOptionsSuccess]: (state, action) => {
    state.options = action.payload;
    state.loading.filter = false;
  },
  [getFilterOptionsError]: (state, action) => {
    state.loading.filter = false;
    state.error = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Get orders reducer
  [getOrders]: (state) => {
    state.loading.list = true;
    state.error = null;
    state.refresh = false;
  },
  [getOrdersSuccess]: (state, action) => {
    state.orders = action.payload;
    state.loading.list = false;
  },
  [getOrdersError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create order reducer
  [createOrder]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createOrderSuccess]: (state) => {
    state.loading.list = false;
  },
  [createOrderError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Delete orders reducer
  [deleteOrders]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [deleteOrdersSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [deleteOrdersError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create pdf reducer
  [createPdf]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createPdfError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create spreadsheet reducer
  [createSpreadsheet]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createSpreadsheetError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Send created notification reducer
  [sendCreatedNotification]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [sendCreatedNotificationError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
