import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ListItemLink = (props) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const {
    icon,
    primary,
    to,
    disabled,
    nested,
  } = props;

  const renderLink = useMemo(
    () => forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  const nestedClass = nested ? classes.nested : '';

  return (
    <li>
      <ListItem button component={renderLink} alignItems="center" disabled={disabled} className={nestedClass} selected={pathname === to}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

ListItemLink.defaultProps = {
  icon: null,
  disabled: false,
  nested: false,
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  nested: PropTypes.bool,
};

export default ListItemLink;
