import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Get reports method
 * @param  {Object} payload
 * @return {Promise}
 */
const getReports = (payload) => {
  const { type, pagination: { first, page } } = payload;

  const typeFilter = `{ column: TYPE, value: "${type}" }`;

  const where = `
    where: {
      AND: [
        ${typeFilter}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query reports($first: Int!, $page: Int!) {
          reports(
            first: $first
            page: $page
            ${where}
            orderBy: [{
              column: CREATED_AT
              order: DESC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              report_id
              has_pdf
              has_spreadsheet
              created_at
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) => client.mutate({
  mutation: gql`
    mutation createReport($input: CreateReportInput!) {
      createReport(input: $input) {
        id
        report_id
        type
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Create pdf method
 * @param  {Object} payload
 * @return {Promise}
 */
const createPdf = (payload) => client.mutate({
  mutation: gql`
    mutation createReportPdf($input: CreateReportFileInput!) {
      createReportPdf(input: $input)
    }
  `,
  variables: { input: payload },
});

/**
 * Create spreadsheet method
 * @param  {Object} payload
 * @return {Promise}
 */
const createSpreadsheet = (payload) => client.mutate({
  mutation: gql`
    mutation createReportSpreadsheet($input: CreateReportFileInput!) {
      createReportSpreadsheet(input: $input)
    }
  `,
  variables: { input: payload },
});

/**
 * Get download file url method
 * @param  {Object} payload
 * @return {Promise}
 */
const getDownloadFileUrl = (payload) => client.query({
  query: gql`
    query {
      reportDownloadFileUrl(id: ${payload.id}, type: ${payload.type}, format: ${payload.format}) {
        url
      }
    }
  `,
});

export default {
  getReports,
  create,
  createPdf,
  createSpreadsheet,
  getDownloadFileUrl,
};
