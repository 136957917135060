import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Notifier from 'components/shared/Notifier';
import RefreshTokenDialog from 'components/auth/RefreshTokenDialog';
import PermanentDrawer from './PermanentDrawer';
import TemporaryDrawer from './TemporaryDrawer';
import Header from './Header';

const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  toolbar: theme.mixins.toolbar,
}));

const DefaultLayout = ({ component: Component, ...rest }) => {
  const classes = useStyles();

  const { isLoggingOut } = useSelector((state) => ({
    isLoggingOut: state.auth.isLoggingOut,
  }));

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <Backdrop open={isLoggingOut} className={classes.backdrop}>
        <CircularProgress size={34} thickness={5} color="secondary" />
      </Backdrop>

      <RefreshTokenDialog />

      <Header />

      <React.Fragment>

        <Hidden smUp implementation="css">
          <TemporaryDrawer width={drawerWidth} />
        </Hidden>

        <Hidden xsDown implementation="css">
          <PermanentDrawer width={drawerWidth} />
        </Hidden>

      </React.Fragment>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Component {...rest} />
      </main>

      <Notifier />
    </Container>
  );
};

DefaultLayout.propTypes = {
  component: PropTypes.func.isRequired,
};

export default DefaultLayout;
