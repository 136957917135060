import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * List method
 * @param  {Object} payload
 * @return {Promise}
 */
const list = (payload) => {
  const { filters, pagination: { first, page } } = payload;

  const authorId = filters.author_id.length > 0 ? `{ column: AUTHOR_ID, operator: IN, value: [ ${filters.author_id} ] }` : '';
  const constructionId = filters.construction_id.length > 0 ? `{ column: CONSTRUCTION_ID, operator: IN, value: [ ${filters.construction_id} ] }` : '';
  const requestedById = filters.requested_by_id.length > 0 ? `{ column: REQUESTED_BY_ID, operator: IN, value: [ ${filters.requested_by_id} ] }` : '';
  const createdAt = filters.created_at ? `createdAt: "${filters.created_at}"` : '';

  const where = `
    where: {
      AND: [
        ${authorId}
        ${constructionId}
        ${requestedById}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query materialExits($first: Int!, $page: Int!) {
          materialExits(
            first: $first
            page: $page
            ${where}
            ${createdAt}
            orderBy: [{
              column: ID
              order: DESC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              author_id {
                full_name
              }
              construction_id {
                name
              }
              requested_by_id {
                full_name
              }
              approved_by_id {
                full_name
              }
              comments
              created_at
              has_pdf
              material_movements {
                material_id {
                  id
                  name
                  unit
                }
                quantity
              }
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create material exit method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) => client.mutate({
  mutation: gql`
    mutation createMaterialExit($input: CreateMaterialExitInput!) {
      createMaterialExit(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Create pdf method
 * @param  {String} id
 * @return {Promise}
 */
const createPdf = (id) => client.mutate({
  mutation: gql`
    mutation {
      createMaterialExitPdf(id: ${id})
    }
  `,
});

/**
 * Send created notification method
 * @param  {String} id
 * @return {Promise}
 */
const sendCreatedNotification = (id) => client.mutate({
  mutation: gql`
    mutation {
      sendMaterialExitCreatedNotification(id: ${id}) {
        sent
        error
      }
    }
  `,
});

/**
 * Get download pdf url method
 * @param  {String} id
 * @return {Promise}
 */
const getDownloadPdfUrl = (id) => client.query({
  query: gql`
    query {
      materialExitDownloadPdfUrl(id: ${id}) {
        url
      }
    }
  `,
});

export default {
  list,
  create,
  createPdf,
  sendCreatedNotification,
  getDownloadPdfUrl,
};
