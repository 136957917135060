import { uiActions } from 'store/modules/ui';
import { isSessionExpired } from 'common/utils';

const auth = ({ dispatch }) => (next) => (action) => {
  const result = next(action);
  const { type, payload } = action;

  if (
    action.type.indexOf('LOCATION_CHANGE') >= 0
    || action.type.indexOf('PERSIST') >= 0
    || action.type.indexOf('REHYDRATE') >= 0
    || action.type.indexOf('CALL_HISTORY_METHOD') >= 0
    || action.type.indexOf('auth/me') >= 0
  ) {
    return result;
  }

  // If session expired, show refresh session modal
  if (type.indexOf('Error') >= 0) {
    const message = typeof payload === 'object' && payload !== null ? payload.message : payload;

    if (isSessionExpired(message)) {
      dispatch(uiActions.toggleRefreshTokenDialog(true));
      return result;
    }

    return result;
  }

  return result;
};

export default auth;
