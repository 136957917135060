const routes = {
  login: {
    path: '/',
    title: 'Ingresar',
  },
  home: {
    path: '/inicio',
    title: 'Inicio',
  },
  request: {
    index: {
      path: '/requisiciones',
      title: 'Requisiciones',
    },
    create: {
      path: '/requisiciones/crear',
      title: 'Crear requisición',
    },
  },
  order: {
    index: {
      path: '/ordenes',
      title: 'Ordenes de compra',
    },
    create: {
      path: '/ordenes/crear',
      title: 'Crear orden de compra',
    },
  },
  material: {
    index: {
      path: '/materiales',
      title: 'Materiales',
    },
    create: {
      path: '/materiales/crear',
      title: 'Crear material',
    },
    update: {
      path: '/materiales/editar/:id',
      title: 'Editar material',
    },
  },
  materialType: {
    index: {
      path: '/materiales/tipos',
      title: 'Tipos de materiales',
    },
    create: {
      path: '/materiales/tipos/crear',
      title: 'Crear tipo de materiales',
    },
    update: {
      path: '/materiales/tipos/editar/:id',
      title: 'Editar tipo de materiales',
    },
  },
  materialEntry: {
    index: {
      path: '/materiales/entradas',
      title: 'Entradas de materiales',
    },
    create: {
      path: '/materiales/entradas/crear',
      title: 'Crear entrada de materiales',
    },
  },
  materialRefund: {
    index: {
      path: '/materiales/devoluciones',
      title: 'Devoluciones de materiales',
    },
    create: {
      path: '/materiales/devoluciones/crear',
      title: 'Crear devolución de materiales',
    },
  },
  materialExit: {
    index: {
      path: '/materiales/salidas',
      title: 'Salidas de materiales',
    },
    create: {
      path: '/materiales/salidas/crear',
      title: 'Crear salida de materiales',
    },
  },
  construction: {
    index: {
      path: '/obras',
      title: 'Obras',
    },
    create: {
      path: '/obras/crear',
      title: 'Crear obra',
    },
    update: {
      path: '/obras/editar/:id',
      title: 'Editar obra',
    },
  },
  supplier: {
    index: {
      path: '/proveedores',
      title: 'Proveedores',
    },
    create: {
      path: '/proveedores/crear',
      title: 'Crear proveedor',
    },
    update: {
      path: '/proveedores/editar/:id',
      title: 'Editar proveedor',
    },
  },
  user: {
    index: {
      path: '/usuarios',
      title: 'Usuarios',
    },
    create: {
      path: '/usuarios/crear',
      title: 'Crear usuario',
    },
    update: {
      path: '/usuarios/editar/:id',
      title: 'Editar usuario',
    },
    profile: {
      path: '/usuarios/mi-perfil',
      title: 'Mi Perfil',
    },
  },
  report: {
    inventory: {
      path: '/reporte/inventario',
      title: 'Inventario',
    },
    entry: {
      path: '/reporte/entradas',
      title: 'Entradas',
    },
    exit: {
      path: '/reporte/salidas',
      title: 'Salidas',
    },
    refund: {
      path: '/reporte/devoluciones',
      title: 'Devoluciones',
    },
  },
};

export default routes;
