import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import LocalizationProvider from '@material-ui/pickers/LocalizationProvider';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import esLocale from 'date-fns/locale/es';
import { store, persistor } from './store';
import Router from './router';
import 'styles/base.css';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <Backdrop open className={classes.backdrop}>
      <CircularProgress size={34} thickness={5} color="secondary" />
    </Backdrop>
  );
};

const App = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <React.Fragment>
        <CssBaseline />
        <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
          <Router />
        </LocalizationProvider>
      </React.Fragment>
    </PersistGate>
  </Provider>
);

export default App;
