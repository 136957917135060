import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { uiActions } from 'store/modules/ui';
import { authActions } from 'store/modules/auth';
import routes from 'common/routes';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  userIcon: {
    '& > :first-child': {
      fontSize: '1.875rem',
    },
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));

  const onDrawerToggle = () => {
    dispatch(uiActions.toggleDrawer());
  };

  const onMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    setAnchorEl(null);
    dispatch(authActions.logout());
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Grid container>
          <Grid item xs={1} className={classes.menuButton}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs={11} sm={12}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button
                  classes={{
                    iconSizeMedium: classes.userIcon,
                  }}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={onMenuToggle}
                  color="inherit"
                  endIcon={<AccountCircle />}
                >
                  {`${user.first_name} ${user.last_name}`}
                </Button>
                <Menu
                  id="user-menu"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={onClose}
                >
                  <MenuItem
                    component={Link}
                    to={routes.user.profile.path}
                  >
                    Mi Perfil
                  </MenuItem>
                  <MenuItem onClick={onLogout}>Salir</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
