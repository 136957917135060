import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  materialsTableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  commentsAndNotesContainer: {
    marginTop: theme.spacing(5),
  },
  comments: {
    marginTop: theme.spacing(2),
  },
}));

const TableDetail = (props) => {
  const classes = useStyles(props);

  const { data: { material_transactions = [], comments = '', notes = '' } } = props;

  return (
    <div className={classes.detailPanel}>
      <React.Fragment>
        <Typography component="h3" className={classes.title}>Materiales</Typography>
        <Divider />
        <div className={classes.materialsTableContainer}>
          <Table aria-label="material-table">
            <TableHead>
              <TableRow>
                <TableCell>Material</TableCell>
                <TableCell>Unidad</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Vr. Unit</TableCell>
                <TableCell>Vr. Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {material_transactions.map((row) => (
                <React.Fragment>
                  {row.material_id && (
                    <TableRow key={`${row.material_id.id}-${Math.random(0, 10)}`}>
                      <TableCell component="th" scope="row">
                        {row.material_id.name}
                      </TableCell>
                      <TableCell>{row.material_id.unit}</TableCell>
                      <TableCell>{row.quantity}</TableCell>
                      <TableCell>{numeral(row.value).format('$0,0')}</TableCell>
                      <TableCell>{numeral(row.total_value).format('$0,0')}</TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
        {(comments || notes) && (
          <Grid container spacing={2} className={classes.commentsAndNotesContainer}>
            {comments && (
              <Grid item xs={12} lg={notes ? 6 : 12}>
                <React.Fragment>
                  <Typography component="h3" className={classes.title}>Comentarios</Typography>
                  <Divider />
                  <div className={classes.comments}>{comments}</div>
                </React.Fragment>
              </Grid>
            )}
            {notes && (
              <Grid item xs={12} lg={comments ? 6 : 12}>
                <React.Fragment>
                  <Typography component="h3" className={classes.title}>Notas</Typography>
                  <Divider />
                  <div className={classes.comments}>{notes}</div>
                </React.Fragment>
              </Grid>
            )}
          </Grid>
        )}
      </React.Fragment>
    </div>
  );
};

TableDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TableDetail;
