import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import _get from 'lodash/get';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import PasswordFieldAdapter from 'components/shared/PasswordFieldAdapter';
import SwitchFieldAdapter from 'components/shared/SwitchFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { userActions } from 'store/modules/user';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import { getFieldsToUpdate } from 'common/utils';
import { validateUpdateUser, validateUpdatePassword } from 'common/formValidations';
import TabPanel from './TabPanel';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
  },
  noBorder: {
    borderBottom: 0,
  },
}));

const a11yProps = (index) => ({
  id: `user-profile-tab-${index}`,
  'aria-controls': `user-profile-tabpanel-${index}`,
});

const UserProfile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [initialValues, setInitialValues] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    user,
    loading,
  } = useSelector((state) => ({
    user: state.auth.user,
    loading: state.user.loading.list,
  }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.user.profile.title));
  }, [dispatch]);

  useEffect(() => {
    setInitialValues({
      ...user,
      settings: JSON.parse(user.settings),
    });
  }, [user]);

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onPasswordUpdate = (values) => {
    dispatch(userActions.updateUser({
      id: initialValues.id,
      input: { password: values.password },
    }));
  };

  const onSubmit = (values) => {
    let input = getFieldsToUpdate(values, initialValues);

    if ('username' in input) {
      input = {
        ...input,
        username: values.username.toLowerCase(),
      };
    }

    if ('settings' in input) {
      input = {
        ...input,
        settings: JSON.stringify(values.settings),
      };
    }

    dispatch(userActions.updateUser({ id: values.id, input }));
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  return (
    <React.Fragment>
      <SectionTitle text="Editar Mi Perfil" backButton />
      <Paper square className={classes.paper}>
        <Tabs value={activeTab} onChange={onTabChange} aria-label="user update tabs">
          <Tab label="Información basica" {...a11yProps(0)} />
          {user.is_admin && (
            <Tab label="Notificaciones" {...a11yProps(1)} />
          )}
          <Tab label="Contraseña" {...a11yProps(2)} />
        </Tabs>
        <Form
          initialValues={initialValues}
          validate={validateUpdateUser}
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            values,
            invalid,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <TabPanel value={activeTab} index={0}>
                <DialogLoadingIndicator open={loading} />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      id="username-update"
                      name="username"
                      component={TextFieldAdapter}
                      label="Nombre de usuario"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      id="email-update"
                      name="email"
                      component={TextFieldAdapter}
                      label="Email"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      id="first_name-update"
                      name="first_name"
                      component={TextFieldAdapter}
                      label="Nombre"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      id="last_name-update"
                      name="last_name"
                      component={TextFieldAdapter}
                      label="Apellido"
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Button
                      className={classes.cancel}
                      type="button"
                      color="secondary"
                      component={RouterLink}
                      to={routes.home.path}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.submit}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={invalid || pristine || loading}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              {user.is_admin && (
                <TabPanel value={activeTab} index={1}>
                  <DialogLoadingIndicator open={loading} />
                  <Table aria-label="notificacions-table">
                    <TableBody>
                      <TableRow>
                        <TableCell />
                        <TableCell align="center">Creada</TableCell>
                        <TableCell align="center">Actualizada</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Requisición de Materiales</TableCell>
                        <TableCell align="center">
                          <Field
                            id="request-created-notification-update"
                            name="settings.notifications.request.created"
                            checked={_get(values, 'settings.notifications.request.created', false)}
                            component={SwitchFieldAdapter}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Field
                            id="request-updated-notification-update"
                            name="settings.notifications.request.updated"
                            checked={_get(values, 'settings.notifications.request.updated', false)}
                            component={SwitchFieldAdapter}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Orden de Compra</TableCell>
                        <TableCell align="center">
                          <Field
                            id="order-created-notification-update"
                            name="settings.notifications.order.created"
                            checked={_get(values, 'settings.notifications.order.created', false)}
                            component={SwitchFieldAdapter}
                          />
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell>Entrada de Materiales</TableCell>
                        <TableCell align="center">
                          <Field
                            id="entry-created-notification-update"
                            name="settings.notifications.entry.created"
                            checked={_get(values, 'settings.notifications.entry.created', false)}
                            component={SwitchFieldAdapter}
                          />
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.noBorder}>Salida de Materiales</TableCell>
                        <TableCell align="center" className={classes.noBorder}>
                          <Field
                            id="exit-created-notification-update"
                            name="settings.notifications.exit.created"
                            checked={_get(values, 'settings.notifications.exit.created', false)}
                            component={SwitchFieldAdapter}
                          />
                        </TableCell>
                        <TableCell className={classes.noBorder} />
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Grid container spacing={3} className={classes.buttons}>
                    <Grid item xs={6}>
                      <Button
                        className={classes.cancel}
                        type="button"
                        color="secondary"
                        component={RouterLink}
                        to={routes.user.index.path}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.submit}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={invalid || pristine || loading}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              )}
            </form>
          )}
        />
        <Form
          initialValues={{
            password: '',
            password_confirm: '',
          }}
          validate={validateUpdatePassword}
          onSubmit={onPasswordUpdate}
          render={({
            handleSubmit,
            invalid,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <TabPanel value={activeTab} index={user.is_admin ? 2 : 1}>
                <DialogLoadingIndicator open={loading} />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      id="password-update"
                      name="password"
                      component={PasswordFieldAdapter}
                      label="Contraseña"
                      showPassword={showPassword}
                      onClickShowPassword={onClickShowPassword}
                      onMouseDownPassword={onMouseDownPassword}
                      autoComplete="new-password"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      id="password_confirm-update"
                      name="password_confirm"
                      component={PasswordFieldAdapter}
                      label="Confirmar contraseña"
                      showPassword={showConfirmPassword}
                      onClickShowPassword={onClickShowConfirmPassword}
                      onMouseDownPassword={onMouseDownPassword}
                      autoComplete="new-password"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={classes.cancel}
                      type="button"
                      color="secondary"
                      component={RouterLink}
                      to={routes.user.index.path}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.submit}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={invalid || pristine || loading}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            </form>
          )}
        />
      </Paper>
    </React.Fragment>
  );
};

export default UserProfile;
