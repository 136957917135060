import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import NavigationMenu from './NavigationMenu';

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: (props) => props.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: theme.mixins.toolbar,
}));

const PermanentDrawer = (props) => {
  const classes = useStyles(props);

  const { miniDrawer } = useSelector((state) => ({
    miniDrawer: state.ui.miniDrawer,
  }));

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: !miniDrawer,
        [classes.drawerClose]: miniDrawer,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: !miniDrawer,
          [classes.drawerClose]: miniDrawer,
        }),
      }}
    >
      <div className={classes.toolbar} />
      <NavigationMenu />
    </Drawer>
  );
};

export default PermanentDrawer;
