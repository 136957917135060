import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    minHeight: '100%',
  },
}));

const NotFoundLayout = ({ component: Component, ...rest }) => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} disableGutters className={classes.root}>
      <Component {...rest} />
    </Container>
  );
};

NotFoundLayout.propTypes = {
  component: PropTypes.func.isRequired,
};

export default NotFoundLayout;
