import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { materialEntryActions } from 'store/modules/materialEntry';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import MaterialEntryFilters from './Filters';
import MaterialEntryTable from './Table';

const MaterialEntry = () => {
  const dispatch = useDispatch();

  const { filters, pagination } = useSelector((state) => ({
    filters: state.materialEntry.filters,
    pagination: state.materialEntry.pagination,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.materialEntry.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.materialEntry.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(materialEntryActions.getMaterialEntries({ filters, pagination }));
  }, [filters, pagination, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Entradas de Materiales" buttonProps={buttonProps} />
      <MaterialEntryFilters isOpen />
      <MaterialEntryTable />
    </React.Fragment>
  );
};

export default MaterialEntry;
