import React from 'react';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const DatePickerFieldAdapter = ({ input, ...rest }) => (
  <DatePicker
    inputFormat="dd/MM/yyyy"
    defaultHighlight={new Date()}
    value={input.value || null}
    onChange={(date) => input.onChange(date)}
    renderInput={(props) => <TextField fullWidth {...props} />}
    {...rest}
  />
);

DatePickerFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
};

export default DatePickerFieldAdapter;
