import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * List method
 * @param  {Object} payload
 * @return {Promise}
 */
const list = (payload) => {
  const { filters, pagination: { first, page } } = payload;
  const authorId = filters.author_id.length > 0 ? `{ column: AUTHOR_ID, operator: IN, value: [ ${filters.author_id} ] }` : '';
  const purchaseOrderId = filters.purchase_order_id.length > 0 ? `{ column: PURCHASE_ORDER_ID, operator: IN, value: [ ${filters.purchase_order_id} ] }` : '';
  const createdAt = filters.created_at ? `createdAt: "${filters.created_at}"` : '';

  const where = `
    where: {
      AND: [
        ${authorId}
        ${purchaseOrderId}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query materialEntries($first: Int!, $page: Int!) {
          materialEntries(
            first: $first
            page: $page
            ${where}
            ${createdAt}
            orderBy: [{
              column: ID
              order: DESC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              author_id {
                full_name
              }
              purchase_order_id {
                id
                supplier_id {
                  name
                }
              }
              comments
              created_at
              has_pdf
              material_movements {
                material_id {
                  id
                  name
                  unit
                }
                quantity
                value
                iva
                total_value
              }
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create material entry method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) => client.mutate({
  mutation: gql`
    mutation createMaterialEntry($input: CreateMaterialEntryInput!) {
      createMaterialEntry(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Create pdf method
 * @param  {String} id
 * @return {Promise}
 */
const createPdf = (id) => client.mutate({
  mutation: gql`
    mutation {
      createMaterialEntryPdf(id: ${id})
    }
  `,
});

/**
 * Send created notification method
 * @param  {String} id
 * @return {Promise}
 */
const sendCreatedNotification = (id) => client.mutate({
  mutation: gql`
    mutation {
      sendMaterialEntryCreatedNotification(id: ${id}) {
        sent
        error
      }
    }
  `,
});

/**
 * Get download pdf url method
 * @param  {String} id
 * @return {Promise}
 */
const getDownloadPdfUrl = (id) => client.query({
  query: gql`
    query {
      materialEntryDownloadPdfUrl(id: ${id}) {
        url
      }
    }
  `,
});

export default {
  list,
  create,
  createPdf,
  sendCreatedNotification,
  getDownloadPdfUrl,
};
