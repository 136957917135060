import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import auth from './auth';
import user from './user';
import request from './request';
import order from './order';
import construction from './construction';
import material from './material';
import materialType from './materialType';
import materialEntry from './materialEntry';
import materialRefund from './materialRefund';
import materialExit from './materialExit';
import supplier from './supplier';
import transaction from './transaction';
import movement from './movement';
import report from './report';
import ui from './ui';

const uiPersistConfig = {
  key: 'ui',
  storage: localforage,
  blacklist: [
    'notifications',
    'refreshTokenDialog',
    'importMaterialsDialog',
  ],
};

const createRootReducer = () => combineReducers({
  auth,
  user,
  request,
  order,
  construction,
  material,
  materialType,
  materialEntry,
  materialRefund,
  materialExit,
  supplier,
  transaction,
  movement,
  report,
  ui: persistReducer(uiPersistConfig, ui),
});

export default createRootReducer;
