import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { materialTypeActions } from 'store/modules/materialType';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import { validateMaterialType } from 'common/formValidations';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
}));

const initialValues = {
  name: '',
};

const CreateMaterialType = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { loading } = useSelector((state) => ({ loading: state.materialType.loading.list }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.materialType.create.title));
  }, [dispatch]);

  const resetAll = (args) => {
    const form = args[0];

    form.restart();
  };

  const onSubmit = (values) => {
    dispatch(materialTypeActions.createMaterialType(values));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Crear Tipo de Material" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validateMaterialType}
          onSubmit={onSubmit}
          mutators={{
            resetAll,
          }}
          render={({
            handleSubmit,
            invalid,
            pristine,
            form,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name="name"
                    component={TextFieldAdapter}
                    label="Nombre"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.mutators.resetAll(form);
                    }}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={8} sm={6} className={classes.submit}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.materialType.index.path}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || pristine || loading}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
    </React.Fragment>
  );
};

export default CreateMaterialType;
