import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Switch } from 'react-router-dom';
import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { esES } from '@material-ui/core/locale';
import lightBlue from '@material-ui/core/colors/lightBlue';
import indigo from '@material-ui/core/colors/indigo';
import { SnackbarProvider } from 'notistack';
import { mainSecondaryColor } from 'common/constants';
import { publicRoutes, privateRoutes } from './Routes';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const useStyles = makeStyles({
  snackbarContainer: {
    '& > div': {
      flexWrap: 'nowrap',
    },
  },
});

const SlideTransition = (props) => <Slide {...props} direction="up" />;

const Router = () => {
  const classes = useStyles();

  const { darkMode, pageTitle } = useSelector((state) => ({
    darkMode: state.ui.darkMode,
    pageTitle: state.ui.pageTitle,
  }));

  const theme = React.useMemo(
    () => createTheme({
      palette: {
        primary: darkMode ? lightBlue : indigo,
        secondary: {
          main: mainSecondaryColor,
        },
        type: darkMode ? 'dark' : 'light',
      },
      overrides: {
        MuiDropzoneArea: {
          root: {
            minHeight: 163,
          },
        },
        MuiDropzonePreviewList: {
          root: {
            marginTop: 16,
          },
        },
      },
    }, esES),
    [darkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SnackbarProvider
          classes={{
            root: classes.snackbarContainer,
          }}
          TransitionComponent={SlideTransition}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Helmet>
            <title>
              MAPMS
              {' '}
              |
              {' '}
              {pageTitle}
            </title>
          </Helmet>
          <Switch>
            {privateRoutes.map(({
              key, exact, path, component, layout, allowedRoles,
            }) => (
              <PrivateRoute
                key={key}
                exact={exact}
                path={path}
                component={component}
                layout={layout}
                allowedRoles={allowedRoles}
              />
            ))}

            {publicRoutes.map(({
              key, exact, path, component, layout, allowedRoles,
            }) => (
              <PublicRoute
                key={key}
                exact={exact}
                path={path}
                component={component}
                layout={layout}
                allowedRoles={allowedRoles}
              />
            ))}
          </Switch>
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Router;
