import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Filter options
const getFilterOptions = createAction('supplier/getFilterOptions');
const getFilterOptionsSuccess = createAction('supplier/getFilterOptionsSuccess');
const getFilterOptionsError = createAction('supplier/getFilterOptionsError');

// Update pagination
const updatePagination = createAction('supplier/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('supplier/updatePaginatorInfo');

// Update filters
const updateFilters = createAction('supplier/updateFilters');

// Get suppliers
const getSuppliers = createAction('supplier/getSuppliers');
const getSuppliersSuccess = createAction('supplier/getSuppliersSuccess');
const getSuppliersError = createAction('supplier/getSuppliersError');

// Create supplier
const createSupplier = createAction('supplier/createSupplier');
const createSupplierSuccess = createAction('supplier/createSupplierSuccess');
const createSupplierError = createAction('supplier/createSupplierError');

// Delete suppliers
const deleteSuppliers = createAction('supplier/deleteSuppliers');
const deleteSuppliersSuccess = createAction('supplier/deleteSuppliersSuccess');
const deleteSuppliersError = createAction('supplier/deleteSuppliersError');

// Update supplier
const updateSupplier = createAction('supplier/updateSupplier');
const updateSupplierSuccess = createAction('supplier/updateSupplierSuccess');
const updateSupplierError = createAction('supplier/updateSupplierError');

export const supplierActions = {
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsError,
  updatePagination,
  updatePaginatorInfo,
  updateFilters,
  getSuppliers,
  getSuppliersSuccess,
  getSuppliersError,
  createSupplier,
  createSupplierSuccess,
  createSupplierError,
  deleteSuppliers,
  deleteSuppliersSuccess,
  deleteSuppliersError,
  updateSupplier,
  updateSupplierSuccess,
  updateSupplierError,
};

// Initial state
const initialState = {
  suppliers: [],
  options: [],
  filters: {
    id: [],
    nit: null,
    manager: null,
    city: null,
    email: null,
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    list: false,
    filter: false,
  },
  error: null,
  refresh: false,
};

// Reducer
export default createReducer(initialState, {
  // Get filter options reducer
  [getFilterOptions]: (state) => {
    state.loading.filter = true;
    state.error = null;
  },
  [getFilterOptionsSuccess]: (state, action) => {
    state.options = action.payload;
    state.loading.filter = false;
  },
  [getFilterOptionsError]: (state, action) => {
    state.loading.filter = false;
    state.error = action.payload;
  },
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Get suppliers reducer
  [getSuppliers]: (state) => {
    state.loading.list = true;
    state.error = null;
    state.refresh = false;
  },
  [getSuppliersSuccess]: (state, action) => {
    state.suppliers = action.payload;
    state.loading.list = false;
  },
  [getSuppliersError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create supplier reducer
  [createSupplier]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createSupplierSuccess]: (state) => {
    state.loading.list = false;
  },
  [createSupplierError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Delete suppliers reducer
  [deleteSuppliers]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [deleteSuppliersSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [deleteSuppliersError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Update supplier reducer
  [updateSupplier]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [updateSupplierSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [updateSupplierError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
