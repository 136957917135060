import _isEmpty from 'lodash/isEmpty';
import _isDate from 'lodash/isDate';
import _isInteger from 'lodash/isInteger';

export const hasValue = (value) => !_isEmpty(value);

export const email = (value) => {
  if (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return true;
  }

  return false;
};

export const date = (value) => _isDate(value);

export const isFloat = (value) => {
  if (typeof value === 'number') {
    return Number(value) === value && value % 1 !== 0;
  }

  return false;
};

export const positiveFloat = (value) => {
  const convertedValue = Number(value);

  if (typeof convertedValue === 'number') {
    const formatedValue = parseFloat(convertedValue);

    return isFloat(formatedValue) && formatedValue >= 0;
  }

  return false;
};

export const positiveInt = (value) => {
  const convertedValue = Number(value);

  if (typeof convertedValue === 'number') {
    const formatedValue = parseInt(convertedValue, 10);

    return _isInteger(formatedValue) && formatedValue >= 0;
  }

  return false;
};

export const positiveNumber = (value) => {
  const convertedValue = Number(value);

  if (typeof convertedValue === 'number') {
    return positiveInt(convertedValue) || positiveFloat(convertedValue);
  }

  return false;
};

export const alphaNumeric = (value) => {
  if (value && /^[a-zA-Z0-9]+$/i.test(value)) {
    return true;
  }

  return false;
};

export const minLength = (value, min) => {
  if (value && value.length >= min) {
    return true;
  }

  return false;
};

export const alphaDash = (value) => {
  if (value && /^[a-zA-Z0-9-_]+$/i.test(value)) {
    return true;
  }

  return false;
};

export const alphaSpace = (value) => {
  if (value && /^[A-Za-z]+[A-Za-z\s]*$/i.test(value)) {
    return true;
  }

  return false;
};

export const password = (value) => {
  if (value && /^(?=.*[0-9]+.*)(?=(.*[A-Z]){1,})(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,}$/.test(value)) {
    return true;
  }

  return false;
};
