import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { uiActions } from 'store/modules/ui';
import NavigationMenu from './NavigationMenu';

const useStyles = makeStyles({
  drawerPaper: {
    width: (props) => props.width,
  },
});

const TemporaryDrawer = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);

  const { drawer } = useSelector((state) => ({
    drawer: state.ui.drawer,
  }));

  const onDrawerToggle = () => {
    dispatch(uiActions.toggleDrawer());
  };

  return (
    <Drawer
      variant="temporary"
      open={drawer}
      onClose={onDrawerToggle}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <NavigationMenu mobile />
    </Drawer>
  );
};

export default TemporaryDrawer;
