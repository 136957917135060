import { all } from 'redux-saga/effects';
import watchAuth from './authSaga';
import watchUser from './userSaga';
import watchRequest from './requestSaga';
import watchOrder from './orderSaga';
import watchConstruction from './constructionSaga';
import watchMaterial from './materialSaga';
import watchMaterialType from './materialTypeSaga';
import watchSupplier from './supplierSaga';
import watchTransaction from './transactionSaga';
import watchMaterialEntry from './materialEntrySaga';
import watchMaterialRefund from './materialRefundSaga';
import watchMaterialExit from './materialExitSaga';
import watchMovement from './movementSaga';
import watchReport from './reportSaga';

/**
 * Root saga
 *
 * @export
 */
export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchUser(),
    watchRequest(),
    watchOrder(),
    watchConstruction(),
    watchMaterial(),
    watchMaterialType(),
    watchSupplier(),
    watchTransaction(),
    watchMaterialEntry(),
    watchMaterialRefund(),
    watchMaterialExit(),
    watchMovement(),
    watchReport(),
  ]);
}
