import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { DropzoneArea } from 'material-ui-dropzone';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { materialTypeActions } from 'store/modules/materialType';
import routes from 'common/routes';
import { validateMaterial } from 'common/formValidations';
import ImportAlert from './ImportAlert';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  dropzoneText: {
    fontSize: '1rem',
  },
  import: {
    marginTop: theme.spacing(2),
  },
}));

const initialValues = {
  name: '',
  material_type_id: '',
  unit: '',
  quantity: 0,
  value: 0,
  discount: 0,
  iva: 0,
};

const CreateMaterial = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [canImport, setCanImport] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    dispatch(materialTypeActions.getFilterOptions());
  }, [dispatch]);

  const {
    materialTypeOptions,
    loading,
    error,
  } = useSelector((state) => ({
    materialTypeOptions: state.materialType.options,
    loading: state.material.loading.list,
    error: state.material.error,
  }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.material.create.title));
  }, [dispatch]);

  const resetAll = (args) => {
    const form = args[0];

    form.restart();
  };

  const onChange = (files) => {
    if (files.length > 0) {
      setFile(files[0]);
      setCanImport(true);
    }
  };

  const onFileDelete = () => {
    setFile(null);
    setCanImport(false);
  };

  const onSubmit = (values) => {
    dispatch(materialActions.createMaterial(values));
  };

  const onImport = () => {
    dispatch(materialActions.importMaterials(file));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Crear Material" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validateMaterial}
          onSubmit={onSubmit}
          mutators={{
            resetAll,
          }}
          render={({
            handleSubmit,
            invalid,
            pristine,
            form,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <Field
                    name="name"
                    component={TextFieldAdapter}
                    label="Nombre"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="material_type_id"
                    name="material_type_id"
                    component={SelectFieldAdapter}
                    options={materialTypeOptions}
                    label="Tipo"
                    keyType="number"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="unit"
                    name="unit"
                    component={TextFieldAdapter}
                    label="Unidad"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="quantity"
                    name="quantity"
                    component={TextFieldAdapter}
                    label="Cantidad"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="discount"
                    name="discount"
                    component={TextFieldAdapter}
                    label="Descuento"
                    type="number"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="iva"
                    name="iva"
                    component={TextFieldAdapter}
                    label="IVA"
                    type="number"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.buttonsWrapper}>
                <Grid item xs={4} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.mutators.resetAll(form);
                    }}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={8} sm={6} className={classes.submit}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.material.index.path}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || pristine || loading}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
      <Paper square className={classes.paper}>
        {error && <ImportAlert errors={error} />}
        <DropzoneArea
          acceptedFiles={['.xls, .xlsx']}
          filesLimit={1}
          Icon={AttachFileIcon}
          dropzoneText="Arrastra y suelta una hoja de calculo aquí o haz clic"
          showPreviews
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
          previewText=""
          showAlerts={false}
          dropzoneParagraphClass={classes.dropzoneText}
          onChange={onChange}
          onDelete={onFileDelete}
        />
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.import}
          disabled={!canImport || loading}
          onClick={onImport}
        >
          Importar
        </Button>
      </Paper>
    </React.Fragment>
  );
};

export default CreateMaterial;
