import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import CheckboxGroupFieldAdapter from 'components/shared/CheckboxGroupFieldAdapter';
import { materialTypeActions } from 'store/modules/materialType';
import { reportType } from 'common/constants';
import { reportActions } from 'store/modules/report';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  accordionRoot: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  diviver: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
  },
}));

const InventoryForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    actionLoading,
    authUser,
    materialTypesOptions,
  } = useSelector((state) => ({
    actionLoading: state.report.loading.action,
    authUser: state.auth.user,
    materialTypesOptions: state.materialType.options,
  }));

  useEffect(() => {
    dispatch(materialTypeActions.getFilterOptions());
  }, [dispatch]);

  const onSubmit = (values) => {
    const createData = {
      author_id: Number(authUser.id),
      type: reportType.inventory,
    };

    dispatch(reportActions.createReport({ createData, fileData: values }));
  };

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Typography className={classes.title} variant="h5">Reporte de inventario de materiales</Typography>
          <Typography className={classes.description} variant="body2">
            Este reporte genera una lista completa de los materiales
            presentes en la base de datos, junto con su tipo, unidad,
            cantidad actual, valor promedio y valor total.
          </Typography>
          <Divider className={classes.diviver} />
          <Typography className={classes.subtitle} variant="h6">Filtros</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Accordion
                variant="outlined"
                square
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Tipos de materiales</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionRoot }}>
                  <FieldArray
                    name="material_types_id"
                    component={CheckboxGroupFieldAdapter}
                    options={materialTypesOptions}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              size="small"
              color="primary"
              variant="contained"
              disabled={actionLoading}
            >
              Generar Reporte
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default InventoryForm;
