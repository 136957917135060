import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const TabPanel = (props) => {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-update-tabpanel-${index}`}
      aria-labelledby={`user-update-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3} pb={2}>{children}</Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
