import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';

const PasswordFieldAdapter = ({
  input, meta, id, label, showPassword, onClickShowPassword, onMouseDownPassword, ...rest
}) => (
  <FormControl
    {...rest}
    error={meta.touched ? meta.invalid : false}
  >
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Input
      {...input}
      {...rest}
      id={id}
      type={showPassword ? 'text' : 'password'}
      onChange={(event) => input.onChange(event.target.value)}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={onClickShowPassword}
            onMouseDown={onMouseDownPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )}
    />
    {(meta.touched && meta.invalid) && <FormHelperText>{meta.invalid ? meta.error : ''}</FormHelperText>}
  </FormControl>
);

PasswordFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showPassword: PropTypes.bool.isRequired,
  onClickShowPassword: PropTypes.func.isRequired,
  onMouseDownPassword: PropTypes.func.isRequired,
};

export default PasswordFieldAdapter;
