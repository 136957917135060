import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Field } from 'react-final-form';
import _isNull from 'lodash/isNull';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import PasswordFieldAdapter from 'components/shared/PasswordFieldAdapter';
import Logo from 'components/shared/Logo';
import ErrorBanner from 'components/shared/ErrorBanner';
import { hasValue, email } from 'common/validations';
import { authActions } from 'store/modules/auth';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100%',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  form: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 300,
    },
  },
  gridContainer: {
    marginTop: theme.spacing(3),
  },
  email: {
    marginBottom: 15,
  },
  buttonWrapper: {
    marginTop: 25,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const initialValues = {
  username: '',
  password: '',
};

const Login = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { isLoggedIn, error, loading } = useSelector((state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error,
    loading: state.auth.loading,
  }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.login.title));
  }, [dispatch]);

  const [showPassword, setShowPassword] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!email(values.username)) {
      errors.username = 'Debes ingresar un email valido';
    }

    if (!hasValue(values.password)) {
      errors.password = 'La contraseña no puede estar vacía';
    }

    return errors;
  };

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (values) => {
    dispatch(authActions.login(values));
  };

  if (isLoggedIn) {
    return <Redirect to={routes.request.index.path} />;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.container}>
      <Grid item xs={10} className={classes.form}>
        <div className={classes.logo}>
          <Logo size={50} />
        </div>
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              {error && (
                <ErrorBanner show={!_isNull(error)} message={error} />
              )}
              <Grid container direction="column" className={classes.gridContainer}>
                <Grid item xs={12} className={classes.email}>
                  <Field
                    id="login-username"
                    name="username"
                    component={TextFieldAdapter}
                    label="Email"
                    type="email"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    id="login-password"
                    name="password"
                    component={PasswordFieldAdapter}
                    label="Contraseña"
                    showPassword={showPassword}
                    onClickShowPassword={onClickShowPassword}
                    onMouseDownPassword={onMouseDownPassword}
                    autoComplete="current-password"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || loading}
                    fullWidth
                  >
                    Ingresar
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      thickness={5}
                      color="secondary"
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Login;
