import React from 'react';
import PropTypes from 'prop-types';

const LoginLayout = ({ component: Component, ...rest }) => (
  <React.Fragment>
    <Component {...rest} />
  </React.Fragment>
);

LoginLayout.propTypes = {
  component: PropTypes.func.isRequired,
};

export default LoginLayout;
