import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { orderActions } from 'store/modules/order';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import OrderFilters from './Filters';
import OrdersTable from './Table';

const Order = () => {
  const dispatch = useDispatch();

  const { filters, pagination, refresh } = useSelector((state) => ({
    filters: state.order.filters,
    pagination: state.order.pagination,
    refresh: state.order.refresh,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.order.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.order.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(orderActions.getOrders({ filters, pagination }));
  }, [filters, pagination, refresh, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Ordenes de Compra" buttonProps={buttonProps} />
      <OrderFilters isOpen />
      <OrdersTable />
    </React.Fragment>
  );
};

export default Order;
