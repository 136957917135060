import {
  hasValue,
  positiveInt,
  minLength,
  alphaDash,
  email,
  alphaNumeric,
  alphaSpace,
  password,
  date,
} from 'common/validations';

export const validateMaterial = (values) => {
  const errors = {};

  if (!hasValue(values.name)) {
    errors.name = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.name, 2)) {
    errors.name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (hasValue(values.material_type_id) && !positiveInt(values.material_type_id)) {
    errors.material_type_id = 'Este campo no puede estar vacío';
  }

  if (!hasValue(values.unit)) {
    errors.unit = 'Este campo no puede estar vacío';
  }

  if (hasValue(values.quantity) && !positiveInt(values.quantity)) {
    errors.quantity = 'Este campo solo admite enteros positivos o cero';
  }

  if (!minLength(values.unit, 2)) {
    errors.unit = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (hasValue(values.value) && !positiveInt(values.value)) {
    errors.value = 'Este campo solo admite enteros positivos o cero';
  }

  if (hasValue(values.discount) && !positiveInt(values.discount)) {
    errors.discount = 'Este campo solo admite enteros positivos o cero';
  }

  if (hasValue(values.iva) && !positiveInt(values.iva)) {
    errors.iva = 'Este campo solo admite enteros positivos o cero';
  }

  return errors;
};

export const validateMaterialType = (values) => {
  const errors = {};

  if (!hasValue(values.name)) {
    errors.name = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.name, 2)) {
    errors.name = 'Este campo debe tener como mínimo dos caracteres';
  }

  return errors;
};

export const validateConstruction = (values) => {
  const errors = {};

  if (!hasValue(values.name)) {
    errors.name = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.name, 2)) {
    errors.name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.work_order)) {
    errors.work_order = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.work_order, 2)) {
    errors.work_order = 'Este campo debe tener como mínimo dos caracteres';
  }

  return errors;
};

export const validateSupplier = (values) => {
  const errors = {};

  if (!hasValue(values.nit)) {
    errors.nit = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.nit, 2)) {
    errors.nit = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.name)) {
    errors.name = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.name, 2)) {
    errors.name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.manager)) {
    errors.manager = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.manager, 2)) {
    errors.manager = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.address)) {
    errors.address = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.address, 2)) {
    errors.address = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.city)) {
    errors.city = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.city, 2)) {
    errors.city = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.email)) {
    errors.email = 'Este campo no puede estar vacío';
  }

  if (!email(values.email)) {
    errors.email = 'Este campo debe ser un email valido';
  }

  if (!hasValue(values.phone)) {
    errors.phone = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.phone, 2)) {
    errors.phone = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!alphaDash(values.phone)) {
    errors.phone = 'Este campo solo admite letras, números y guiones';
  }

  if (hasValue(values.fax) && !minLength(values.fax, 2)) {
    errors.fax = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (hasValue(values.fax) && !alphaDash(values.fax)) {
    errors.fax = 'Este campo solo admite letras, números y guiones';
  }

  return errors;
};

export const validateUser = (values) => {
  const errors = {};

  if (!hasValue(values.username)) {
    errors.username = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.username, 2)) {
    errors.username = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!alphaNumeric(values.username)) {
    errors.username = 'Este campo solo admite letras y números';
  }

  if (!hasValue(values.email)) {
    errors.email = 'Este campo no puede estar vacío';
  }

  if (!email(values.email)) {
    errors.email = 'Este campo debe ser un email valido';
  }

  if (!hasValue(values.first_name)) {
    errors.first_name = 'Este campo no puede estar vacío';
  }

  if (!alphaSpace(values.first_name)) {
    errors.first_name = 'Este campo solo admite letras y espacios';
  }

  if (!minLength(values.first_name, 2)) {
    errors.first_name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.last_name)) {
    errors.last_name = 'Este campo no puede estar vacío';
  }

  if (!alphaSpace(values.last_name)) {
    errors.last_name = 'Este campo solo admite letras y espacios';
  }

  if (!minLength(values.last_name, 2)) {
    errors.last_name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.password)) {
    errors.password = 'Este campo no puede estar vacío';
  }

  if (!password(values.password)) {
    errors.password = 'La contraseña suministrada debe estar compuesta mínimo de ocho caracteres, entre los cuales debe haber al menos una letra minúscula, una mayúscula y un número';
  }

  return errors;
};

export const validateUpdateUser = (values) => {
  const errors = {};

  if (!hasValue(values.username)) {
    errors.username = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.username, 2)) {
    errors.username = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!alphaNumeric(values.username)) {
    errors.username = 'Este campo solo admite letras y números';
  }

  if (!hasValue(values.email)) {
    errors.email = 'Este campo no puede estar vacío';
  }

  if (!email(values.email)) {
    errors.email = 'Este campo debe ser un email valido';
  }

  if (!hasValue(values.first_name)) {
    errors.first_name = 'Este campo no puede estar vacío';
  }

  if (!alphaSpace(values.first_name)) {
    errors.first_name = 'Este campo solo admite letras y espacios';
  }

  if (!minLength(values.first_name, 2)) {
    errors.first_name = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!hasValue(values.last_name)) {
    errors.last_name = 'Este campo no puede estar vacío';
  }

  if (!alphaSpace(values.last_name)) {
    errors.last_name = 'Este campo solo admite letras y espacios';
  }

  if (!minLength(values.last_name, 2)) {
    errors.last_name = 'Este campo debe tener como mínimo dos caracteres';
  }

  return errors;
};

export const validateUpdatePassword = (values) => {
  const errors = {};

  if (!hasValue(values.password)) {
    errors.password = 'Este campo no puede estar vacío';
  }

  if (!password(values.password)) {
    errors.password = 'La contraseña suministrada debe estar compuesta mínimo de ocho caracteres, entre los cuales debe haber al menos una letra minúscula, una mayúscula y un número';
  }

  if (!hasValue(values.password_confirm)) {
    errors.password_confirm = 'Este campo no puede estar vacío';
  }

  if (!password(values.password_confirm)) {
    errors.password_confirm = 'La contraseña suministrada debe estar compuesta mínimo de ocho caracteres, entre los cuales debe haber al menos una letra minúscula, una mayúscula y un número';
  }

  if (
    hasValue(values.password)
    && hasValue(values.password_confirm)
    && values.password !== values.password_confirm
  ) {
    errors.password_confirm = 'Las contraseñas no coinciden';
  }

  return errors;
};

export const validateOrder = (values) => {
  const errors = {};

  if (!hasValue(values.supplier_id)) {
    errors.supplier_id = 'Este campo no puede estar vacío';
  }

  if (!hasValue(values.construction_id)) {
    errors.construction_id = 'Este campo no puede estar vacío';
  }

  if (!hasValue(values.approved_by_id)) {
    errors.approved_by_id = 'Este campo no puede estar vacío';
  }

  if (!hasValue(values.delivery_address)) {
    errors.delivery_address = 'Este campo no puede estar vacío';
  }

  if (!minLength(values.delivery_address, 2)) {
    errors.delivery_address = 'Este campo debe tener como mínimo dos caracteres';
  }

  if (!date(values.delivery_date)) {
    errors.delivery_date = 'Este campo no puede estar vació';
  }

  if (!hasValue(values.materials)) {
    errors.materials = 'Este campo no puede estar vació';
  }

  if (!hasValue(values.material_requests_id)) {
    errors.material_requests_id = 'Este campo no puede estar vació';
  }

  if (values.iva < 0) {
    errors.iva = 'El valor de este campo debe ser igual o mayor a cero';
  }

  if (values.retf < 0) {
    errors.retf = 'El valor de este campo debe ser igual o mayor a cero';
  }

  if (values.ica < 0) {
    errors.ica = 'El valor de este campo debe ser igual o mayor a cero';
  }

  return errors;
};
