import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const AutocompleteFieldAdapter = ({
  input, meta, isMultiple, filterSelectedOptions, loading, textProps, ...rest
}) => (
  <Autocomplete
    getOptionLabel={(option) => (option ? option.label : '')}
    value={input.value}
    multiple={isMultiple}
    filterSelectedOptions={filterSelectedOptions}
    filterOptions={(options) => options}
    onChange={(event, value) => input.onChange(value)}
    onFocus={(event) => input.onFocus(event)}
    onBlur={(event) => input.onBlur(event)}
    renderInput={(params) => (
      <TextField
        {...params}
        {...textProps}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {(loading && meta.active) ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
    {...rest}
  />
);

AutocompleteFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  filterSelectedOptions: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  textProps: PropTypes.object.isRequired,
};

export default AutocompleteFieldAdapter;
