import { take, takeLatest, put } from 'redux-saga/effects';
import { reportActions } from 'store/modules/report';
import { getUserFriendlyError } from 'common/utils';
import report from 'graphql/report';

/**
 * Get orders saga
 *
 * @param {*} action
 */
function* getReports(action) {
  try {
    const { payload } = action;
    const response = yield report.getReports({ type: payload.type, pagination: payload.pagination });
    const { data: { reports: { data, paginatorInfo } } } = response;

    yield put(reportActions.getReportsSuccess(data));
    yield put(reportActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(reportActions.getReportsError(message));
  }
}

/**
 * Create report saga
 *
 * @param {*} action
 */
function* createReport(action) {
  try {
    const { payload: { createData, fileData } } = action;
    const { data: { createReport: { id } } } = yield report.create(createData);

    yield put(reportActions.createPdf({ id, ...fileData }));
    yield put(reportActions.createSpreadsheet({ id, ...fileData }));
    yield take([
      reportActions.createPdfSuccess,
      reportActions.createSpreadsheetSuccess,
    ]);
    yield put(reportActions.createReportSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(reportActions.createReportError(message));
  }
}

/**
 * Create report PDF saga
 *
 * @param {*} action
 */
function* createPdf(action) {
  try {
    yield report.createPdf(action.payload);
    yield put(reportActions.createPdfSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(reportActions.createPdfError(message));
  }
}

/**
 * Create report spreadsheet saga
 *
 * @param {*} action
 */
function* createSpreadsheet(action) {
  try {
    yield report.createSpreadsheet(action.payload);
    yield put(reportActions.createSpreadsheetSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(reportActions.createSpreadsheetError(message));
  }
}

/**
 * Download file saga
 *
 * @param {*} action
 */
function* downloadFile(action) {
  const response = yield report.getDownloadFileUrl(action.payload);
  const { data: { reportDownloadFileUrl: { url: downloadUrl } } } = response;

  window.open(downloadUrl, '_blank');
}

/**
 * Watch report
 *
 * @export
 */
export default function* watchReport() {
  yield takeLatest(reportActions.getReports, getReports);
  yield takeLatest(reportActions.createReport, createReport);
  yield takeLatest(reportActions.createPdf, createPdf);
  yield takeLatest(reportActions.createSpreadsheet, createSpreadsheet);
  yield takeLatest(reportActions.downloadFile, downloadFile);
}
