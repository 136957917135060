import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Insert transactions method
 * @param  {Array} payload
 * @return {Promise}
 */
const insert = (payload) => client.mutate({
  mutation: gql`
    mutation insertMaterialTransactions($input: [InsertMaterialTransactionInput!]!) {
      insertMaterialTransactions(input: $input)
    }
  `,
  variables: { input: payload },
});

export default {
  insert,
};
