import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { constructionActions } from 'store/modules/construction';
import { uiActions } from 'store/modules/ui';
import { constructionStatusOptions } from 'common/constants';
import routes from 'common/routes';
import { validateConstruction } from 'common/formValidations';
import { getFieldsToUpdate } from 'common/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
  },
}));

const UpdateConstruction = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({});

  const {
    constructions,
    loading,
  } = useSelector((state) => ({
    constructions: state.construction.constructions,
    loading: state.construction.loading.list,
  }));

  const getValues = () => {
    if (constructions && constructions.length > 0) {
      const construction = constructions.find((element) => element.id === id);

      if (construction) {
        setInitialValues({
          id: construction.id,
          name: construction.name,
          work_order: construction.work_order,
          status: construction.status,
        });
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.construction.update.title));
  }, [dispatch]);

  useEffect(getValues, [constructions]);

  const onSubmit = (values) => {
    const input = getFieldsToUpdate(values, initialValues);
    dispatch(constructionActions.updateConstruction({ id: values.id, input }));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Editar Obra" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validateConstruction}
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            invalid,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Field
                    id="name-update"
                    name="name"
                    component={TextFieldAdapter}
                    label="Nombre"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    id="work_order-update"
                    name="work_order"
                    component={TextFieldAdapter}
                    label="OT"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    id="status-update"
                    name="status"
                    component={SelectFieldAdapter}
                    options={constructionStatusOptions}
                    label="Estado"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.construction.index.path}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.submit}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || pristine || loading}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
    </React.Fragment>
  );
};

export default UpdateConstruction;
