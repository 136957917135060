import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { materialTypeActions } from 'store/modules/materialType';
import routes from 'common/routes';
import { validateMaterial } from 'common/formValidations';
import { getFieldsToUpdate } from 'common/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
  },
}));

const UpdateMaterial = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({});

  const {
    materialTypeOptions,
    materials,
    loading,
  } = useSelector((state) => ({
    materialTypeOptions: state.materialType.options,
    materials: state.material.materials,
    loading: state.material.loading.list,
  }));

  const getValues = () => {
    if (materials && materials.length > 0) {
      const material = materials.find((element) => element.id === id);

      if (material) {
        setInitialValues({
          id: material.id,
          name: material.name,
          material_type_id: material.material_type_id.id,
          unit: material.unit,
          quantity: material.quantity,
          value: material.value,
          discount: material.discount,
          iva: material.iva,
        });
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.material.update.title));
  }, [dispatch]);

  useEffect(getValues, [materials]);

  useEffect(() => {
    dispatch(materialTypeActions.getFilterOptions());
  }, [dispatch]);

  const onSubmit = (values) => {
    const input = getFieldsToUpdate(values, initialValues);
    dispatch(materialActions.updateMaterial({ id: values.id, input }));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Editar Material" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validateMaterial}
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            invalid,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4}>
                  <Field
                    name="name"
                    component={TextFieldAdapter}
                    label="Nombre"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="material_type_id"
                    name="material_type_id"
                    component={SelectFieldAdapter}
                    options={materialTypeOptions}
                    label="Tipo"
                    keyType="number"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="unit"
                    name="unit"
                    component={TextFieldAdapter}
                    label="Unidad"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="discount"
                    name="discount"
                    component={TextFieldAdapter}
                    label="Descuento"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Field
                    id="iva"
                    name="iva"
                    component={TextFieldAdapter}
                    label="IVA"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.buttonsWrapper}>
                <Grid item xs={6}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.material.index.path}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.submit}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || pristine || loading}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
    </React.Fragment>
  );
};

export default UpdateMaterial;
