import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...input}
    {...rest}
    onChange={(event) => {
      const { target: { type, value } } = event;
      const formatedValue = type === 'number' ? Number(value) : value;
      input.onChange(formatedValue);
    }}
    error={meta.touched ? meta.invalid : false}
    helperText={(meta.touched && meta.invalid) ? meta.error : ''}
  />
);

TextFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default TextFieldAdapter;
