import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { materialTypeActions } from 'store/modules/materialType';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import { validateMaterialType } from 'common/formValidations';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
  },
}));

const UpdateMaterialType = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({});

  const {
    materialTypes,
    loading,
  } = useSelector((state) => ({
    materialTypes: state.materialType.materialTypes,
    loading: state.materialType.loading.list,
  }));

  const getValues = () => {
    if (materialTypes && materialTypes.length > 0) {
      const materialType = materialTypes.find((element) => element.id === id);

      if (materialType) {
        setInitialValues({
          id: materialType.id,
          name: materialType.name,
        });
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.materialType.update.title));
  }, [dispatch]);

  useEffect(getValues, [materialTypes]);

  const onSubmit = (values) => {
    const input = { ...values };
    delete input.id;

    dispatch(materialTypeActions.updateMaterialType({ id: values.id, input }));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Editar Tipo de Material" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validateMaterialType}
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            invalid,
            pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name="name"
                    component={TextFieldAdapter}
                    label="Nombre"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.materialType.index.path}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.submit}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || pristine || loading}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
    </React.Fragment>
  );
};

export default UpdateMaterialType;
