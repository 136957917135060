import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import { requestStatus } from 'common/constants';

// Action creators

// Get filter options
const getFilterOptions = createAction('request/getFilterOptions');
const getFilterOptionsSuccess = createAction('request/getFilterOptionsSuccess');
const getFilterOptionsError = createAction('request/getFilterOptionsError');

// Update filters
const updateFilters = createAction('request/updateFilters');

// Update pagination
const updatePagination = createAction('request/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('request/updatePaginatorInfo');

// Get requests
const getRequests = createAction('request/getRequests');
const getRequestsSuccess = createAction('request/getRequestsSuccess');
const getRequestsError = createAction('request/getRequestsError');

// Create request
const createRequest = createAction('request/createRequest');
const createRequestSuccess = createAction('request/createRequestSuccess');
const createRequestError = createAction('request/createRequestError');

// Delete requests
const deleteRequests = createAction('request/deleteRequests');
const deleteRequestsSuccess = createAction('request/deleteRequestsSuccess');
const deleteRequestsError = createAction('request/deleteRequestsError');

// Create pdf
const createPdf = createAction('request/createPdf');
const createPdfSuccess = createAction('request/createPdfSuccess');
const createPdfError = createAction('request/createPdfError');

// Send created notification
const sendCreatedNotification = createAction('request/sendCreatedNotification');
const sendCreatedNotificationSuccess = createAction('request/sendCreatedNotificationSuccess');
const sendCreatedNotificationError = createAction('request/sendCreatedNotificationError');

// Download pdf
const downloadPdf = createAction('request/downloadPdf');

// Move pdf
const movePdf = createAction('request/movePdf');
const movePdfSuccess = createAction('request/movePdfSuccess');
const movePdfError = createAction('request/movePdfError');

// Update request
const updateRequestComments = createAction('request/updateRequestComments');
const updateRequestCommentsSuccess = createAction('request/updateRequestCommentsSuccess');
const updateRequestCommentsError = createAction('request/updateRequestCommentsError');

// Update requests
const updateRequests = createAction('request/updateRequests');
const updateRequestsSuccess = createAction('request/updateRequestsSuccess');
const updateRequestsError = createAction('request/updateRequestsError');

// Send updated notification
const sendUpdatedNotification = createAction('request/sendUpdatedNotification');
const sendUpdatedNotificationSuccess = createAction('request/sendUpdatedNotificationSuccess');
const sendUpdatedNotificationError = createAction('request/sendUpdatedNotificationError');

export const requestActions = {
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsError,
  updateFilters,
  updatePagination,
  updatePaginatorInfo,
  getRequests,
  getRequestsSuccess,
  getRequestsError,
  createRequest,
  createRequestSuccess,
  createRequestError,
  deleteRequests,
  deleteRequestsSuccess,
  deleteRequestsError,
  createPdf,
  createPdfSuccess,
  createPdfError,
  sendCreatedNotification,
  sendCreatedNotificationSuccess,
  sendCreatedNotificationError,
  downloadPdf,
  movePdf,
  movePdfSuccess,
  movePdfError,
  updateRequests,
  updateRequestsSuccess,
  updateRequestsError,
  updateRequestComments,
  updateRequestCommentsSuccess,
  updateRequestCommentsError,
  sendUpdatedNotification,
  sendUpdatedNotificationSuccess,
  sendUpdatedNotificationError,
};

// Initial state
const initialState = {
  requests: [],
  options: [],
  filters: {
    id: [],
    author_id: [],
    construction_id: [],
    delivery_date: null,
    status: requestStatus.waiting,
    created_at: null,
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    create: false,
    list: false,
    filter: false,
    comments: false,
  },
  error: null,
  refresh: false,
};

// Reducer
export default createReducer(initialState, {
  // Get filter options reducer
  [getFilterOptions]: (state) => {
    state.loading.filter = true;
    state.error = null;
  },
  [getFilterOptionsSuccess]: (state, action) => {
    state.options = action.payload;
    state.loading.filter = false;
  },
  [getFilterOptionsError]: (state, action) => {
    state.loading.filter = false;
    state.error = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Get requests reducer
  [getRequests]: (state) => {
    state.loading.list = true;
    state.error = null;
    state.refresh = false;
  },
  [getRequestsSuccess]: (state, action) => {
    state.requests = action.payload;
    state.loading.list = false;
  },
  [getRequestsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create request reducer
  [createRequest]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createRequestSuccess]: (state) => {
    state.loading.list = false;
  },
  [createRequestError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Delete requests reducer
  [deleteRequests]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [deleteRequestsSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [deleteRequestsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create pdf reducer
  [createPdf]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createPdfError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Send created notification reducer
  [sendCreatedNotification]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [sendCreatedNotificationError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Move Pdf reducer
  [movePdf]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [movePdfSuccess]: (state) => {
    state.loading.list = false;
    state.refresh = true;
  },
  [movePdfError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Update requests reducer
  [updateRequests]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [updateRequestsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Update request comments reducer
  [updateRequestComments]: (state) => {
    state.loading.comments = true;
    state.error = null;
  },
  [updateRequestCommentsSuccess]: (state, action) => {
    const { payload } = action;

    const request = state.requests.find((currentRequest) => currentRequest.id === payload.id);

    if (request) {
      request.comments = payload.comments;
      request.admin_comments = payload.admin_comments;
    }

    state.loading.comments = false;
  },
  [updateRequestCommentsError]: (state, action) => {
    state.loading.comments = false;
    state.error = action.payload;
  },
  // Send updated notification reducer
  [sendUpdatedNotification]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [sendUpdatedNotificationError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
