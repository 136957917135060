export const roles = ['ADMINISTRADOR', 'USUARIO'];

export const debounceWait = 700;

export const mainSecondaryColor = '#f44336';

export const fileExportFormat = {
  pdf: 'PDF',
  spreadsheet: 'SPREADSHEET',
};

export const requestStatus = {
  waiting: 'EVALUANDO',
  approved: 'APROBADA',
  rejected: 'RECHAZADA',
};

export const requestStringToIntStatus = {
  EVALUANDO: 0,
  APROBADA: 1,
  RECHAZADA: 2,
};

export const requestStatusOptions = [
  { value: requestStatus.waiting, label: requestStatus.waiting },
  { value: requestStatus.approved, label: requestStatus.approved },
  { value: requestStatus.rejected, label: requestStatus.rejected },
];

export const operationType = {
  request: 'REQUISICION',
  order: 'ORDEN',
  entry: 'ENTRADA',
  exit: 'SALIDA',
  refund: 'DEVOLUCION',
};

export const reportType = {
  inventory: 'INVENTARIO',
  entry: 'ENTRADA',
  exit: 'SALIDA',
  refund: 'DEVOLUCION',
  kardex: 'KARDEX',
};

export const constructionStatus = {
  active: 'ACTIVA',
  inactive: 'INACTIVA',
};

export const constructionStatusOptions = [
  { value: constructionStatus.active, label: constructionStatus.active },
  { value: constructionStatus.inactive, label: constructionStatus.inactive },
];

export const userRole = {
  user: 'USUARIO',
  admin: 'ADMINISTRADOR',
};

export const userRoleOptions = [
  { value: userRole.user, label: userRole.user },
  { value: userRole.admin, label: userRole.admin },
];

export const quantityOperations = {
  increase: 'INCREASE',
  decrease: 'DECREASE',
};

export const reportOptions = [
  { value: 'inventory', label: 'Inventario' },
];
