import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import BookIcon from '@material-ui/icons/Book';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CashRefund from 'mdi-material-ui/CashRefund';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChartAreaspline from 'mdi-material-ui/ChartAreaspline';
import HammerWrench from 'mdi-material-ui/HammerWrench';
import FormatListBulleted from 'mdi-material-ui/FormatListBulleted';
import ListItemLink from 'components/shared/ListItemLink';
import routes from 'common/routes';
import { makeStyles } from '@material-ui/core/styles';
import { uiActions } from 'store/modules/ui';

const useStyles = makeStyles({
  settingsIcon: {
    display: 'flex',
    height: 48,
    alignItems: 'center',
  },
  darkModeIcon: {
    height: 48,
  },
});

const NavigationMenu = ({ mobile = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { isAdmin, darkMode, miniDrawer } = useSelector((state) => ({
    isAdmin: state.auth.user.is_admin,
    darkMode: state.ui.darkMode,
    miniDrawer: state.ui.miniDrawer,
  }));

  const onToggleCollapse = () => {
    setOpen(!open);
  };

  const onToggleDarkMode = (event) => {
    dispatch(uiActions.toggleTheme(event.target.checked));
  };

  const onToggleDarkModeIcon = () => {
    dispatch(uiActions.toggleTheme(!darkMode));
  };

  const onToggleMiniDrawer = () => {
    dispatch(uiActions.toggleMiniDrawer());
  };

  return (
    <React.Fragment>
      <List component="nav">
        <ListItemLink
          to={routes.home.path}
          primary={routes.home.title}
          icon={<DashboardIcon />}
          disabled
        />
        <Divider />
        <ListItemLink
          to={routes.request.index.path}
          primary={routes.request.index.title}
          icon={<AssignmentIcon />}
        />
        <Divider />
        {isAdmin && (
          <React.Fragment>
            <ListItemLink
              to={routes.order.index.path}
              primary={routes.order.index.title}
              icon={<ShoppingCartIcon />}
            />
            <Divider />
            <ListItemLink
              to={routes.material.index.path}
              primary={routes.material.index.title}
              icon={<HammerWrench />}
            />
            <Divider />
            <ListItemLink
              to={routes.materialType.index.path}
              primary={routes.materialType.index.title}
              icon={<FormatListBulleted />}
            />
            <Divider />
            <ListItemLink
              to={routes.materialEntry.index.path}
              primary={routes.materialEntry.index.title}
              icon={<CallReceivedIcon />}
            />
            <Divider />
            <ListItemLink
              to={routes.materialExit.index.path}
              primary={routes.materialExit.index.title}
              icon={<CallMadeIcon />}
            />
            <Divider />
            <ListItemLink
              to={routes.materialRefund.index.path}
              primary={routes.materialRefund.index.title}
              icon={<CashRefund />}
            />
            <Divider />
            <ListItemLink
              to={routes.construction.index.path}
              primary={routes.construction.index.title}
              icon={<ApartmentIcon />}
            />
            <Divider />
            <ListItemLink
              to={routes.supplier.index.path}
              primary={routes.supplier.index.title}
              icon={<LocalShippingIcon />}
            />
            <Divider />
            <ListItemLink
              to={routes.user.index.path}
              primary={routes.user.index.title}
              icon={<PeopleIcon />}
            />
            <Divider />
            <ListItem button onClick={onToggleCollapse}>
              <ListItemIcon>
                <ChartAreaspline />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemLink
                  to={routes.report.inventory.path}
                  primary={routes.report.inventory.title}
                  icon={<HammerWrench />}
                  nested
                />
                <ListItemLink
                  to={routes.report.entry.path}
                  primary={routes.report.entry.title}
                  icon={<CallReceivedIcon />}
                  nested
                />
                <ListItemLink
                  to={routes.report.exit.path}
                  primary={routes.report.exit.title}
                  icon={<CallMadeIcon />}
                  nested
                />
                <ListItemLink
                  to={routes.report.refund.path}
                  primary={routes.report.refund.title}
                  icon={<CashRefund />}
                  nested
                />
              </List>
            </Collapse>
            <Divider />
            <ListItemLink
              to="/"
              primary="Versión"
              icon={<BookIcon />}
            />
            <Divider />
          </React.Fragment>
        )}
      </List>
      <List subheader={miniDrawer && !mobile ? (
        <ListSubheader className={classes.settingsIcon}><SettingsIcon /></ListSubheader>
      ) : (
        <ListSubheader>Opciones</ListSubheader>
      )}
      >
        {miniDrawer && !mobile ? (
          <ListItem button onClick={onToggleDarkModeIcon} className={classes.darkModeIcon}>
            <ListItemIcon>
              {darkMode ? <Brightness7Icon /> : <Brightness2Icon />}
            </ListItemIcon>
          </ListItem>
        ) : (
          <ListItem>
            <ListItemIcon>
              <Brightness2Icon />
            </ListItemIcon>
            <ListItemText id="switch-dark-model" primary="Modo Oscuro" />
            <ListItemSecondaryAction>
              <Switch
                color="primary"
                edge="end"
                onChange={onToggleDarkMode}
                checked={darkMode}
                inputProps={{ 'aria-labelledby': 'switch-dark-model' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {!mobile && (
          <ListItem button onClick={onToggleMiniDrawer}>
            <ListItemIcon>
              {miniDrawer ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
            </ListItemIcon>
            <ListItemText primary={miniDrawer ? '' : 'Colapsar'} />
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
};

NavigationMenu.defaultProps = {
  mobile: false,
};

NavigationMenu.propTypes = {
  mobile: PropTypes.bool,
};

export default NavigationMenu;
