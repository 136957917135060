import { take, takeLatest, put } from 'redux-saga/effects';
import { orderActions } from 'store/modules/order';
import { transactionActions } from 'store/modules/transaction';
import { uiActions } from 'store/modules/ui';
import order from 'graphql/order';
import { getOrderFilters } from 'common/filters';
import { createSnack, getUserFriendlyError } from 'common/utils';

/**
 * Get filter options saga
 *
 * @param {*} action
 */
function* getFilterOptions(action) {
  try {
    const params = 'exclude' in action.payload ? action.payload : { ...action.payload, exclude: [] };
    const response = yield order.getFilterOptions(params);
    const { data: { purchaseOrders: { data } } } = response;
    const options = data.map((option) => ({ value: option.id, label: option.id }));

    yield put(orderActions.getFilterOptionsSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(orderActions.getFilterOptionsError(message));
  }
}

/**
 * Get orders saga
 *
 * @param {*} action
 */
function* getOrders(action) {
  try {
    const { payload } = action;
    const filters = getOrderFilters(payload.filters);
    const response = yield order.getOrders({ filters, pagination: payload.pagination });
    const { data: { purchaseOrders: { data, paginatorInfo } } } = response;

    yield put(orderActions.getOrdersSuccess(data));
    yield put(orderActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(orderActions.getOrdersError(message));
  }
}

/**
 * Create order saga
 *
 * @param {*} action
 */
function* createOrder(action) {
  try {
    const { payload: { order: orderData, sendToSupplier, transactions } } = action;
    const { data: { createPurchaseOrder: { id } } } = yield order.createOrder(orderData);

    const transactionsData = transactions.map((transaction) => ({
      ...transaction,
      purchase_order_id: Number(id),
    }));

    const snack = createSnack({
      key: 'send-order-created-notification-success',
      variant: 'success',
      message: `Orden de compra ${id} creada con éxito`,
    });

    yield put(transactionActions.insertTransactions(transactionsData));
    yield take([
      orderActions.createOrderSuccess,
      transactionActions.insertTransactionsSuccess,
    ]);
    yield put(orderActions.createPdf(id));
    yield take(orderActions.createPdfSuccess);
    yield put(orderActions.createSpreadsheet(id));
    yield take(orderActions.createSpreadsheetSuccess);
    yield put(orderActions.sendCreatedNotification({ id, sendToSupplier }));
    yield take(orderActions.sendCreatedNotificationSuccess);
    yield put(orderActions.createOrderSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(orderActions.createOrderError(message));
  }
}

/**
 * Delete orders saga
 *
 * @param {*} action
 */
function* deleteOrders(action) {
  try {
    const {
      data: { deletePurchaseOrders: deletedOrders },
    } = yield order.deleteOrders(action.payload);

    const snack = createSnack({
      key: 'delete-orders-notification-success',
      variant: 'success',
      message: `${deletedOrders.length > 1 ? 'Ordenes de compra eliminadas' : 'Orden de compra eliminada'} con éxito`,
    });

    yield put(orderActions.deleteOrdersSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(orderActions.deleteOrdersError(message));
  }
}

/**
 * Create order PDF saga
 *
 * @param {*} action
 */
function* createPdf(action) {
  try {
    const { payload: id } = action;
    yield order.createPdf(id);
    yield put(orderActions.createPdfSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(orderActions.createPdfError(message));
  }
}

/**
 * Create order spreadsheet saga
 *
 * @param {*} action
 */
function* createSpreadsheet(action) {
  try {
    const { payload: id } = action;
    yield order.createSpreadsheet(id);
    yield put(orderActions.createSpreadsheetSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(orderActions.createSpreadsheetError(message));
  }
}

/**
 * Send created notification saga
 *
 * @param {*} action
 */
function* sendCreatedNotification(action) {
  try {
    const { payload: { id, sendToSupplier } } = action;
    yield order.sendCreatedNotification(id, sendToSupplier);
    yield put(orderActions.sendCreatedNotificationSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(orderActions.sendCreatedNotificationError(message));
  }
}

/**
 * Download file saga
 *
 * @param {*} action
 */
function* downloadFile(action) {
  const response = yield order.getDownloadFileUrl(action.payload);
  const { data: { purchaseOrderDownloadFileUrl: { url: downloadUrl } } } = response;

  window.open(downloadUrl, '_blank');
}

/**
 * Watch order
 *
 * @export
 */
export default function* watchOrder() {
  yield takeLatest(orderActions.getFilterOptions, getFilterOptions);
  yield takeLatest(orderActions.getOrders, getOrders);
  yield takeLatest(orderActions.createOrder, createOrder);
  yield takeLatest(orderActions.deleteOrders, deleteOrders);
  yield takeLatest(orderActions.createPdf, createPdf);
  yield takeLatest(orderActions.createSpreadsheet, createSpreadsheet);
  yield takeLatest(orderActions.sendCreatedNotification, sendCreatedNotification);
  yield takeLatest(orderActions.downloadFile, downloadFile);
}
