import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const CheckboxGroupFieldAdapter = ({ fields, options }) => {
  const toggleOptions = (event, option) => {
    if (event.target.checked) {
      fields.push(option.value);
    } else {
      fields.remove(option.value);
    }
  };

  return (
    <React.Fragment>
      {options.map((option) => {
        if (option.value > 0) {
          return (
            <FormControlLabel
              control={(
                <Checkbox
                  value={option.value}
                  onChange={(event) => toggleOptions(event, option)}
                />
              )}
              label={option.label}
              key={option.value}
            />
          );
        }

        return null;
      })}
    </React.Fragment>
  );
};

CheckboxGroupFieldAdapter.propTypes = {
  fields: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CheckboxGroupFieldAdapter;
