import { take, takeLatest, put } from 'redux-saga/effects';
import { materialExitActions } from 'store/modules/materialExit';
import { movementActions } from 'store/modules/movement';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { getMaterialExitFilters } from 'common/filters';
import { quantityOperations } from 'common/constants';
import { createSnack, getUserFriendlyError } from 'common/utils';
import materialExit from 'graphql/materialExit';

/**
 * Get material exits saga
 *
 * @param {*} action
 */
function* getMaterialExits(action) {
  try {
    const { payload } = action;
    const filters = getMaterialExitFilters(payload.filters);
    const response = yield materialExit.list({
      filters, pagination: payload.pagination,
    });
    const { data: { materialExits: { data, paginatorInfo } } } = response;

    yield put(materialExitActions.getMaterialExitsSuccess(data));
    yield put(materialExitActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialExitActions.getMaterialExitsError(message));
  }
}

/**
 * Create material exit saga
 *
 * @param {*} action
 */
function* createMaterialExit(action) {
  try {
    const { payload: { exit, movements, materialsToUpdate } } = action;
    const { data: { createMaterialExit: { id } } } = yield materialExit.create(exit);

    const movementsData = movements.map((movement) => ({
      ...movement,
      material_exit_id: Number(id),
    }));

    const snack = createSnack({
      key: 'exit-created-notification-success',
      variant: 'success',
      message: `Salida de materiales ${id} creada con éxito`,
    });

    yield put(movementActions.insertMovements(movementsData));
    yield take(movementActions.insertMovementsSuccess);
    yield put(materialActions.updateMaterialByMovement({
      toUpdate: materialsToUpdate, operation: quantityOperations.decrease,
    }));
    yield take(materialActions.updateMaterialByMovementSuccess);
    yield put(materialExitActions.createPdf(id));
    yield take(materialExitActions.createPdfSuccess);
    yield put(materialExitActions.sendCreatedNotification(id));
    yield take(materialExitActions.sendCreatedNotificationSuccess);
    yield put(materialExitActions.createMaterialExitSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialExitActions.createMaterialExitError(message));
  }
}

/**
 * Create material exit PDF saga
 *
 * @param {*} action
 */
function* createPdf(action) {
  try {
    const { payload: id } = action;
    yield materialExit.createPdf(id);
    yield put(materialExitActions.createPdfSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialExitActions.createPdfError(message));
  }
}

/**
 * Send material exit created notification saga
 *
 * @param {*} action
 */
function* sendCreatedNotification(action) {
  try {
    const { payload: id } = action;
    const {
      data: {
        sendMaterialExitCreatedNotification: { sent, error },
      },
    } = yield materialExit.sendCreatedNotification(id);

    if (!sent) {
      yield put(materialExitActions.sendCreatedNotificationError(error));
    } else {
      yield put(materialExitActions.sendCreatedNotificationSuccess());
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialExitActions.sendCreatedNotificationError(message));
  }
}

/**
 * Download pdf saga
 *
 * @param {*} action
 */
function* downloadPdf(action) {
  const { payload: id } = action;
  const response = yield materialExit.getDownloadPdfUrl(id);
  const { data: { materialExitDownloadPdfUrl: { url: downloadUrl } } } = response;

  window.open(downloadUrl, '_blank');
}

/**
 * Watch material exit
 *
 * @export
 */
export default function* watchMaterialExit() {
  yield takeLatest(materialExitActions.getMaterialExits, getMaterialExits);
  yield takeLatest(materialExitActions.createMaterialExit, createMaterialExit);
  yield takeLatest(materialExitActions.createPdf, createPdf);
  yield takeLatest(materialExitActions.sendCreatedNotification, sendCreatedNotification);
  yield takeLatest(materialExitActions.downloadPdf, downloadPdf);
}
