import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { uiActions } from 'store/modules/ui';

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    marginTop: theme.spacing(3),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  note: {
    marginTop: theme.spacing(3),
  },
}));

const ImportMaterialsDialog = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { requestIds, onConfirm } = props;

  const { importMaterialsDialog: isOpen } = useSelector((state) => ({
    importMaterialsDialog: state.ui.importMaterialsDialog,
  }));

  const requestList = requestIds.map(
    (request) => <Chip key={request.value} label={request.value} className={classes.chip} />,
  );

  const onAgree = () => {
    dispatch(uiActions.toggleImportMaterialsDialog(false));
    onConfirm();
  };

  const onClose = () => {
    dispatch(uiActions.toggleImportMaterialsDialog(false));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="import-materials-dialog"
      aria-describedby="import-materials-dialog"
      PaperProps={{
        square: true,
      }}
    >
      <DialogContent>
        <DialogContentText id="import-materials-dialog-description">
          Desea importar los materiales relacionados a las requisiciones:
        </DialogContentText>
        <div className={classes.chipContainer}>{requestList}</div>
        <div className={classes.note}>
          * Cualquier material previamente añadido será eliminado de la lista.
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
        >
          No
        </Button>
        <Button
          onClick={onAgree}
          color="primary"
        >
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ImportMaterialsDialog.propTypes = {
  requestIds: PropTypes.arrayOf(PropTypes.object).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ImportMaterialsDialog;
