import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const SelectFieldAdapter = ({
  input, id, label, options, keyType, ...rest
}) => (
  <FormControl {...rest}>
    <InputLabel id={id}>{label}</InputLabel>
    <Select
      labelId={id}
      id={id}
      value={input.value}
      onChange={(event) => {
        const { target: { value } } = event;
        const formatedValue = (keyType && keyType === 'number') ? Number(value) : value;
        input.onChange(formatedValue);
      }}
      {...rest}
    >
      {options.map(
        (option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>,
      )}
    </Select>
  </FormControl>
);

SelectFieldAdapter.defaultProps = {
  keyType: 'string',
};

SelectFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  keyType: PropTypes.string,
};

export default SelectFieldAdapter;
