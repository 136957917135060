import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Get filter options
const getFilterOptions = createAction('user/getFilterOptions');
const getFilterOptionsSuccess = createAction('user/getFilterOptionsSuccess');
const getFilterOptionsError = createAction('user/getFilterOptionsError');

// Update pagination
const updatePagination = createAction('user/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('user/updatePaginatorInfo');

// Update filters
const updateFilters = createAction('user/updateFilters');

// Get users
const getUsers = createAction('user/getUsers');
const getUsersSuccess = createAction('user/getUsersSuccess');
const getUsersError = createAction('user/getUsersError');

// Get users who can approve
const getUsersWhoCanApprove = createAction('user/getUsersWhoCanApprove');
const getUsersWhoCanApproveSuccess = createAction('user/getUsersWhoCanApproveSuccess');
const getUsersWhoCanApproveError = createAction('user/getUsersWhoCanApproveError');

// Create user
const createUser = createAction('user/createUser');
const createUserSuccess = createAction('user/createUserSuccess');
const createUserError = createAction('user/createUserError');

// Delete user
const deleteUsers = createAction('user/deleteUsers');
const deleteUsersSuccess = createAction('user/deleteUsersSuccess');
const deleteUsersError = createAction('user/deleteUsersError');

// Update user
const updateUser = createAction('user/updateUser');
const updateUserSuccess = createAction('user/updateUserSuccess');
const updateUserError = createAction('user/updateUserError');

export const userActions = {
  getFilterOptions,
  getFilterOptionsSuccess,
  getFilterOptionsError,
  updatePagination,
  updatePaginatorInfo,
  updateFilters,
  getUsers,
  getUsersSuccess,
  getUsersError,
  getUsersWhoCanApprove,
  getUsersWhoCanApproveSuccess,
  getUsersWhoCanApproveError,
  createUser,
  createUserSuccess,
  createUserError,
  deleteUsers,
  deleteUsersSuccess,
  deleteUsersError,
  updateUser,
  updateUserSuccess,
  updateUserError,
};

const regularFilterActions = [
  getFilterOptions.type,
  getUsersWhoCanApprove.type,
];

const regularFilterErrorActions = [
  getFilterOptionsError.type,
  getUsersWhoCanApproveError.type,
];

const regularActions = [
  getUsers.type,
  createUser.type,
  deleteUsers.type,
  updateUser.type,
];

const regularErrorActions = [
  getUsersError.type,
  createUserError.type,
  deleteUsersError.type,
  updateUserError.type,
];

const regularRefreshAction = [
  deleteUsersSuccess.type,
  updateUserSuccess.type,
];

const isRegularFilterAction = (action) => regularFilterActions.includes(action.type);
const isRegularFilterErrorAction = (action) => regularFilterErrorActions.includes(action.type);
const isRegularAction = (action) => regularActions.includes(action.type);
const isRegularErrorAction = (action) => regularErrorActions.includes(action.type);
const isRegularRefreshAction = (action) => regularRefreshAction.includes(action.type);

// Initial state
const initialState = {
  users: [],
  canApprove: [],
  options: [],
  filters: {
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    role: '',
    can_approve: null,
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    list: false,
    filter: false,
  },
  error: null,
  refresh: false,
};

// Reducer
export default createReducer(initialState, {
  // Get filter options reducer
  [getFilterOptionsSuccess]: (state, action) => {
    state.options = action.payload;
    state.loading.filter = false;
    state.error = null;
  },
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Get users reducer
  [getUsersSuccess]: (state, action) => {
    state.users = action.payload;
    state.loading.list = false;
    state.error = null;
  },
  // Get users who can approve reducer
  [getUsersWhoCanApproveSuccess]: (state, action) => {
    state.canApprove = action.payload;
    state.loading.filter = false;
    state.error = null;
  },
  // Create user reducer
  [createUserSuccess]: (state) => {
    state.loading.list = false;
    state.error = null;
  },
  // Purge reducer
  [PURGE]: () => initialState,
}, [
  {
    matcher: isRegularFilterAction,
    reducer(state) {
      state.loading.filter = true;
      state.error = null;
    },
  },
  {
    matcher: isRegularFilterErrorAction,
    reducer(state, action) {
      state.loading.filter = false;
      state.error = action.payload;
    },
  },
  {
    matcher: isRegularAction,
    reducer(state) {
      state.loading.list = true;
      state.error = null;
    },
  },
  {
    matcher: isRegularErrorAction,
    reducer(state, action) {
      state.loading.list = false;
      state.error = action.payload;
    },
  },
  {
    matcher: isRegularRefreshAction,
    reducer(state) {
      state.loading.list = false;
      state.error = null;
      state.refresh = true;
    },
  },
]);
