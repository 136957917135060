import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { uiActions } from 'store/modules/ui';

const SnackCloseButton = ({ snackKey = new Date().getTime() + Math.random() }) => {
  const dispatch = useDispatch();

  const onCloseSnack = () => {
    dispatch(uiActions.closeSnackbar({ key: snackKey }));
  };

  return (
    <IconButton onClick={onCloseSnack}>
      <CloseIcon />
    </IconButton>
  );
};

SnackCloseButton.propTypes = {
  snackKey: PropTypes.string.isRequired,
};

export default SnackCloseButton;
