import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  noBorder: {
    borderBottom: 0,
  },
}));

const UserTableDetail = (props) => {
  const { data: { notifications: { request = {}, order = {} } } } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.detailPanel}>
      <React.Fragment>
        <Typography className={classes.title} component="h3">Notificaciones</Typography>
        <div className={classes.tableContainer}>
          <Table aria-label="notificacions-table">
            <TableBody>
              <TableRow>
                <TableCell />
                <TableCell align="center">Creada</TableCell>
                <TableCell align="center">Actualizada</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Requisición de Materiales</TableCell>
                <TableCell align="center">
                  {request.created ? (
                    <CheckIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </TableCell>
                <TableCell align="center">
                  {request.updated ? (
                    <CheckIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.noBorder}>Orden de Compra</TableCell>
                <TableCell align="center" className={classes.noBorder}>
                  {order.created ? (
                    <CheckIcon />
                  ) : (
                    <CloseIcon />
                  )}
                </TableCell>
                <TableCell className={classes.noBorder} />
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </React.Fragment>
    </div>
  );
};

UserTableDetail.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UserTableDetail;
