import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { materialTypeActions } from 'store/modules/materialType';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import MaterialTypesTable from './Table';

const MaterialType = () => {
  const dispatch = useDispatch();

  const { refresh } = useSelector((state) => ({
    refresh: state.materialType.refresh,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.materialType.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.materialType.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(materialTypeActions.getMaterialTypes());
  }, [refresh, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Tipos de Materiales" buttonProps={buttonProps} />
      <MaterialTypesTable />
    </React.Fragment>
  );
};

export default MaterialType;
