import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';
import { cleanString } from 'common/utils';

/**
 * Get filter options method
 * @param  {Object} payload
 * @return {Promise}
 */
const getFilterOptions = (payload) => {
  const whereCondition = payload.exclude.length > 0
    ? `where: { AND: [ { column: ID, operator: NOT_IN, value: [ ${payload.exclude} ] } ] }`
    : '';

  return (
    client.query({
      query: gql`
        query materials($first: Int!, $page: Int!) {
          materials(
            first: $first
            page: $page
            search: "${cleanString(payload.value)}"
            ${whereCondition}
          ) {
            data {
              id
              name
              unit
              quantity
              value
              discount
              iva
            }
          }
        }
      `,
      variables: {
        first: payload.pagination.first,
        page: payload.pagination.page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Get materials method
 * @param  {Object} payload
 * @return {Promise}
 */
const getMaterials = (payload) => {
  const { filters, pagination: { first, page } } = payload;

  const id = filters.id.length > 0 ? `{ column: ID, operator: IN, value: [ ${filters.id} ] }` : '';
  const materialTypeId = filters.material_type_id > 0 ? `{ column: MATERIAL_TYPE_ID, value: ${filters.material_type_id} }` : '';
  const name = filters.name ? `{ column: NAME, operator: LIKE, value: "${filters.name}%"}` : '';

  const where = `
    where: {
      AND: [
        ${id}
        ${materialTypeId}
        ${name}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query materials($first: Int!, $page: Int!) {
          materials(
            first: $first
            page: $page
            ${where}
            orderBy: [{
              column: NAME
              order: ASC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              name
              material_type_id {
                id
                name
              }
              unit
              quantity
              value
              discount
              iva
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create material method
 * @param  {Object} payload
 * @return {Promise}
 */
const createMaterial = (payload) => client.mutate({
  mutation: gql`
    mutation createMaterial($input: CreateMaterialInput!) {
      createMaterial(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Create material method
 * @param  {Object} payload
 * @return {Promise}
 */
const importMaterials = (payload) => client.mutate({
  mutation: gql`
    mutation uploadMaterialList($file: Upload!) {
      uploadMaterialList(file: $file) {
        processed
        errors {
          row
          values
          errors_messages
        }
      }
    }
  `,
  variables: { file: payload },
});

/**
 * Delete materials method
 * @param  {String} ids
 * @return {Promise}
 */
const deleteMaterials = (ids) => client.mutate({
  mutation: gql`
    mutation deleteMaterials($id: [ID!]!) {
      deleteMaterials(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Update material method
 * @param  {Object} payload
 * @return {Promise}
 */
const updateMaterial = (payload) => client.mutate({
  mutation: gql`
    mutation updateMaterial($id: ID!, $input: UpdateMaterialInput!) {
      updateMaterial(id: $id, input: $input) {
        id
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

/**
 * Update material by movement method
 * @param  {Object} payload
 * @return {Promise}
 */
const updateMaterialByMovement = (payload) => client.mutate({
  mutation: gql`
    mutation updateMaterialByMovement($input: UpdateMaterialByMovementInput!) {
      updateMaterialByMovement(input: $input) {
        processed
      }
    }
  `,
  variables: { input: payload },
});

export default {
  getFilterOptions,
  getMaterials,
  createMaterial,
  importMaterials,
  deleteMaterials,
  updateMaterial,
  updateMaterialByMovement,
};
