import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { constructionActions } from 'store/modules/construction';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import ConstructionFilters from './Filters';
import ConstructionsTable from './Table';

const Construction = () => {
  const dispatch = useDispatch();

  const { filters, pagination, refresh } = useSelector((state) => ({
    filters: state.construction.filters,
    pagination: state.construction.pagination,
    refresh: state.construction.refresh,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.construction.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.construction.index.title));
  }, [dispatch]);

  useEffect(() => {
    dispatch(constructionActions.getConstructions({ filters, pagination }));
  }, [filters, pagination, refresh, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Obras" buttonProps={buttonProps} />
      <ConstructionFilters isOpen />
      <ConstructionsTable />
    </React.Fragment>
  );
};

export default Construction;
