import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { constructionActions } from 'store/modules/construction';
import tableIcons from 'common/tableIcons';
import TablePaginationActions from 'components/shared/TablePaginationActions';
import TableLoadingIndicator from 'components/shared/TableLoadingIndicator';
import routes from 'common/routes';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(8),
    },
    '& .MuiTableFooter-root .MuiTablePagination-root': {
      display: 'block',
      boxSizing: 'border-box',
      clear: 'both',
    },
  },
  paginationToolbar: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  paginationSpacer: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 auto',
    },
  },
}));

const ConstructionsTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    constructions,
    pagination,
    paginatorInfo,
    loading,
  } = useSelector((state) => ({
    constructions: state.construction.constructions,
    pagination: state.construction.pagination,
    paginatorInfo: state.construction.paginatorInfo,
    loading: state.construction.loading.list,
  }));

  const data = constructions.map((construction) => ({
    id: construction.id,
    name: construction.name,
    work_order: construction.work_order,
    status: construction.status,
  }));

  const getDisplayedRows = () => {
    const firstItem = pagination.page === 1 ? 1 : (pagination.page - 1) * pagination.first;

    const lastItem = pagination.page === paginatorInfo.lastPage
      ? paginatorInfo.total : pagination.page * pagination.first;

    return `${firstItem}-${lastItem} de ${paginatorInfo.total}`;
  };

  const goToEdit = (event, rowData) => {
    history.push(routes.construction.update.path.replace(':id', rowData.id));
  };

  const onDelete = (event, rowData) => {
    const ids = rowData.map((row) => row.id);
    dispatch(constructionActions.deleteConstructions(ids));
  };

  const onPageChange = (event, newPage) => {
    dispatch(constructionActions.updatePagination({ page: newPage }));
  };

  return (
    <Paper square className={classes.container}>
      <MaterialTable
        columns={[
          { title: 'Consec.', field: 'id' },
          { title: 'Nombre', field: 'name', width: 500 },
          { title: 'OT', field: 'work_order' },
          { title: 'Estado', field: 'status' },
        ]}
        data={data}
        icons={tableIcons}
        isLoading={loading}
        actions={[
          {
            icon: () => <DeleteIcon />,
            position: 'toolbarOnSelect',
            tooltip: 'Eliminar',
            onClick: onDelete,
          },
          {
            icon: () => <EditIcon />,
            position: 'row',
            tooltip: 'Editar',
            onClick: goToEdit,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          emptyRowsWhenPaging: false,
          draggable: false,
          sorting: false,
          selection: true,
          pageSize: pagination.first,
          initialPage: pagination.page,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyRowsWhenPaging: 'No hay obras que mostrar. Intenta cambiando los filtros',
            emptyDataSourceMessage: 'No hay obras que mostrar. Intenta cambiando los filtros',
          },
          toolbar: {
            nRowsSelected: '{0} fila(s) seleccionada(s)',
          },
        }}
        components={{
          OverlayLoading: () => (<TableLoadingIndicator />),
          Pagination: () => (
            <TablePagination
              classes={{
                toolbar: classes.paginationToolbar,
                spacer: classes.paginationSpacer,
              }}
              count={paginatorInfo.total}
              page={pagination.page}
              rowsPerPage={pagination.first}
              rowsPerPageOptions={[pagination.first]}
              labelDisplayedRows={getDisplayedRows}
              onChangePage={onPageChange}
              ActionsComponent={TablePaginationActions}
            />
          ),
        }}
      />
    </Paper>
  );
};

export default ConstructionsTable;
