import { takeLatest, put } from 'redux-saga/effects';
import { userActions } from 'store/modules/user';
import { authActions } from 'store/modules/auth';
import { uiActions } from 'store/modules/ui';
import { createSnack, getUserFriendlyError } from 'common/utils';
import user from 'graphql/user';

/**
 * Get filter options saga
 *
 * @param {*} action
 */
function* getFilterOptions(action) {
  try {
    const params = 'exclude' in action.payload ? action.payload : { ...action.payload, exclude: [] };
    const response = yield user.getFilterOptions(params);
    const { data: { users: { data } } } = response;
    const options = data.map((option) => ({ value: option.id, label: option.full_name }));

    yield put(userActions.getFilterOptionsSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(userActions.getFilterOptionsError(message));
  }
}

/**
 * Get users saga
 *
 * @param {*} action
 */
function* getUsers(action) {
  try {
    const { payload } = action;
    const response = yield user.getUsers(payload);
    const { data: { users: { data, paginatorInfo } } } = response;

    yield put(userActions.getUsersSuccess(data));
    yield put(userActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(userActions.getUsersError(message));
  }
}

/**
 * Get users who can approve saga
 *
 * @param {*} action
 */
function* getUsersWhoCanApprove(action) {
  try {
    const { payload } = action;
    const response = yield user.getUsers(payload);
    const { data: { users: { data } } } = response;

    yield put(userActions.getUsersWhoCanApproveSuccess(data));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(userActions.getUsersWhoCanApproveError(message));
  }
}

/**
 * Create user saga
 *
 * @param {*} action
 */
function* createUser(action) {
  try {
    yield user.createUser(action.payload);

    const snack = createSnack({
      key: 'user-created-notification-success',
      variant: 'success',
      message: 'Usuario creado con éxito',
    });

    yield put(userActions.createUserSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(userActions.createUserError(message));
  }
}

/**
 * Delete users saga
 *
 * @param {*} action
 */
function* deleteUsers(action) {
  try {
    const {
      data: { deleteUsers: deletedUsers },
    } = yield user.deleteUsers(action.payload);

    const snack = createSnack({
      key: 'delete-users-notification-success',
      variant: 'success',
      message: `${deletedUsers.length > 1 ? 'Usuarios eliminados' : 'Usuario eliminado'} con éxito`,
    });

    yield put(userActions.deleteUsersSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(userActions.deleteUsersError(message));
  }
}

/**
 * Update user saga
 *
 * @param {*} action
 */
function* updateUser(action) {
  try {
    const {
      data: { updateUser: updatedUser },
    } = yield user.updateUser(action.payload);

    const snack = createSnack({
      key: 'update-user-notification-success',
      variant: 'success',
      message: 'El usuario fue actualizado con éxito',
    });

    yield put(userActions.updateUserSuccess(updatedUser));
    yield put(authActions.updateCurrentUser(updatedUser));
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(userActions.updateUserError(message));
  }
}

/**
 * Watch user
 *
 * @export
 */
export default function* watchUser() {
  yield takeLatest(userActions.getFilterOptions, getFilterOptions);
  yield takeLatest(userActions.getUsers, getUsers);
  yield takeLatest(userActions.getUsersWhoCanApprove, getUsersWhoCanApprove);
  yield takeLatest(userActions.createUser, createUser);
  yield takeLatest(userActions.deleteUsers, deleteUsers);
  yield takeLatest(userActions.updateUser, updateUser);
}
