import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import { requestActions } from 'store/modules/request';

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(3),
  },
  buttonWrapper: {
    width: 106.4,
    display: 'inline-block',
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  materialsTableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  adminComments: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const TableDetail = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);

  const {
    data: {
      id = null,
      comments = '',
      admin_comments = '',
      material_transactions = [],
    },
    user,
  } = props;

  const { loading } = useSelector((state) => ({ loading: state.request.loading.comments }));

  const initialValues = {
    comments,
    admin_comments,
  };

  const onSubmit = (values) => {
    const input = {
      comments: values.comments || '',
      admin_comments: values.admin_comments || '',
    };

    dispatch(requestActions.updateRequestComments({ id, input }));
  };

  return (
    <div className={classes.detailPanel}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          pristine,
          invalid,
        }) => (
          <form
            onSubmit={handleSubmit}
          >
            {!_isEmpty(material_transactions) && (
              <React.Fragment>
                <Typography component="h3" className={classes.title}>Materiales</Typography>
                <Divider />
                <div className={classes.materialsTableContainer}>
                  <Table aria-label="material-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Material</TableCell>
                        <TableCell>Unidad</TableCell>
                        <TableCell>Cantidad</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {material_transactions.map((row) => (
                        <React.Fragment>
                          {row.material_id && (
                            <TableRow key={`${row.material_id.id}-${Math.random(0, 10)}`}>
                              <TableCell component="th" scope="row">
                                {row?.material_id.name}
                              </TableCell>
                              <TableCell>{row.material_id.unit}</TableCell>
                              <TableCell>{row.quantity}</TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </React.Fragment>
            )}
            <Box textAlign="right" mt={5}>
              <div className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  disabled={invalid || pristine || loading}
                >
                  Guardar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    thickness={5}
                    color="secondary"
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
            <Grid container spacing={2}>
              {comments && (
                <Grid item xs={12} lg={user.is_admin ? 6 : 12}>
                  <React.Fragment>
                    <Typography component="h3" className={classes.title}>Comentarios del Autor</Typography>
                    <Field
                      name="comments"
                      component={TextFieldAdapter}
                      variant="outlined"
                      disabled={loading}
                      fullWidth
                    />
                  </React.Fragment>
                </Grid>
              )}
              {user.is_admin && (
                <Grid item xs={12} lg={comments ? 6 : 12} className={classes.adminComments}>
                  <React.Fragment>
                    <Typography component="h3" className={classes.title}>Comentarios del Administrador</Typography>
                    <Field
                      name="admin_comments"
                      component={TextFieldAdapter}
                      variant="outlined"
                      disabled={loading}
                      fullWidth
                    />
                  </React.Fragment>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      />
    </div>
  );
};

TableDetail.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default TableDetail;
