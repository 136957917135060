import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { supplierActions } from 'store/modules/supplier';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import { validateSupplier } from 'common/formValidations';
import { cleanObject } from 'common/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
}));

const initialValues = {
  name: '',
  nit: '',
  manager: '',
  address: '',
  city: '',
  email: '',
  phone: '',
  fax: '',
};

const CreateSupplier = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { loading } = useSelector((state) => ({ loading: state.supplier.loading.list }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.supplier.create.title));
  }, [dispatch]);

  const resetAll = (args) => {
    const form = args[0];

    form.restart();
  };

  const onSubmit = (values) => {
    const cleanValues = cleanObject(values);

    dispatch(supplierActions.createSupplier(cleanValues));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Crear Proveedor" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validateSupplier}
          onSubmit={onSubmit}
          mutators={{
            resetAll,
          }}
          render={({
            handleSubmit,
            invalid,
            pristine,
            form,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="name"
                    component={TextFieldAdapter}
                    label="Nombre"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="nit"
                    component={TextFieldAdapter}
                    label="NIT"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="manager"
                    component={TextFieldAdapter}
                    label="Manager"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="address"
                    component={TextFieldAdapter}
                    label="Dirección"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="city"
                    component={TextFieldAdapter}
                    label="Ciudad"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="email"
                    component={TextFieldAdapter}
                    label="Email"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="phone"
                    component={TextFieldAdapter}
                    label="Teléfono"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="fax"
                    component={TextFieldAdapter}
                    label="Fax"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.mutators.resetAll(form);
                    }}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={8} sm={6} className={classes.submit}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.supplier.index.path}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || pristine || loading}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
    </React.Fragment>
  );
};

export default CreateSupplier;
