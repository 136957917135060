import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Update pagination
const updatePagination = createAction('materialExit/updatePagination');

// Update paginator info
const updatePaginatorInfo = createAction('materialExit/updatePaginatorInfo');

// Update filters
const updateFilters = createAction('materialExit/updateFilters');

// Get material exits
const getMaterialExits = createAction('materialExit/getMaterialExits');
const getMaterialExitsSuccess = createAction('materialExit/getMaterialExitsSuccess');
const getMaterialExitsError = createAction('materialExit/getMaterialExitsError');

// Create material exit
const createMaterialExit = createAction('materialExit/createMaterialExit');
const createMaterialExitSuccess = createAction('materialExit/createMaterialExitSuccess');
const createMaterialExitError = createAction('materialExit/createMaterialExitError');

// Create pdf
const createPdf = createAction('materialExit/createPdf');
const createPdfSuccess = createAction('materialExit/createPdfSuccess');
const createPdfError = createAction('materialExit/createPdfError');

// Send created notification
const sendCreatedNotification = createAction('materialExit/sendCreatedNotification');
const sendCreatedNotificationSuccess = createAction('materialExit/sendCreatedNotificationSuccess');
const sendCreatedNotificationError = createAction('materialExit/sendCreatedNotificationError');

// Download pdf
const downloadPdf = createAction('materialExit/downloadPdf');

export const materialExitActions = {
  updatePagination,
  updatePaginatorInfo,
  updateFilters,
  getMaterialExits,
  getMaterialExitsSuccess,
  getMaterialExitsError,
  createMaterialExit,
  createMaterialExitSuccess,
  createMaterialExitError,
  createPdf,
  createPdfSuccess,
  createPdfError,
  sendCreatedNotification,
  sendCreatedNotificationSuccess,
  sendCreatedNotificationError,
  downloadPdf,
};

// Initial state
const initialState = {
  materialExits: [],
  options: [],
  filters: {
    author_id: [],
    construction_id: [],
    requested_by_id: [],
    created_at: null,
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0,
  },
  pagination: {
    first: 20,
    page: 1,
  },
  loading: {
    list: false,
    filter: false,
  },
  error: null,
};

// Reducer
export default createReducer(initialState, {
  // Update pagination reducer
  [updatePagination]: (state, action) => {
    state.pagination = { ...state.pagination, ...action.payload };
  },
  // Update paginator info reducer
  [updatePaginatorInfo]: (state, action) => {
    state.paginatorInfo = action.payload;
  },
  // Update filters reducer
  [updateFilters]: (state, action) => {
    state.filters = action.payload;
  },
  // Get material exits reducer
  [getMaterialExits]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [getMaterialExitsSuccess]: (state, action) => {
    state.materialExits = action.payload;
    state.loading.list = false;
  },
  [getMaterialExitsError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create material exit reducer
  [createMaterialExit]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createMaterialExitSuccess]: (state) => {
    state.loading.list = false;
  },
  [createMaterialExitError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Create pdf reducer
  [createPdf]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [createPdfError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Send created notification reducer
  [sendCreatedNotification]: (state) => {
    state.loading.list = true;
    state.error = null;
  },
  [sendCreatedNotificationError]: (state, action) => {
    state.loading.list = false;
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
