import LoginLayout from 'components/layout/LoginLayout';
import DefaultLayout from 'components/layout/DefaultLayout';
import NotFoundLayout from 'components/layout/NotFoundLayout';
import { roles } from 'common/constants';
import routes from 'common/routes';

// Public components
import Login from 'components/login';
import NotFound from 'components/not-found';

// Private components
import Dashboard from 'components/dashboard';

import Request from 'components/request';
import CreateRequest from 'components/request/Create';

import Order from 'components/order';
import CreateOrder from 'components/order/Create';

import Material from 'components/material';
import CreateMaterial from 'components/material/Create';
import UpdateMaterial from 'components/material/Update';

import MaterialType from 'components/material-type';
import CreateMaterialType from 'components/material-type/Create';
import UpdateMaterialType from 'components/material-type/Update';

import MaterialEntry from 'components/material-entry';
import CreateMaterialEntry from 'components/material-entry/Create';

import MaterialRefund from 'components/material-refund';
import CreateMaterialRefund from 'components/material-refund/Create';

import MaterialExit from 'components/material-exit';
import CreateMaterialExit from 'components/material-exit/Create';

import Construction from 'components/construction';
import CreateConstruction from 'components/construction/Create';
import UpdateConstruction from 'components/construction/Update';

import Supplier from 'components/supplier';
import CreateSupplier from 'components/supplier/Create';
import UpdateSupplier from 'components/supplier/Update';

import User from 'components/user';
import CreateUser from 'components/user/Create';
import UpdateUser from 'components/user/Update';
import UserProfile from 'components/user/Profile';

import InventoryReport from 'components/report/inventory';
import EntryReport from 'components/report/entry';
import ExitReport from 'components/report/exit';
import RefundReport from 'components/report/refund';

export const publicRoutes = [
  {
    key: 'root',
    exact: true,
    path: routes.login.path,
    component: Login,
    layout: LoginLayout,
    allowedRoles: [roles[0], roles[1]],
  },
  {
    key: 'not-found',
    path: '*',
    component: NotFound,
    layout: NotFoundLayout,
    allowedRoles: [roles[0], roles[1]],
  },
];

export const privateRoutes = [
  {
    key: 'home',
    exact: true,
    path: routes.home.path,
    component: Dashboard,
    layout: DefaultLayout,
    allowedRoles: [roles[0], roles[1]],
  },
  {
    key: 'requests',
    exact: true,
    path: routes.request.index.path,
    component: Request,
    layout: DefaultLayout,
    allowedRoles: [roles[0], roles[1]],
  },
  {
    key: 'request-create',
    exact: true,
    path: routes.request.create.path,
    component: CreateRequest,
    layout: DefaultLayout,
    allowedRoles: [roles[0], roles[1]],
  },
  {
    key: 'orders',
    exact: true,
    path: routes.order.index.path,
    component: Order,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'order-create',
    exact: true,
    path: routes.order.create.path,
    component: CreateOrder,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'materials',
    exact: true,
    path: routes.material.index.path,
    component: Material,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-create',
    exact: true,
    path: routes.material.create.path,
    component: CreateMaterial,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-update',
    exact: true,
    path: routes.material.update.path,
    component: UpdateMaterial,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-type',
    exact: true,
    path: routes.materialType.index.path,
    component: MaterialType,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-type-create',
    exact: true,
    path: routes.materialType.create.path,
    component: CreateMaterialType,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-type-update',
    exact: true,
    path: routes.materialType.update.path,
    component: UpdateMaterialType,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-entry',
    exact: true,
    path: routes.materialEntry.index.path,
    component: MaterialEntry,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-entry-create',
    exact: true,
    path: routes.materialEntry.create.path,
    component: CreateMaterialEntry,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-refund',
    exact: true,
    path: routes.materialRefund.index.path,
    component: MaterialRefund,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-refund-create',
    exact: true,
    path: routes.materialRefund.create.path,
    component: CreateMaterialRefund,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-exit',
    exact: true,
    path: routes.materialExit.index.path,
    component: MaterialExit,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'material-exit-create',
    exact: true,
    path: routes.materialExit.create.path,
    component: CreateMaterialExit,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'constructions',
    exact: true,
    path: routes.construction.index.path,
    component: Construction,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'construction-create',
    exact: true,
    path: routes.construction.create.path,
    component: CreateConstruction,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'construction-update',
    exact: true,
    path: routes.construction.update.path,
    component: UpdateConstruction,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'suppliers',
    exact: true,
    path: routes.supplier.index.path,
    component: Supplier,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'supplier-create',
    exact: true,
    path: routes.supplier.create.path,
    component: CreateSupplier,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'supplier-update',
    exact: true,
    path: routes.supplier.update.path,
    component: UpdateSupplier,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'users',
    exact: true,
    path: routes.user.index.path,
    component: User,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'user-create',
    exact: true,
    path: routes.user.create.path,
    component: CreateUser,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'user-update',
    exact: true,
    path: routes.user.update.path,
    component: UpdateUser,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'user-profile',
    exact: true,
    path: routes.user.profile.path,
    component: UserProfile,
    layout: DefaultLayout,
    allowedRoles: [roles[0], roles[1]],
  },
  {
    key: 'inventory-report',
    exact: true,
    path: routes.report.inventory.path,
    component: InventoryReport,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'entry-report',
    exact: true,
    path: routes.report.entry.path,
    component: EntryReport,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'exit-report',
    exact: true,
    path: routes.report.exit.path,
    component: ExitReport,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
  {
    key: 'refund-report',
    exact: true,
    path: routes.report.refund.path,
    component: RefundReport,
    layout: DefaultLayout,
    allowedRoles: [roles[0]],
  },
];
