import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Get filter options method
 * @param  {Object} payload
 * @return {Promise}
 */
const getFilterOptions = (payload) => {
  const exclude = payload.exclude.length > 0
    ? `{ column: ID, operator: NOT_IN, value: [ ${payload.exclude} ] }`
    : '';

  return (
    client.query({
      query: gql`
        query suppliers($first: Int!, $page: Int!) {
          suppliers(
            first: $first
            page: $page
            where: {
              AND: [
                ${exclude}
                {
                  AND: [
                    {
                      OR: [
                        { column: NIT, operator: LIKE, value: "%${payload.value}%" }
                        { column: NAME, operator: LIKE, value: "%${payload.value}%" }
                        { column: MANAGER, operator: LIKE, value: "%${payload.value}%" }
                      ]
                    }
                  ]
                }
              ]
            }
            orderBy: [{
              column: NAME
              order: ASC
            }]
          ) {
            data {
              id
              name
            }
          }
        }
      `,
      variables: {
        first: payload.pagination.first,
        page: payload.pagination.page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Get suppliers method
 * @param  {Object} payload
 * @return {Promise}
 */
const getSuppliers = (payload) => {
  const { filters, pagination: { first, page } } = payload;

  const id = filters.id.length > 0 ? `{ column: ID, operator: IN, value: [ ${filters.id} ] }` : '';
  const nit = filters.nit ? `{ column: NIT, operator: LIKE, value: "${filters.nit}%"}` : '';
  const name = filters.name ? `{ column: NAME, operator: LIKE, value: "${filters.name}%"}` : '';
  const manager = filters.manager ? `{ column: MANAGER, operator: LIKE, value: "${filters.manager}%"}` : '';
  const city = filters.city ? `{ column: CITY, operator: LIKE, value: "${filters.city}%"}` : '';
  const email = filters.email ? `{ column: EMAIL, operator: LIKE, value: "${filters.email}%"}` : '';

  const where = `
    where: {
      AND: [
        ${id}
        ${nit}
        ${name}
        ${manager}
        ${city}
        ${email}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query suppliers($first: Int!, $page: Int!) {
          suppliers(
            first: $first
            page: $page
            ${where}
            orderBy: [{
              column: NAME
              order: ASC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              nit
              name
              manager
              address
              city
              phone
              fax
              email
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create supplier method
 * @param  {Object} payload
 * @return {Promise}
 */
const createSupplier = (payload) => client.mutate({
  mutation: gql`
    mutation createSupplier($input: CreateSupplierInput!) {
      createSupplier(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Delete suppliers method
 * @param  {String} ids
 * @return {Promise}
 */
const deleteSuppliers = (ids) => client.mutate({
  mutation: gql`
    mutation deleteSuppliers($id: [ID!]!) {
      deleteSuppliers(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Update supplier method
 * @param  {Object} payload
 * @return {Promise}
 */
const updateSupplier = (payload) => client.mutate({
  mutation: gql`
    mutation updateSupplier($id: ID!, $input: UpdateSupplierInput!) {
      updateSupplier(id: $id, input: $input) {
        id
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

export default {
  getFilterOptions,
  getSuppliers,
  createSupplier,
  deleteSuppliers,
  updateSupplier,
};
