import { takeLatest, put } from 'redux-saga/effects';
import { movementActions } from 'store/modules/movement';
import { materialEntryActions } from 'store/modules/materialEntry';
import { materialExitActions } from 'store/modules/materialExit';
import { materialRefundActions } from 'store/modules/materialRefund';
import { getUserFriendlyError } from 'common/utils';
import { operationType } from 'common/constants';
import movement from 'graphql/movement';

const getMaterialErrorAction = (type, message) => {
  const action = {
    [operationType.entry]: materialEntryActions.createMaterialEntryError(message),
    [operationType.exit]: materialExitActions.createMaterialExitError(message),
    [operationType.refund]: materialRefundActions.createMaterialRefundError(message),
  };

  return action[type];
};

/**
 * Insert movements saga
 *
 * @param {*} action
 */
function* insertMovements(action) {
  try {
    const { payload } = action;
    yield movement.insert(payload);
    yield put(movementActions.insertMovementsSuccess());
  } catch (ex) {
    const { payload } = action;
    const { type } = payload[0];
    const message = getUserFriendlyError(ex);

    yield put(getMaterialErrorAction(type, message));
  }
}

/**
 * Watch movement
 *
 * @export
 */
export default function* watchMovement() {
  yield takeLatest(movementActions.insertMovements, insertMovements);
}
