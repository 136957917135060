import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar - 1,
  },
}));

const DialogLoadingIndicator = ({ open = false }) => {
  const classes = useStyles();

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress size={34} thickness={5} color="secondary" />
    </Backdrop>
  );
};

DialogLoadingIndicator.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default DialogLoadingIndicator;
