import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, FormSpy } from 'react-final-form';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import formatISO9075 from 'date-fns/formatISO9075';
import _debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import { date } from 'common/validations';
import { reportActions } from 'store/modules/report';
import { materialActions } from 'store/modules/material';
import { constructionActions } from 'store/modules/construction';
import { reportType, debounceWait } from 'common/constants';
import { filtersPagination } from 'common/filters';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  accordionRoot: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  diviver: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
  },
}));

const initialValues = {
  start_date: null,
  end_date: null,
  materials_id: [],
  constructions_id: [],
};

const RefundForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(null);

  const {
    actionLoading,
    authUser,
    materialOptions,
    materialLoading,
    constructionOptions,
    constructionLoading,
  } = useSelector((state) => ({
    actionLoading: state.report.loading.action,
    authUser: state.auth.user,
    materialOptions: state.material.options,
    materialLoading: state.material.loading.filter,
    constructionOptions: state.construction.options,
    constructionLoading: state.construction.loading.filter,
  }));

  const disableStartDate = (day) => {
    const { values: { end_date } } = formState;

    if (end_date) {
      const difference = differenceInCalendarDays(day, end_date);
      const isEqualOrAfter = isEqual(day, end_date) || isAfter(day, end_date);

      return difference > 62 || isEqualOrAfter;
    }

    return false;
  };

  const disableEndDate = (day) => {
    const { values: { start_date } } = formState;

    if (start_date) {
      const difference = differenceInCalendarDays(day, start_date);

      return difference > 62 || difference <= 0;
    }

    return false;
  };

  const validate = (values) => {
    const errors = {};

    if (!date(values.start_date)) {
      errors.start_date = 'Este campo no puede estar vacío';
    }

    if (!date(values.end_date)) {
      errors.end_date = 'Este campo no puede estar vacío';
    }

    return errors;
  };

  const onMaterialIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(materialActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onConstructionIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(constructionActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onSubmit = (values) => {
    let fileData = {
      start_date: formatISO9075(values.start_date),
      end_date: formatISO9075(values.end_date),
    };

    if ('materials_id' in values && values.materials_id.length > 0) {
      fileData = {
        ...fileData,
        materials_id: values.materials_id.map((material) => Number(material.value)),
      };
    }

    if ('constructions_id' in values && values.constructions_id.length > 0) {
      fileData = {
        ...fileData,
        constructions_id: values.constructions_id.map((construction) => Number(construction.value)),
      };
    }

    const createData = {
      author_id: Number(authUser.id),
      type: reportType.refund,
    };

    dispatch(reportActions.createReport({ createData, fileData }));
  };

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        invalid,
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <FormSpy onChange={(state) => setFormState(state)} />
          <Typography className={classes.title} variant="h5">Reporte de devoluciones de materiales</Typography>
          <Typography className={classes.description} variant="body2">
            Este reporte genera una lista de las devoluciones de materiales que cumplan con los
            filtros seleccionados. El m&aacute;ximo rango de fecha a seleccionar corresponde a 62
            d&iacute;as calendario.
          </Typography>
          <Divider className={classes.diviver} />
          <Typography className={classes.subtitle} variant="h6">Filtros</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Field
                id="start_date-create"
                name="start_date"
                component={DatePickerFieldAdapter}
                label="Desde"
                disableFuture
                shouldDisableDate={disableStartDate}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Field
                id="end_date-create"
                name="end_date"
                component={DatePickerFieldAdapter}
                label="Hasta"
                disableFuture
                shouldDisableDate={disableEndDate}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                id="materials_id-create"
                name="materials_id"
                component={AutocompleteFieldAdapter}
                options={materialOptions}
                isMultiple
                filterSelectedOptions
                loading={materialLoading}
                onInputChange={_debounce(onMaterialIdChange, debounceWait)}
                textProps={{
                  label: 'Materiales',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                id="constructions_id-create"
                name="constructions_id"
                component={AutocompleteFieldAdapter}
                options={constructionOptions}
                isMultiple
                filterSelectedOptions
                loading={constructionLoading}
                onInputChange={_debounce(onConstructionIdChange, debounceWait)}
                textProps={{
                  label: 'Obra',
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              size="small"
              color="primary"
              variant="contained"
              disabled={invalid || actionLoading}
            >
              Generar Reporte
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default RefundForm;
