import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from 'components/layout/SectionTitle';
import { requestActions } from 'store/modules/request';
import { uiActions } from 'store/modules/ui';
import routes from 'common/routes';
import RequestFilters from './Filters';
import RequestsTable from './Table';

const Request = () => {
  const dispatch = useDispatch();

  const {
    currentUser,
    filters,
    pagination,
    refresh,
  } = useSelector((state) => ({
    currentUser: state.auth.user,
    filters: state.request.filters,
    pagination: state.request.pagination,
    refresh: state.request.refresh,
  }));

  const buttonProps = {
    text: 'Crear',
    to: routes.request.create.path,
  };

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.request.index.title));
  }, [dispatch]);

  useEffect(() => {
    const { is_admin, id } = currentUser;

    if (is_admin) {
      dispatch(requestActions.getRequests({ filters, pagination }));
    } else {
      const clonedFilters = {
        ...filters,
        author_id: [{ value: id }],
      };

      dispatch(requestActions.getRequests({ filters: clonedFilters, pagination }));
    }
  }, [filters, currentUser, pagination, refresh, dispatch]);

  return (
    <React.Fragment>
      <SectionTitle text="Requisiciones" buttonProps={buttonProps} />
      <RequestFilters isOpen />
      <RequestsTable />
    </React.Fragment>
  );
};

export default Request;
