import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Login method
 * @param  {String} username
 * @param  {Password} password
 * @return {Promise}
 */
const login = (username, password) => client.mutate({
  mutation: gql`
    mutation login($input: LoginInput!) {
      login(input: $input) {
        access_token
        refresh_token
        expires_in
        token_type
        user {
          id
          username
          email
          first_name
          last_name
          full_name
          role
          is_admin
          settings
        }
      }
    }
  `,
  variables: { input: { username, password } },
});

/**
 * Logout method
 * @return {Promise}
 */
const logout = () => client.mutate({
  mutation: gql`
    mutation logout {
      logout {
        status
        message
      }
    }
  `,
});

/**
 * Refresh token method
 * @param  {String} refresh_token
 * @return {Promise}
 */
const refreshToken = (refresh_token) => client.mutate({
  mutation: gql`
    mutation refreshToken($input: RefreshTokenInput) {
      refreshToken(input: $input) {
        access_token
        refresh_token
        expires_in
        token_type
      }
    }
  `,
  variables: { input: { refresh_token } },
});

export default {
  login,
  logout,
  refreshToken,
};
