import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'common/routes';

const Gate = ({
  component, Layout, allowedRoles, ...rest
}) => {
  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));

  if (allowedRoles.indexOf(user.role) >= 0) {
    return <Layout {...rest} component={component} />;
  }

  return <Redirect to={routes.home.path} />;
};

Gate.propTypes = {
  component: PropTypes.func.isRequired,
  Layout: PropTypes.func.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Gate;
