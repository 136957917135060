import React from 'react';
import localforage from 'localforage';
import _pickBy from 'lodash/pickBy';
import SnackCloseButton from 'components/shared/SnackCloseButton';

export const getLocalStorage = (key) => {
  localforage.getItem(key)
    .then((value) => value)
    .catch((error) => error);
};

export const saveLocalStorage = (key, value) => {
  localforage.setItem(key, value)
    .then((savedValue) => savedValue)
    .catch((error) => error);
};

export const removeLocalStorage = (key) => {
  localforage.removeItem(key)
    .then((value) => value)
    .catch((error) => error);
};

export const createSnack = (options) => {
  const { key = new Date().getTime() + Math.random(), variant = 'error', message = '' } = options;
  const randomString = new Date().getTime() + Math.random();

  return ({
    notification: {
      message,
      options: {
        key: `${key}-${randomString}`,
        variant,
        action: (snackKey) => <SnackCloseButton snackKey={snackKey} />,
      },
    },
    key: `${key}-${randomString}`,
  });
};

export const isSessionExpired = (message = '') => message.indexOf('Unauthenticated') >= 0 || message.indexOf('expirado') >= 0;

export const getUserFriendlyError = (ex) => {
  if ('message' in ex) {
    return ex.message.replace(/Error:/i, '');
  }

  return ex.replace(/Error:/i, '');
};

export const getFieldsToUpdate = (values, initialValues) => {
  const fieldsToUpdate = {};

  for (const key in initialValues) {
    if (Object.prototype.hasOwnProperty.call(initialValues, key) && key in values) {
      if (values[key] !== initialValues[key]) {
        fieldsToUpdate[key] = values[key];
      }
    }
  }

  return fieldsToUpdate;
};

export const cleanObject = (values) => _pickBy(values, (value) => {
  if (typeof value === 'object' && value !== null) {
    const keys = Object.keys(value);

    return keys.length > 0;
  }

  return (
    value.length > 0
    && value !== null
    && typeof value !== 'undefined'
  );
});

export const cleanString = (value) => value.replace(/\\/g, '\\\\')
  .replace(/\t/g, '\\t')
  .replace(/\n/g, '\\n')
  .replace(/\f/g, '\\f')
  .replace(/\r/g, '\\r')
  .replace(/'/g, "\\'")
  .replace(/"/g, '\\"');

export const getTotalValue = (price = 0, quantity = 0, iva = 0, discount = 0) => {
  const castValue = Number(price);
  const castQuantity = Number(quantity);
  const castIva = Number(iva);
  const castDiscount = Number(discount);

  if (castValue && castQuantity) {
    const subtotal = castValue * castQuantity;
    const totalIva = subtotal * (castIva / 100);
    const totalDiscount = subtotal * (castDiscount / 100);
    const total = Math.ceil((subtotal - totalDiscount) + totalIva);

    return Number.isNaN(total) ? 0 : total;
  }

  return 0;
};
