import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import { materialEntryActions } from 'store/modules/materialEntry';
import { userActions } from 'store/modules/user';
import { orderActions } from 'store/modules/order';
import { debounceWait } from 'common/constants';
import { filtersPagination } from 'common/filters';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const MaterialEntryFilters = ({ isOpen = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    initialValues,
    loading,
    userOptions,
    userLoading,
    orderOptions,
    orderLoading,
  } = useSelector((state) => ({
    initialValues: state.materialEntry.filters,
    loading: state.materialEntry.loading.list,
    userOptions: state.user.options,
    userLoading: state.user.loading.filter,
    orderOptions: state.order.options,
    orderLoading: state.order.loading.filter,
  }));

  const onAuthorIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      const toExclude = initialValues.author_id.length > 0
        ? initialValues.author_id.map((item) => item.value)
        : [];

      dispatch(userActions.getFilterOptions({
        value, exclude: toExclude, pagination: filtersPagination,
      }));
    }
  };

  const onOrderIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      const toExclude = initialValues.purchase_order_id.length > 0
        ? initialValues.purchase_order_id.map((item) => item.value)
        : [];

      dispatch(orderActions.getFilterOptions({
        value, exclude: toExclude, pagination: filtersPagination,
      }));
    }
  };

  const onSubmit = (values) => {
    dispatch(materialEntryActions.updateFilters(values));
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => (
        <form
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Accordion square defaultExpanded={isOpen}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Filtros</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="author_id-filter"
                    name="author_id"
                    component={AutocompleteFieldAdapter}
                    options={userOptions}
                    isMultiple
                    filterSelectedOptions
                    loading={userLoading}
                    onInputChange={_debounce(onAuthorIdChange, debounceWait)}
                    textProps={{
                      label: 'Autor',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="purchase_order_id-filter"
                    name="purchase_order_id"
                    component={AutocompleteFieldAdapter}
                    options={orderOptions}
                    isMultiple
                    filterSelectedOptions
                    loading={orderLoading}
                    onInputChange={_debounce(onOrderIdChange, debounceWait)}
                    textProps={{
                      label: 'Requisiciones',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="created_at-filter"
                    name="created_at"
                    component={DatePickerFieldAdapter}
                    label="Fecha de Creación"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button
                type="submit"
                size="small"
                color="primary"
                disabled={pristine || loading}
              >
                Filtrar
              </Button>
            </AccordionActions>
          </Accordion>
        </form>
      )}
    />
  );
};

MaterialEntryFilters.defaultProps = {
  isOpen: false,
};

MaterialEntryFilters.propTypes = {
  isOpen: PropTypes.bool,
};

export default MaterialEntryFilters;
