import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';
import createSagaMiddleware from 'redux-saga';
import authMiddleware from './middleware/auth';
import loggerMiddleware from './middleware/logger';
import errorMiddleware from './middleware/error';
import createRootReducer from './modules';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = createRootReducer();

let middleware = null;

if (process.env.NODE_ENV !== 'production') {
  middleware = (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(authMiddleware, errorMiddleware, loggerMiddleware, sagaMiddleware);

  /*
  middleware = [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    authMiddleware,
    errorMiddleware,
    loggerMiddleware,
    sagaMiddleware,
  ];
  */
} else {
  middleware = (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(authMiddleware, sagaMiddleware);
  /*
  middleware = [authMiddleware, sagaMiddleware];
  */
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

const persistor = persistStore(store);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./modules', () => store.replaceReducer(rootReducer));
}

sagaMiddleware.run(rootSaga);

export {
  store,
  persistor,
};
