import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import formatISO9075 from 'date-fns/formatISO9075';
import clsx from 'clsx';
import _get from 'lodash/get';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import CheckboxFieldAdapter from 'components/shared/CheckboxFieldAdapter';
import MaskedFieldAdapter from 'components/shared/MaskedFieldAdapter';
import SelectFieldAdapter from 'components/shared/SelectFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { constructionActions } from 'store/modules/construction';
import { materialActions } from 'store/modules/material';
import { supplierActions } from 'store/modules/supplier';
import { orderActions } from 'store/modules/order';
import { requestActions } from 'store/modules/request';
import { userActions } from 'store/modules/user';
import { uiActions } from 'store/modules/ui';
import request from 'graphql/request';
import { validateOrder } from 'common/formValidations';
import { createSnack, getTotalValue } from 'common/utils';
import { debounceWait, operationType } from 'common/constants';
import routes from 'common/routes';
import { filtersPagination } from 'common/filters';
import { positiveNumber } from 'common/validations';
import ImportMaterialsDialog from './ImportMaterialsDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(3),
    '&.bottom-spacing': {
      marginBottom: theme.spacing(3),
    },
  },
  flex: {
    display: 'flex',
  },
  buttonGrid: {
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '100%',
      },
    },
  },
  import: {
    textAlign: 'right',
  },
  deleteWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '0 !important',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  alertRoot: {
    borderRadius: 0,
  },
}));

const initialValues = {
  construction_id: '',
  supplier_id: '',
  approved_by_id: '',
  delivery_date: null,
  delivery_address: '',
  payment_method: '',
  material_requests_id: [],
  send_to_supplier: false,
  materials: [],
  iva: 19,
  retf: 0,
  ica: 0,
  totals: {
    partial: 0,
    iva: 0,
    retf: 0,
    ica: 0,
    final: 0,
  },
  comments: '',
  notes: '',
};

const CreateOrder = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const addMaterialButton = useRef();
  const [isImporting, setIsImporting] = useState(false);
  const [calculatedTotals, setCalculatedTotals] = useState(false);

  const {
    constructionOptions,
    constructionLoading,
    materialOptions,
    materialLoading,
    requestOptions,
    requestLoading,
    supplierOptions,
    supplierLoading,
    loading,
    canApprove,
    transactionLoading,
    userId,
  } = useSelector((state) => ({
    constructionOptions: state.construction.options,
    constructionLoading: state.construction.loading.filter,
    materialOptions: state.material.options,
    materialLoading: state.material.loading.filter,
    requestOptions: state.request.options,
    requestLoading: state.request.loading.filter,
    supplierOptions: state.supplier.options,
    supplierLoading: state.supplier.loading.filter,
    loading: state.order.loading.list,
    canApprove: state.user.canApprove,
    transactionLoading: state.transaction.loading,
    userId: state.auth.user.id,
  }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.order.create.title));
  }, [dispatch]);

  useEffect(() => {
    if (canApprove.length === 0) {
      dispatch(userActions.getUsersWhoCanApprove({
        filters: { can_approve: true },
        pagination: filtersPagination,
      }));
    }
  }, [canApprove.length, dispatch]);

  const canApproveOptions = canApprove.map((userCanApprove) => ({
    value: userCanApprove.id, label: userCanApprove.full_name,
  }));

  const required = (value) => (value ? undefined : 'Este campo es requerido');

  const isPositiveNumber = (value) => (positiveNumber(value) ? undefined : 'Este campo solo admite números positivos');

  const onConstructionIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(constructionActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onMaterialIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(materialActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onSupplierIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(supplierActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onRequestIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      const toExclude = initialValues.material_requests_id.length > 0
        ? initialValues.id.map((item) => item.value)
        : [];

      dispatch(requestActions.getFilterOptions({
        value, exclude: toExclude, pagination: filtersPagination,
      }));
    }
  };

  const openConfirmImportMaterialsDialog = () => {
    dispatch(uiActions.toggleImportMaterialsDialog(true));
  };

  const getMaterialTransactions = (requests) => requests.reduce(
    (acc, current) => acc.concat(current.material_transactions), [],
  );

  const getMaterialsToImport = (materialTransactions) => {
    const materials = materialTransactions.map((transaction) => ({
      total_value: 0,
      item: {
        value: transaction.material_id.id,
        label: transaction.material_id.name,
        unit: transaction.material_id.unit,
        quantity: transaction.quantity,
        price: transaction.material_id.value,
        discount: transaction.material_id.discount,
        iva: transaction.material_id.iva,
      },
    }));

    return materials;
  };

  const importMaterials = async (args, state, tools) => {
    setIsImporting(true);
    const { formState: { values: { material_requests_id: selectedRequests } } } = state;
    const selectedRequestsId = selectedRequests.map((selectedRequest) => selectedRequest.value);

    request.getRequestsToImport({
      filters: { id: selectedRequestsId },
      pagination: { first: 20, page: 1 },
    }).then(({ data: { materialRequests: { data } } }) => {
      const materialTransactions = getMaterialTransactions(data);
      const materialsToImport = getMaterialsToImport(materialTransactions);
      tools.changeValue(state, 'materials', () => materialsToImport);
      // Trigger click on add material button  to show the newly added materials
      addMaterialButton.current.click();
    }).then(() => {
      setIsImporting(false);
    }).catch((ex) => {
      setIsImporting(false);
      const key = 'materialimportMaterialFromRequestError';
      const snack = createSnack({ key, variant: 'error', ex });

      dispatch(uiActions.enqueueSnackbar(snack));
    });
  };

  const calculateTotals = (args, state, tools) => {
    const {
      formState: {
        values: {
          materials, iva, retf, ica,
        },
      },
    } = state;

    let total = 0;

    for (let index = 0; index < materials.length; index += 1) {
      if ('item' in materials[index]) {
        const {
          item: {
            price, iva: itemIva, discount, quantity,
          },
        } = materials[index];

        const totalValue = getTotalValue(price, quantity, itemIva, discount);

        total += totalValue;
        tools.changeValue(state, `materials[${index}].total_value`, () => totalValue);
      }
    }

    const orderIva = Math.ceil(total * (iva / 100));
    const orderRetf = Math.ceil(total * (retf / 100));
    const orderIca = Math.ceil(total * (ica / 100));
    const orderTotalValue = total + orderIva + orderRetf + orderIca;

    tools.changeValue(state, 'totals.partial', () => total);
    tools.changeValue(state, 'totals.iva', () => orderIva);
    tools.changeValue(state, 'totals.retf', () => orderRetf);
    tools.changeValue(state, 'totals.ica', () => orderIca);
    tools.changeValue(state, 'totals.final', () => orderTotalValue);

    if (!calculatedTotals) {
      setCalculatedTotals(true);
    }
  };

  const resetAll = (args, state) => {
    const form = args[0];
    const { formState: { values: { materials } } } = state;

    for (let i = 0; i < materials.length; i += 1) {
      form.mutators.pop('materials');
    }

    form.restart();
  };

  const onSubmit = (values) => {
    const data = {
      order: {
        author_id: Number(userId),
        construction_id: Number(values.construction_id.value),
        supplier_id: Number(values.supplier_id.value),
        approved_by_id: Number(values.approved_by_id),
        delivery_date: formatISO9075(values.delivery_date),
        delivery_address: values.delivery_address,
        payment_method: values.payment_method,
        material_requests_id: `[${values.material_requests_id.map((option) => option.value).join()}]`,
        iva: values.iva,
        retf: values.retf,
        ica: values.ica,
        comments: values.comments,
        notes: values.notes,
      },
      sendToSupplier: values.send_to_supplier,
      transactions: values.materials.map((material) => {
        const {
          item: {
            value, price, discount, iva, quantity,
          },
          total_value: totalValue,
        } = material;

        return {
          material_id: Number(value),
          type: operationType.order,
          quantity: typeof quantity === 'number' ? quantity : Number(quantity),
          value: Number(price),
          discount: Number(discount),
          iva: Number(iva),
          total_value: (totalValue && totalValue > 0)
            ? totalValue
            : getTotalValue(price, quantity, iva, discount),
        };
      }),
    };

    dispatch(orderActions.createOrder(data));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Crear Orden de Compra" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validateOrder}
          onSubmit={onSubmit}
          mutators={{
            importMaterials,
            calculateTotals,
            resetAll,
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            invalid,
            pristine,
            values,
            form,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading || transactionLoading || isImporting} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="supplier_id-create"
                    name="supplier_id"
                    component={AutocompleteFieldAdapter}
                    options={supplierOptions}
                    isMultiple={false}
                    filterSelectedOptions={false}
                    loading={supplierLoading}
                    onInputChange={_debounce(onSupplierIdChange, debounceWait)}
                    textProps={{
                      label: 'Proveedor',
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="construction_id-create"
                    name="construction_id"
                    component={AutocompleteFieldAdapter}
                    options={constructionOptions}
                    isMultiple={false}
                    filterSelectedOptions={false}
                    loading={constructionLoading}
                    onInputChange={_debounce(onConstructionIdChange, debounceWait)}
                    textProps={{
                      label: 'Obra',
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="material_requests_id-create"
                    name="material_requests_id"
                    component={AutocompleteFieldAdapter}
                    options={requestOptions}
                    isMultiple
                    filterSelectedOptions
                    loading={requestLoading}
                    onInputChange={_debounce(onRequestIdChange, debounceWait)}
                    textProps={{
                      label: 'Requisiciones',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="payment_method-create"
                    name="payment_method"
                    component={TextFieldAdapter}
                    label="Forma de Pago"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="approved_by_id-create"
                    name="approved_by_id"
                    component={SelectFieldAdapter}
                    options={canApproveOptions}
                    label="Aprobada Por"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="delivery_address-create"
                    name="delivery_address"
                    component={TextFieldAdapter}
                    label="Lugar de Entrega"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    id="delivery_date-create"
                    name="delivery_date"
                    component={DatePickerFieldAdapter}
                    label="Fecha de Entrega"
                    disablePast
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.flex}>
                  <Field
                    id="send_to_supplier-create"
                    name="send_to_supplier"
                    component={CheckboxFieldAdapter}
                    checked={_get(values, 'send_to_supplier', false)}
                    label="Enviar a Proveedor"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={clsx(classes.grid, classes.buttonGrid, 'bottom-spacing')}>
                <Grid item xs={12} sm={6}>
                  <Button
                    ref={addMaterialButton}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => push('materials', undefined)}
                  >
                    Agregar Material
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.import}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ImportExportIcon />}
                    disabled={values.material_requests_id.length === 0}
                    onClick={openConfirmImportMaterialsDialog}
                  >
                    Importar Materiales
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={clsx(classes.grid, 'bottom-spacing')}>
                <Grid item xs={12}>
                  <FieldArray name="materials">
                    {({ fields }) => fields.map((name, index) => (
                      <Grid container spacing={3} key={name}>
                        <Grid item xs={12} sm={12} md={3}>
                          <Field
                            id={`material_id-create-${index}`}
                            name={`${name}.item`}
                            component={AutocompleteFieldAdapter}
                            options={materialOptions}
                            isMultiple={false}
                            filterSelectedOptions={false}
                            loading={materialLoading}
                            onInputChange={_debounce(onMaterialIdChange, debounceWait)}
                            required
                            validate={required}
                            textProps={{
                              label: 'Material',
                            }}
                            subscription={{ active: true, value: true }}
                          />
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <Field
                            name={`${name}.item.unit`}
                            component={TextFieldAdapter}
                            label="Unidad"
                            disabled
                            fullWidth
                            subscription={{ value: true }}
                          />
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <Field
                            name={`${name}.item.quantity`}
                            component={TextFieldAdapter}
                            label="CANT."
                            required
                            validate={isPositiveNumber}
                            fullWidth
                            subscription={{
                              value: true,
                              invalid: true,
                              touched: true,
                              error: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Field
                            name={`${name}.item.price`}
                            component={MaskedFieldAdapter}
                            label="Valor Unitario"
                            required
                            validate={isPositiveNumber}
                            fullWidth
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            subscription={{
                              value: true,
                              invalid: true,
                              touched: true,
                              error: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <Field
                            name={`${name}.item.discount`}
                            component={TextFieldAdapter}
                            label="DTO."
                            required
                            validate={isPositiveNumber}
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            subscription={{
                              value: true,
                              invalid: true,
                              touched: true,
                              error: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <Field
                            name={`${name}.item.iva`}
                            component={TextFieldAdapter}
                            label="IVA"
                            required
                            validate={isPositiveNumber}
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            subscription={{
                              value: true,
                              invalid: true,
                              touched: true,
                              error: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Field
                            name={`${name}.total_value`}
                            component={MaskedFieldAdapter}
                            defaultValue={0}
                            label="Valor Total"
                            disabled
                            fullWidth
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            subscription={{ value: true }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1} className={classes.deleteWrapper}>
                          <IconButton
                            color="secondary"
                            onClick={() => fields.remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </FieldArray>
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={4}>
                  <Field
                    name="iva"
                    component={TextFieldAdapter}
                    label="IVA"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    subscription={{
                      value: true,
                      invalid: true,
                      touched: true,
                      error: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    name="retf"
                    component={TextFieldAdapter}
                    label="RETF"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    subscription={{
                      value: true,
                      invalid: true,
                      touched: true,
                      error: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    name="ica"
                    component={TextFieldAdapter}
                    label="ICA"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    subscription={{
                      value: true,
                      invalid: true,
                      touched: true,
                      error: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={6} md={3}>
                  <Field
                    name="totals.partial"
                    component={MaskedFieldAdapter}
                    label="Sub Total"
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    subscription={{ value: true }}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <Field
                    name="totals.iva"
                    component={MaskedFieldAdapter}
                    label="IVA"
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    subscription={{ value: true }}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <Field
                    name="totals.retf"
                    component={MaskedFieldAdapter}
                    label="RETF"
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    subscription={{ value: true }}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <Field
                    name="totals.ica"
                    component={MaskedFieldAdapter}
                    label="ICA"
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    subscription={{ value: true }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    name="totals.final"
                    component={MaskedFieldAdapter}
                    label="Valor Total"
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    subscription={{ value: true }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={values.materials.length === 0}
                    onClick={form.mutators.calculateTotals}
                  >
                    Calcular Totales
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="comments-create"
                    name="comments"
                    component={TextFieldAdapter}
                    label="Observaciones"
                    multiline
                    maxRows="4"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="notes-create"
                    name="notes"
                    component={TextFieldAdapter}
                    label="Notas"
                    multiline
                    maxRows="4"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.mutators.resetAll(form);
                    }}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={8} sm={6} className={classes.submit}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.order.index.path}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      invalid || pristine || !calculatedTotals || loading || transactionLoading
                    }
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
              <ImportMaterialsDialog
                requestIds={values.material_requests_id}
                onConfirm={form.mutators.importMaterials}
              />
            </form>
          )}
        />
      </Paper>
      <Alert severity="info" variant="filled" classes={{ root: classes.alertRoot }}>
        Recuerde calcular los totales antes de crear la orden de compra
      </Alert>
    </React.Fragment>
  );
};

export default CreateOrder;
