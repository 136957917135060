import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import formatISO9075 from 'date-fns/formatISO9075';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import clsx from 'clsx';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import MaskedFieldAdapter from 'components/shared/MaskedFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { materialRefundActions } from 'store/modules/materialRefund';
import { constructionActions } from 'store/modules/construction';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { getTotalValue } from 'common/utils';
import { debounceWait, operationType } from 'common/constants';
import routes from 'common/routes';
import { filtersPagination } from 'common/filters';
import { hasValue, positiveNumber } from 'common/validations';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(3),
    '&.bottom-spacing': {
      marginBottom: theme.spacing(3),
    },
  },
  buttonGrid: {
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '100%',
      },
    },
  },
  deleteWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '0 !important',
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  alertRoot: {
    borderRadius: 0,
  },
}));

const initialValues = {
  created_at: Date.now(),
  construction_id: null,
  materials: [],
  comments: '',
};

const CreateMaterialRefund = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [calculatedTotals, setCalculatedTotals] = useState(false);

  const {
    constructionOptions,
    constructionLoading,
    materialOptions,
    materialLoading,
    loading,
    userId,
  } = useSelector((state) => ({
    constructionOptions: state.construction.options,
    constructionLoading: state.construction.loading.filter,
    materialOptions: state.material.options,
    materialLoading: state.material.loading.filter,
    loading: state.materialRefund.loading.list,
    userId: state.auth.user.id,
  }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.materialRefund.create.title));
  }, [dispatch]);

  const required = (value) => (value ? undefined : 'Este campo es requerido');

  const isPositiveNumber = (value) => (positiveNumber(value) ? undefined : 'Este campo solo admite números positivos');

  const validate = (values) => {
    const errors = {};

    if (!hasValue(values.construction_id)) {
      errors.purchase_orders_id = 'Este campo no puede estar vació';
    }

    if (!hasValue(values.materials)) {
      errors.materials = 'Este campo no puede estar vació';
    }

    return errors;
  };

  const getSubmitData = (values) => {
    const { materials } = values;
    const movements = [];
    const materialsToUpdate = [];

    for (let i = 0; i < materials.length; i += 1) {
      const {
        item: {
          quantity, price, iva, value,
        },
      } = materials[i];

      const castId = Number(value);
      const castQuantity = Number(quantity);
      const castPrice = Number(price);
      const totalValue = getTotalValue(price, quantity, iva);

      movements.push({
        material_id: castId,
        type: operationType.refund,
        quantity: castQuantity,
        value: castPrice,
        total_value: totalValue,
        created_at: formatISO9075(values.created_at),
        updated_at: formatISO9075(values.created_at),
      });

      materialsToUpdate.push({
        id: castId,
        quantity: castQuantity,
      });
    }

    return { movements, materialsToUpdate };
  };

  const onConstructionIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(constructionActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onMaterialIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(materialActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const calculateTotals = (args, state, tools) => {
    const { formState: { values: { materials } } } = state;

    for (let index = 0; index < materials.length; index += 1) {
      if ('item' in materials[index]) {
        const { item: { price, quantity, iva } } = materials[index];

        tools.changeValue(state, `materials[${index}].total_value`, () => getTotalValue(price, quantity, iva));
      }
    }

    if (!calculatedTotals) {
      setCalculatedTotals(true);
    }
  };

  const isOptionDisabled = (option) => option.price === 0;

  const resetAll = (args, state) => {
    const form = args[0];
    const { formState: { values: { materials } } } = state;

    for (let i = 0; i < materials.length; i += 1) {
      form.mutators.pop('materials');
    }

    form.restart();
  };

  const onSubmit = (values) => {
    const submitData = getSubmitData(values);

    const data = {
      refund: {
        author_id: Number(userId),
        construction_id: Number(_get(values, 'construction_id.value', null)),
        created_at: formatISO9075(values.created_at),
        comments: values.comments,
      },
      movements: submitData.movements,
      materialsToUpdate: submitData.materialsToUpdate,
    };

    dispatch(materialRefundActions.createMaterialRefund(data));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Crear Devolución de Materiales" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          mutators={{
            calculateTotals,
            resetAll,
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            invalid,
            pristine,
            values,
            form,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="construction_id-create"
                    name="construction_id"
                    component={AutocompleteFieldAdapter}
                    options={constructionOptions}
                    isMultiple={false}
                    filterSelectedOptions={false}
                    loading={constructionLoading}
                    onInputChange={_debounce(onConstructionIdChange, debounceWait)}
                    textProps={{
                      label: 'Obra',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="created_at-create"
                    name="created_at"
                    component={DatePickerFieldAdapter}
                    label="Fecha de creación"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={clsx(classes.grid, classes.buttonGrid, 'bottom-spacing')}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => push('materials', undefined)}
                  >
                    Agregar Material
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={clsx(classes.grid, 'bottom-spacing')}>
                <Grid item xs={12}>
                  <FieldArray name="materials">
                    {({ fields }) => fields.map((name, index) => (
                      <Grid container spacing={3} key={name}>
                        <Grid item xs={12} sm={12} md={4}>
                          <Field
                            id={`material_id-create-${index}`}
                            name={`${name}.item`}
                            component={AutocompleteFieldAdapter}
                            options={materialOptions}
                            isMultiple={false}
                            filterSelectedOptions={false}
                            loading={materialLoading}
                            onInputChange={_debounce(onMaterialIdChange, debounceWait)}
                            getOptionDisabled={isOptionDisabled}
                            required
                            validate={required}
                            textProps={{
                              label: 'Material',
                            }}
                            subscription={{ active: true, value: true }}
                          />
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <Field
                            name={`${name}.item.unit`}
                            component={TextFieldAdapter}
                            label="Unidad"
                            disabled
                            fullWidth
                            subscription={{ value: true }}
                          />
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <Field
                            name={`${name}.item.quantity`}
                            component={TextFieldAdapter}
                            label="CANT."
                            required
                            validate={isPositiveNumber}
                            fullWidth
                            subscription={{
                              value: true,
                              invalid: true,
                              touched: true,
                              error: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Field
                            name={`${name}.item.price`}
                            component={MaskedFieldAdapter}
                            label="Valor Unitario"
                            required
                            validate={isPositiveNumber}
                            fullWidth
                            disabled
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            subscription={{
                              value: true,
                              invalid: true,
                              touched: true,
                              error: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={10} sm={11} md={2}>
                          <Field
                            name={`${name}.total_value`}
                            component={MaskedFieldAdapter}
                            defaultValue={0}
                            label="Valor Total"
                            disabled
                            fullWidth
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            subscription={{ value: true }}
                          />
                        </Grid>
                        <Grid item xs={2} sm={1} md={1} className={classes.deleteWrapper}>
                          <IconButton
                            color="secondary"
                            onClick={() => fields.remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </FieldArray>
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={values.materials.length === 0}
                    onClick={form.mutators.calculateTotals}
                  >
                    Calcular Totales
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12}>
                  <Field
                    id="comments-create"
                    name="comments"
                    component={TextFieldAdapter}
                    label="Comentarios"
                    multiline
                    maxRows="4"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.mutators.resetAll(form);
                    }}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={8} sm={6} className={classes.submit}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.materialRefund.index.path}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      invalid || pristine || loading || !calculatedTotals
                    }
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
      <Alert severity="info" variant="filled" className={classes.alert} classes={{ root: classes.alertRoot }}>
        Tenga en cuenta que una vez creada, la devoluci&oacute;n de
        materiales no puede ser modificada, ni eliminada
      </Alert>
      <Alert severity="info" variant="filled" classes={{ root: classes.alertRoot }}>
        Recuerde calcular los totales antes de crear la devoluci&oacute;n de materiales
      </Alert>
    </React.Fragment>
  );
};

export default CreateMaterialRefund;
