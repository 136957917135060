const getValuesArray = (array) => array.map((item) => item.value);

export const filtersPagination = { first: 10, page: 1 };

export const getRequestFilters = (filters) => ({
  ...filters,
  id: filters.id.length > 0 ? getValuesArray(filters.id) : [],
  author_id: filters.author_id.length > 0 ? getValuesArray(filters.author_id) : [],
  construction_id: filters.construction_id.length > 0
    ? getValuesArray(filters.construction_id) : [],
});

export const getOrderFilters = (filters) => ({
  ...filters,
  id: filters.id.length > 0 ? getValuesArray(filters.id) : [],
  author_id: filters.author_id.length > 0 ? getValuesArray(filters.author_id) : [],
  construction_id: filters.construction_id.length > 0
    ? getValuesArray(filters.construction_id) : [],
  material_requests_id: filters.material_requests_id.length > 0
    ? getValuesArray(filters.material_requests_id) : [],
  supplier_id: filters.supplier_id.length > 0 ? getValuesArray(filters.supplier_id) : [],
});

export const getMaterialEntryFilters = (filters) => ({
  author_id: filters.author_id.length > 0 ? getValuesArray(filters.author_id) : [],
  purchase_order_id: filters.purchase_order_id.length > 0
    ? getValuesArray(filters.purchase_order_id) : [],
});

export const getMaterialRefundFilters = (filters) => ({
  author_id: filters.author_id.length > 0 ? getValuesArray(filters.author_id) : [],
  construction_id: filters.construction_id.length > 0
    ? getValuesArray(filters.construction_id) : [],
});

export const getMaterialExitFilters = (filters) => ({
  author_id: filters.author_id.length > 0 ? getValuesArray(filters.author_id) : [],
  construction_id: filters.construction_id.length > 0
    ? getValuesArray(filters.construction_id) : [],
  requested_by_id: filters.requested_by_id.length > 0
    ? getValuesArray(filters.requested_by_id) : [],
});

export const getDefaultFilters = (filters) => ({
  ...filters,
  id: filters.id.length > 0 ? getValuesArray(filters.id) : [],
});
