import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Gate from './Gate';

const Authenticated = (props) => {
  const { isLoggedIn, error } = useSelector((state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error,
  }));

  const {
    layout, component, ...rest
  } = props;

  if (isLoggedIn && !error) {
    return (
      <Gate {...rest} component={component} Layout={layout} />
    );
  }

  return <Redirect to="/" />;
};

Authenticated.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
};

export default Authenticated;
