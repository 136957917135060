import { createAction, createReducer } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

// Action creators

// Create material transactions
const insertTransactions = createAction('transaction/insertTransactions');
const insertTransactionsSuccess = createAction('transaction/insertTransactionsSuccess');
const insertTransactionsError = createAction('transaction/insertTransactionsError');

export const transactionActions = {
  insertTransactions,
  insertTransactionsSuccess,
  insertTransactionsError,
};

// Initial state
const initialState = {
  loading: false,
  success: false,
  error: null,
};

// Reducer
export default createReducer(initialState, {
  // Insert material transactions reducer
  [insertTransactions]: (state) => {
    state.success = false;
    state.error = null;
  },
  [insertTransactionsSuccess]: (state) => {
    state.success = true;
  },
  [insertTransactionsError]: (state, action) => {
    state.error = action.payload;
  },
  // Purge reducer
  [PURGE]: () => initialState,
});
