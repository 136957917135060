import { takeLatest, put } from 'redux-saga/effects';
import { supplierActions } from 'store/modules/supplier';
import { uiActions } from 'store/modules/ui';
import { getDefaultFilters } from 'common/filters';
import { createSnack, getUserFriendlyError } from 'common/utils';
import supplier from 'graphql/supplier';

/**
 * Get filter options saga
 *
 * @param {*} action
 */
function* getFilterOptions(action) {
  try {
    const params = 'exclude' in action.payload ? action.payload : { ...action.payload, exclude: [] };
    const response = yield supplier.getFilterOptions(params);
    const { data: { suppliers: { data } } } = response;
    const options = data.map((option) => ({ value: option.id, label: option.name }));

    yield put(supplierActions.getFilterOptionsSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(supplierActions.getFilterOptionsError(message));
  }
}

/**
 * Get suppliers saga
 *
 * @param {*} action
 */
function* getSuppliers(action) {
  try {
    const { payload } = action;
    const filters = getDefaultFilters(payload.filters);
    const response = yield supplier.getSuppliers({ filters, pagination: payload.pagination });
    const { data: { suppliers: { data, paginatorInfo } } } = response;

    yield put(supplierActions.getSuppliersSuccess(data));
    yield put(supplierActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(supplierActions.getSuppliersError(message));
  }
}

/**
 * Create supplier saga
 *
 * @param {*} action
 */
function* createSupplier(action) {
  try {
    yield supplier.createSupplier(action.payload);

    const snack = createSnack({
      key: 'supplier-created-notification-success',
      variant: 'success',
      message: 'Proveedor creado con éxito',
    });

    yield put(supplierActions.createSupplierSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(supplierActions.createSupplierError(message));
  }
}

/**
 * Delete suppliers saga
 *
 * @param {*} action
 */
function* deleteSuppliers(action) {
  try {
    const {
      data: { deleteSuppliers: deletedSuppliers },
    } = yield supplier.deleteSuppliers(action.payload);

    const snack = createSnack({
      key: 'delete-suppliers-notification-success',
      variant: 'success',
      message: `${deletedSuppliers.length > 1 ? 'Proveedores eliminados' : 'Proveedor eliminado'} con éxito`,
    });

    yield put(supplierActions.deleteSuppliersSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(supplierActions.deleteSuppliersError(message));
  }
}

/**
 * Update supplier saga
 *
 * @param {*} action
 */
function* updateSupplier(action) {
  try {
    const {
      data: { updateSupplier: updatedSupplier },
    } = yield supplier.updateSupplier(action.payload);

    const snack = createSnack({
      key: 'update-supplier-notification-success',
      variant: 'success',
      message: 'El proveedor fue actualizado con éxito',
    });

    yield put(supplierActions.updateSupplierSuccess(updatedSupplier));
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(supplierActions.updateSupplierError(message));
  }
}

/**
 * Watch supplier
 *
 * @export
 */
export default function* watchSupplier() {
  yield takeLatest(supplierActions.getFilterOptions, getFilterOptions);
  yield takeLatest(supplierActions.getSuppliers, getSuppliers);
  yield takeLatest(supplierActions.createSupplier, createSupplier);
  yield takeLatest(supplierActions.deleteSuppliers, deleteSuppliers);
  yield takeLatest(supplierActions.updateSupplier, updateSupplier);
}
