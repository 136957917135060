import { takeLatest, put } from 'redux-saga/effects';
import { transactionActions } from 'store/modules/transaction';
import { getUserFriendlyError } from 'common/utils';
import transaction from 'graphql/transaction';

/**
 * Insert transactions saga
 *
 * @param {*} action
 */
function* insertTransactions(action) {
  try {
    const { payload } = action;
    yield transaction.insert(payload);
    yield put(transactionActions.insertTransactionsSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(transactionActions.insertTransactionsError(message));
  }
}

/**
 * Watch transaction
 *
 * @export
 */
export default function* watchTransaction() {
  yield takeLatest(transactionActions.insertTransactions, insertTransactions);
}
