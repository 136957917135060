import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Get filter options method
 * @param  {Object} payload
 * @return {Promise}
 */
const getFilterOptions = (payload) => {
  const exclude = payload.exclude.length > 0
    ? `{ column: ID, operator: NOT_IN, value: [ ${payload.exclude} ] }`
    : '';

  return (
    client.query({
      query: gql`
        query users($first: Int!, $page: Int!) {
          users(
            first: $first
            page: $page
            where: {
              AND: [
                ${exclude}
                {
                  AND: [
                    {
                      OR: [
                        { column: FIRST_NAME, operator: LIKE, value: "%${payload.value}%" }
                        { column: LAST_NAME, operator: LIKE, value: "%${payload.value}%" }
                      ]
                    }
                  ]
                }
              ]
            }
            orderBy: [{
              column: FIRST_NAME
              order: ASC
            }]
          ) {
            data {
              id
              full_name
            }
          }
        }
      `,
      variables: {
        first: payload.pagination.first,
        page: payload.pagination.page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Get users method
 * @param  {Object} payload
 * @return {Promise}
 */
const getUsers = (payload) => {
  const { filters, pagination: { first, page } } = payload;

  const role = filters.role ? `{ column: ROLE, value: "${filters.role}" }` : '';
  const username = filters.username ? `{ column: USERNAME, operator: LIKE, value: "${filters.username}%"}` : '';
  const email = filters.email ? `{ column: EMAIL, operator: LIKE, value: "${filters.email}%"}` : '';
  const first_name = filters.first_name ? `{ column: FIRST_NAME, operator: LIKE, value: "${filters.first_name}%"}` : '';
  const last_name = filters.last_name ? `{ column: LAST_NAME, operator: LIKE, value: "${filters.last_name}%"}` : '';
  const canApprove = typeof filters.can_approve === 'boolean' ? `can_approve: ${filters.can_approve}` : '';

  const where = `
    where: {
      AND: [
        ${role}
        ${username}
        ${email}
        ${first_name}
        ${last_name}
      ]
    }
  `;

  return (
    client.query({
      query: gql`
        query users($first: Int!, $page: Int!) {
          users(
            first: $first
            page: $page
            ${where}
            ${canApprove}
            orderBy: [{
              column: FIRST_NAME
              order: ASC
            }]
          ) {
            paginatorInfo {
              total
              hasMorePages
              lastPage
            }
            data {
              id
              username
              email
              first_name
              last_name
              full_name
              role
              settings
            }
          }
        }
      `,
      variables: {
        first,
        page,
      },
      fetchPolicy: 'network-only',
    })
  );
};

/**
 * Create user method
 * @param  {Object} payload
 * @return {Promise}
 */
const createUser = (payload) => client.mutate({
  mutation: gql`
    mutation createUser($input: CreateUserInput!) {
      createUser(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Delete users method
 * @param  {String} ids
 * @return {Promise}
 */
const deleteUsers = (ids) => client.mutate({
  mutation: gql`
    mutation deleteUsers($id: [ID!]!) {
      deleteUsers(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Update user method
 * @param  {Object} payload
 * @return {Promise}
 */
const updateUser = (payload) => client.mutate({
  mutation: gql`
    mutation updateUser($id: ID!, $input: UpdateUserInput!) {
      updateUser(id: $id, input: $input) {
        id
        username
        email
        first_name
        last_name
        full_name
        role
        settings
        is_admin
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

export default {
  getFilterOptions,
  getUsers,
  createUser,
  deleteUsers,
  updateUser,
};
