import { takeLatest, put } from 'redux-saga/effects';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { getDefaultFilters } from 'common/filters';
import { createSnack, getUserFriendlyError } from 'common/utils';
import material from 'graphql/material';

/**
 * Get filter options saga
 *
 * @param {*} action
 */
function* getFilterOptions(action) {
  try {
    const params = 'exclude' in action.payload ? action.payload : { ...action.payload, exclude: [] };
    const response = yield material.getFilterOptions(params);
    const { data: { materials: { data } } } = response;
    const options = data.map((option) => ({
      value: option.id,
      label: option.name,
      unit: option.unit,
      quantity: option.quantity,
      price: option.value,
      discount: option.discount,
      iva: option.iva,
    }));

    yield put(materialActions.getFilterOptionsSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialActions.getFilterOptionsError(message));
  }
}

/**
 * Get materials saga
 *
 * @param {*} action
 */
function* getMaterials(action) {
  try {
    const { payload } = action;
    const filters = getDefaultFilters(payload.filters);
    const response = yield material.getMaterials({ filters, pagination: payload.pagination });
    const { data: { materials: { data, paginatorInfo } } } = response;

    yield put(materialActions.getMaterialsSuccess(data));
    yield put(materialActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialActions.getMaterialsError(message));
  }
}

/**
 * Create material saga
 *
 * @param {*} action
 */
function* createMaterial(action) {
  try {
    yield material.createMaterial(action.payload);

    const snack = createSnack({
      key: 'material-created-notification-success',
      variant: 'success',
      message: 'Material creado con éxito',
    });

    yield put(materialActions.createMaterialSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialActions.createMaterialError(message));
  }
}

/**
 * Import materials saga
 *
 * @param {*} action
 */
function* importMaterials(action) {
  try {
    const {
      data: { uploadMaterialList: { processed, errors } },
    } = yield material.importMaterials(action.payload);

    if (processed > 0) {
      const snack = createSnack({
        key: 'materials-imported-notification-success',
        variant: 'success',
        message: 'Materiales importados con éxito',
      });

      yield put(materialActions.importMaterialsSuccess());
      yield put(uiActions.enqueueSnackbar(snack));
    } else {
      const ex = {
        message: 'Se presentaron algunos errores al importar los materiales',
        errors,
      };

      yield put(materialActions.importMaterialsError(ex));
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialActions.importMaterialsError(message));
  }
}

/**
 * Delete materials saga
 *
 * @param {*} action
 */
function* deleteMaterials(action) {
  try {
    const {
      data: { deleteMaterials: deletedMaterials },
    } = yield material.deleteMaterials(action.payload);

    const snack = createSnack({
      key: 'delete-materials-notification-success',
      variant: 'success',
      message: `${deletedMaterials.length > 1 ? 'Materiales eliminados' : 'Material eliminado'} con éxito`,
    });

    yield put(materialActions.deleteMaterialsSuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialActions.deleteMaterialsError(message));
  }
}

/**
 * Update material saga
 *
 * @param {*} action
 */
function* updateMaterial(action) {
  try {
    const {
      data: { updateMaterial: updatedMaterial },
    } = yield material.updateMaterial(action.payload);

    const snack = createSnack({
      key: 'update-material-notification-success',
      variant: 'success',
      message: 'El material fue actualizado con éxito',
    });

    yield put(materialActions.updateMaterialSuccess(updatedMaterial));
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialActions.updateMaterialError(message));
  }
}

/**
 * Update material by movement saga
 *
 * @param {*} action
 */
function* updateMaterialByMovement(action) {
  try {
    const {
      data: { updateMaterialByMovement: updatedMaterials },
    } = yield material.updateMaterialByMovement(action.payload);
    yield put(materialActions.updateMaterialByMovementSuccess(updatedMaterials));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialActions.updateMaterialByMovementError(message));
  }
}

/**
 * Watch material
 *
 * @export
 */
export default function* watchMaterial() {
  yield takeLatest(materialActions.getFilterOptions, getFilterOptions);
  yield takeLatest(materialActions.getMaterials, getMaterials);
  yield takeLatest(materialActions.createMaterial, createMaterial);
  yield takeLatest(materialActions.importMaterials, importMaterials);
  yield takeLatest(materialActions.deleteMaterials, deleteMaterials);
  yield takeLatest(materialActions.updateMaterial, updateMaterial);
  yield takeLatest(materialActions.updateMaterialByMovement, updateMaterialByMovement);
}
