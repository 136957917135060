import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableBodyRow } from 'material-table';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { materialEntryActions } from 'store/modules/materialEntry';
import tableIcons from 'common/tableIcons';
import TablePaginationActions from 'components/shared/TablePaginationActions';
import TableLoadingIndicator from 'components/shared/TableLoadingIndicator';
import TableDetail from './TableDetail';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(8),
    },
    '& .MuiTableFooter-root .MuiTablePagination-root': {
      display: 'block',
      boxSizing: 'border-box',
      clear: 'both',
    },
  },
  paginationToolbar: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  paginationSpacer: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 auto',
    },
  },
}));

const MaterialEntryTable = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    materialEntries,
    pagination,
    paginatorInfo,
    loading,
  } = useSelector((state) => ({
    materialEntries: state.materialEntry.materialEntries,
    pagination: state.materialEntry.pagination,
    paginatorInfo: state.materialEntry.paginatorInfo,
    loading: state.materialEntry.loading.list,
  }));

  const data = materialEntries.map((materialEntry) => ({
    ...materialEntry,
    author_id: materialEntry.author_id.full_name,
    purchase_order_id: materialEntry.purchase_order_id.id,
    supplier_id: materialEntry.purchase_order_id.supplier_id.name,
    created_at: format(parseISO(materialEntry.created_at), 'dd/MM/yyyy'),
  }));

  const getDisplayedRows = () => {
    const firstItem = pagination.page === 1 ? 1 : (pagination.page - 1) * pagination.first;

    const lastItem = pagination.page === paginatorInfo.lastPage
      ? paginatorInfo.total : pagination.page * pagination.first;

    return `${firstItem}-${lastItem} de ${paginatorInfo.total}`;
  };

  const onDownloadPdf = (event, rowData) => {
    dispatch(materialEntryActions.downloadPdf(rowData.id));
  };

  const onPageChange = (event, newPage) => {
    dispatch(materialEntryActions.updatePagination({ page: newPage }));
  };

  return (
    <Paper square className={classes.container}>
      <MaterialTable
        columns={[
          { title: 'Consec.', field: 'id' },
          { title: 'Autor', field: 'author_id' },
          { title: 'O.C', field: 'purchase_order_id' },
          { title: 'Proveedor', field: 'supplier_id' },
          { title: 'Fecha de Creación', field: 'created_at' },
        ]}
        data={data}
        icons={tableIcons}
        isLoading={loading}
        detailPanel={[
          (rowData) => ({
            disabled: rowData.material_movements.length === 0,
            render: () => <TableDetail data={rowData} />,
          }),
        ]}
        actions={[
          {
            name: 'download',
            icon: () => <PictureAsPdfIcon />,
            position: 'row',
            tooltip: 'Descargar PDF',
            onClick: onDownloadPdf,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          emptyRowsWhenPaging: false,
          draggable: false,
          sorting: false,
          selection: false,
          pageSize: pagination.first,
          initialPage: pagination.page,
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyRowsWhenPaging: 'No hay entradas de materiales que mostrar. Intenta cambiando los filtros',
            emptyDataSourceMessage: 'No hay entradas de materiales que mostrar. Intenta cambiando los filtros',
          },
        }}
        components={{
          OverlayLoading: () => (<TableLoadingIndicator />),
          Pagination: () => (
            <TablePagination
              classes={{
                toolbar: classes.paginationToolbar,
                spacer: classes.paginationSpacer,
              }}
              count={paginatorInfo.total}
              page={pagination.page}
              rowsPerPage={pagination.first}
              rowsPerPageOptions={[pagination.first]}
              labelDisplayedRows={getDisplayedRows}
              onChangePage={onPageChange}
              ActionsComponent={TablePaginationActions}
            />
          ),
          Row: (props) => {
            const propsCopy = { ...props };
            propsCopy.actions.find((a) => a.name === 'download').hidden = !propsCopy.data.has_pdf;
            return <MTableBodyRow {...propsCopy} />;
          },
        }}
      />
    </Paper>
  );
};

export default MaterialEntryTable;
