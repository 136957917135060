import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import formatISO9075 from 'date-fns/formatISO9075';
import AutocompleteFieldAdapter from 'components/shared/AutocompleteFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/DatePickerFieldAdapter';
import TextFieldAdapter from 'components/shared/TextFieldAdapter';
import DialogLoadingIndicator from 'components/shared/DialogLoadingIndicator';
import SectionTitle from 'components/layout/SectionTitle';
import { constructionActions } from 'store/modules/construction';
import { materialActions } from 'store/modules/material';
import { requestActions } from 'store/modules/request';
import { uiActions } from 'store/modules/ui';
import { debounceWait, requestStatus, operationType } from 'common/constants';
import routes from 'common/routes';
import { filtersPagination } from 'common/filters';
import { hasValue, date, positiveNumber } from 'common/validations';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  deleteWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '0 !important',
  },
  submit: {
    textAlign: 'right',
  },
  cancel: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
}));

const initialValues = {
  construction_id: '',
  delivery_date: null,
  materials: [],
  comments: '',
};

const CreateRequest = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    constructionOptions,
    constructionLoading,
    materialOptions,
    materialLoading,
    loading,
    transactionLoading,
    userId,
  } = useSelector((state) => ({
    constructionOptions: state.construction.options,
    constructionLoading: state.construction.loading.filter,
    materialOptions: state.material.options,
    materialLoading: state.material.loading.filter,
    loading: state.request.loading.list,
    transactionLoading: state.transaction.loading,
    userId: state.auth.user.id,
  }));

  useEffect(() => {
    dispatch(uiActions.updatePageTitle(routes.request.create.title));
  }, [dispatch]);

  const required = (value) => (value ? undefined : 'Este campo es requerido');

  const isPositiveNumber = (value) => (positiveNumber(value) ? undefined : 'Este campo solo admite números positivos');

  const validate = (values) => {
    const errors = {};

    if (!hasValue(values.construction_id)) {
      errors.construction_id = 'Este campo no puede estar vacío';
    }

    if (!date(values.delivery_date)) {
      errors.delivery_date = 'Este campo no puede estar vació';
    }

    if (!hasValue(values.materials)) {
      errors.materials = 'Debe hacer por lo menos un material en la lista';
    }

    return errors;
  };

  const onConstructionIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(constructionActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const onMaterialIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      dispatch(materialActions.getFilterOptions({ value, pagination: filtersPagination }));
    }
  };

  const resetAll = (args, state) => {
    const form = args[0];
    const { formState: { values: { materials } } } = state;

    for (let i = 0; i < materials.length; i += 1) {
      form.mutators.pop('materials');
    }

    form.restart();
  };

  const onSubmit = (values) => {
    const data = {
      request: {
        author_id: Number(userId),
        construction_id: Number(values.construction_id.value),
        delivery_date: formatISO9075(values.delivery_date),
        status: requestStatus.waiting,
        comments: values.comments,
      },
      transactions: values.materials.map((material) => ({
        material_id: Number(material.item.value),
        type: operationType.request,
        quantity: Number(material.quantity),
      })),
    };

    dispatch(requestActions.createRequest(data));
  };

  return (
    <React.Fragment>
      <SectionTitle text="Crear Requisición" backButton />
      <Paper square className={classes.paper}>
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          mutators={{
            resetAll,
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            invalid,
            pristine,
            form,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogLoadingIndicator open={loading || transactionLoading} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="construction_id-create"
                    name="construction_id"
                    component={AutocompleteFieldAdapter}
                    options={constructionOptions}
                    isMultiple={false}
                    filterSelectedOptions={false}
                    loading={constructionLoading}
                    onInputChange={_debounce(onConstructionIdChange, debounceWait)}
                    textProps={{
                      label: 'Obra',
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    id="delivery_date-create"
                    name="delivery_date"
                    component={DatePickerFieldAdapter}
                    label="Fecha de Entrega"
                    disablePast
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => push('materials', undefined)}
                  >
                    Agregar Material
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FieldArray name="materials">
                    {({ fields }) => fields.map((name, index) => (
                      <Grid container spacing={3} key={name}>
                        <Grid item xs={12} sm={12} md={7}>
                          <Field
                            id={`material_id-create-${index}`}
                            name={`${name}.item`}
                            component={AutocompleteFieldAdapter}
                            options={materialOptions}
                            isMultiple={false}
                            filterSelectedOptions={false}
                            loading={materialLoading}
                            onInputChange={_debounce(onMaterialIdChange, debounceWait)}
                            validate={required}
                            textProps={{
                              label: 'Material',
                              required: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={5} sm={6} md={2}>
                          <Field
                            name={`${name}.item.unit`}
                            component={TextFieldAdapter}
                            label="Unidad"
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={5} sm={5} md={2}>
                          <Field
                            name={`${name}.quantity`}
                            component={TextFieldAdapter}
                            label="Cantidad"
                            type="number"
                            required
                            validate={isPositiveNumber}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} sm={1} className={classes.deleteWrapper}>
                          <IconButton
                            color="secondary"
                            onClick={() => fields.remove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </FieldArray>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    id="comments-create"
                    name="comments"
                    component={TextFieldAdapter}
                    label="Comentarios"
                    multiline
                    maxRows="4"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      form.mutators.resetAll(form);
                    }}
                  >
                    Reiniciar
                  </Button>
                </Grid>
                <Grid item xs={8} sm={6} className={classes.submit}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    color="secondary"
                    component={RouterLink}
                    to={routes.request.index.path}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={invalid || pristine || loading || transactionLoading}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
    </React.Fragment>
  );
};

export default CreateRequest;
