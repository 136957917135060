import { gql } from '@apollo/client';
import client from 'apollo/apolloClient';

/**
 * Get material types method
 * @return {Promise}
 */
const getMaterialTypes = () => client.query({
  query: gql`
    query materialTypes {
      materialTypes(
        orderBy: [{
          column: ID
          order: ASC
        }]
      ) {
        id
        name
        materials_count
      }
    }
  `,
  fetchPolicy: 'network-only',
});

/**
 * Create material types method
 * @param  {Object} payload
 * @return {Promise}
 */
const createMaterialType = (payload) => client.mutate({
  mutation: gql`
    mutation createMaterialType($input: CreateMaterialTypeInput!) {
      createMaterialType(input: $input) {
        id
      }
    }
  `,
  variables: { input: payload },
});

/**
 * Delete material types method
 * @param  {String} ids
 * @return {Promise}
 */
const deleteMaterialTypes = (ids) => client.mutate({
  mutation: gql`
    mutation deleteMaterialTypes($id: [ID!]!) {
      deleteMaterialTypes(id: $id) {
        id
      }
    }
  `,
  variables: { id: ids },
});

/**
 * Update material types method
 * @param  {Object} payload
 * @return {Promise}
 */
const updateMaterialType = (payload) => client.mutate({
  mutation: gql`
    mutation updateMaterialType($id: ID!, $input: UpdateMaterialTypeInput!) {
      updateMaterialType(id: $id, input: $input) {
        id
        name
        materials_count
      }
    }
  `,
  variables: {
    id: payload.id,
    input: payload.input,
  },
});

export default {
  getMaterialTypes,
  createMaterialType,
  deleteMaterialTypes,
  updateMaterialType,
};
