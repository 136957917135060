import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { reportActions } from 'store/modules/report';
import ReportTable from 'components/report/ReportTable';
import { reportType } from 'common/constants';
import InventoryForm from './InventoryForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  form: {
    order: 2,
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
  list: {
    order: 1,
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
    '& .MuiPaper-root': {
      borderRadius: 0,
      marginTop: theme.spacing(2),
    },
    '& .MuiTableCell-root.MuiTableCell-footer': {
      border: 0,
      display: 'block',
    },
  },
}));

const InventoryReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    pagination,
    refresh,
  } = useSelector((state) => ({
    pagination: state.report.pagination,
    refresh: state.report.refresh,
  }));

  useEffect(() => {
    dispatch(reportActions.getReports({ type: reportType.inventory, pagination }));
  }, [pagination, refresh, dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={7} className={classes.form}>
        <Paper className={classes.paper} square>
          <InventoryForm />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={5} className={classes.list}>
        <ReportTable type={reportType.inventory} />
      </Grid>
    </Grid>
  );
};

export default InventoryReport;
