import { take, takeLatest, put } from 'redux-saga/effects';
import { materialEntryActions } from 'store/modules/materialEntry';
import { movementActions } from 'store/modules/movement';
import { materialActions } from 'store/modules/material';
import { uiActions } from 'store/modules/ui';
import { getMaterialEntryFilters } from 'common/filters';
import { quantityOperations } from 'common/constants';
import { createSnack, getUserFriendlyError } from 'common/utils';
import materialEntry from 'graphql/materialEntry';

/**
 * Get material entries saga
 *
 * @param {*} action
 */
function* getMaterialEntries(action) {
  try {
    const { payload } = action;
    const filters = getMaterialEntryFilters(payload.filters);
    const response = yield materialEntry.list({
      filters, pagination: payload.pagination,
    });
    const { data: { materialEntries: { data, paginatorInfo } } } = response;

    yield put(materialEntryActions.getMaterialEntriesSuccess(data));
    yield put(materialEntryActions.updatePaginatorInfo({
      hasMorePages: paginatorInfo.hasMorePages,
      lastPage: paginatorInfo.lastPage,
      total: paginatorInfo.total,
    }));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialEntryActions.getMaterialEntriesError(message));
  }
}

/**
 * Create material entry saga
 *
 * @param {*} action
 */
function* createMaterialEntry(action) {
  try {
    const { payload: { entry, movements, materialsToUpdate } } = action;
    const { data: { createMaterialEntry: { id } } } = yield materialEntry.create(entry);

    const movementsData = movements.map((movement) => ({
      ...movement,
      material_entry_id: Number(id),
    }));

    const snack = createSnack({
      key: 'entry-created-notification-success',
      variant: 'success',
      message: `Entrada de materiales ${id} creada con éxito`,
    });

    yield put(movementActions.insertMovements(movementsData));
    yield take(movementActions.insertMovementsSuccess);
    yield put(materialActions.updateMaterialByMovement({
      toUpdate: materialsToUpdate, operation: quantityOperations.increase,
    }));
    yield take(materialActions.updateMaterialByMovementSuccess);
    yield put(materialEntryActions.createPdf(id));
    yield take(materialEntryActions.createPdfSuccess);
    yield put(materialEntryActions.sendCreatedNotification(id));
    yield take(materialEntryActions.sendCreatedNotificationSuccess);
    yield put(materialEntryActions.createMaterialEntrySuccess());
    yield put(uiActions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialEntryActions.createMaterialEntryError(message));
  }
}

/**
 * Create material entry PDF saga
 *
 * @param {*} action
 */
function* createPdf(action) {
  try {
    const { payload: id } = action;
    yield materialEntry.createPdf(id);
    yield put(materialEntryActions.createPdfSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialEntryActions.createPdfError(message));
  }
}

/**
 * Send material entry created notification saga
 *
 * @param {*} action
 */
function* sendCreatedNotification(action) {
  try {
    const { payload: id } = action;
    const {
      data: {
        sendMaterialEntryCreatedNotification: { sent, error },
      },
    } = yield materialEntry.sendCreatedNotification(id);

    if (!sent) {
      yield put(materialEntryActions.sendCreatedNotificationError(error));
    } else {
      yield put(materialEntryActions.sendCreatedNotificationSuccess());
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(materialEntryActions.sendCreatedNotificationError(message));
  }
}

/**
 * Download pdf saga
 *
 * @param {*} action
 */
function* downloadPdf(action) {
  const { payload: id } = action;
  const response = yield materialEntry.getDownloadPdfUrl(id);
  const { data: { materialEntryDownloadPdfUrl: { url: downloadUrl } } } = response;

  window.open(downloadUrl, '_blank');
}

/**
 * Watch material entry
 *
 * @export
 */
export default function* watchMaterialEntry() {
  yield takeLatest(materialEntryActions.getMaterialEntries, getMaterialEntries);
  yield takeLatest(materialEntryActions.createMaterialEntry, createMaterialEntry);
  yield takeLatest(materialEntryActions.createPdf, createPdf);
  yield takeLatest(materialEntryActions.sendCreatedNotification, sendCreatedNotification);
  yield takeLatest(materialEntryActions.downloadPdf, downloadPdf);
}
